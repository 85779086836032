import { intersection } from 'lodash';
import {Navigate} from "react-router-dom";
import React from "react";

export function CheckStore({ component: RouteComponent }) {
	if(!localStorage.getItem('user')) {
		return <Navigate to="/login" />;
	}
	return <RouteComponent />
}

export function isLoggedIn() {
	let tok = JSON.parse(localStorage.getItem('user'));
	return tok && tok.user && tok.access_token && tok.roles && tok.user.torgid_user.type === "Company_User"
}

export function isAdmin() {
	let tok = JSON.parse(localStorage.getItem('user'));
	return tok && tok.roles && tok.roles.includes('super_admin')
}

export function isArrayWithLength(arr) {
	return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes) {
	const userStore = JSON.parse(localStorage.getItem('user'));
	const userPermissions = userStore.permission;
	const userGroupTypes = userStore.groupTypes;
	return routes.filter(({ permission, showByGroupType}) => {
		if(showByGroupType && userGroupTypes && intersection(showByGroupType, userGroupTypes).length === 0) return false;
		if(!permission || permission.length === 0) return false;
		else return intersection(permission, userPermissions).length;
	});
}

export const AuthRoute = ({ component: RouteComponent }) => {
	let data = JSON.parse(localStorage.getItem('user'));
	if(data) {
		let isAuthenticated = !!(data && data.user && data.access_token && data.roles && data.permission && data.user.torgid_user.type === "Company_User");
		if(isAuthenticated) {
			return <Navigate to="/" />;
		} else {
			return <Navigate to="/login" />;
		}
	} else {
		return <RouteComponent />
	}
};