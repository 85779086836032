import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  activityLog: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_LOG_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_LOG_SUCCESS:
      return {
        ...state,
        activityLog: action.data,
        loading: false
      };
    case actionTypes.GET_LOG_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_ACTIVITY_LOGIN_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_ACTIVITY_LOGIN_SUCCESS:
      return {
        ...state,
        activityLog: action.data,
        loading: false
      };
    case actionTypes.GET_ACTIVITY_LOGIN_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_MORE_ACTIVITY_LOGIN_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_ACTIVITY_LOGIN_SUCCESS:
      return {
        ...state,
        activityLog: {
          ...state.activityLog,
          results: [...state.activityLog.results, ...action.data.results]
        },
        loadingMore: false
      };
    case actionTypes.GET_MORE_ACTIVITY_LOGIN_FAIL:
      return  {
        ...state,
        loadingMore: false
      };

    default:
      return state;
  }
}
