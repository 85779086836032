import { Permissions } from './index'
import {
  Dashboard,
  SupervisorAccount,
  AssignmentInd,
  AccountCircle,
  Business,
  Ballot,
  ListAlt,
  List,
  Notifications,
  ImportExport,
  SupervisedUserCircle,
  GroupAdd
} from '@mui/icons-material';


export default function menus() {
  return [
    {
      path: "/dashboard",
      name: "menu.dashboard",
      icon: Dashboard,
      state: "DashCollapse",
      permission: [
        Permissions.DASHBOARD_VIEW
      ],
    },
    {
      collapse: true,
      name: "menu.users",
      icon: SupervisorAccount,
      state: "userCollapse",
      permission: [
        Permissions.APP_USER_VIEW,
        Permissions.COMPANY_USER_VIEW,
        Permissions.SPECIAL_USER_VIEW
      ],
      views: [
        {
          path: "/app-user",
          name: "menu.app_users",
          icon: AccountCircle,
          mini: "AU",
          permission: [
            Permissions.APP_USER_VIEW
          ],
        },
        {
          redirect: true,
          path: "/add-app-user",
          name: "menu.add_user",
          icon: AccountCircle,
          mini: "AU",
          permission: [
            Permissions.APP_USER_CREATE,
            Permissions.APP_USER_UPDATE
          ],
        },
        {
          redirect: true,
          path: "/user-profile",
          name: "menu.user_profile",
          icon: AccountCircle,
          mini: "UP",
          permission: [
            Permissions.APP_USER_DETAILS
          ],
        },
        {
          path: "/company-user",
          name: "menu.company_users",
          icon: AssignmentInd,
          mini: "CU",
          permission: [
            Permissions.COMPANY_USER_VIEW
          ],
        },
        {
          redirect: true,
          path: "/add-company-user",
          name: "menu.add_company_users",
          icon: AccountCircle,
          mini: "CU",
          permission: [
            Permissions.COMPANY_USER_CREATE,
            Permissions.COMPANY_USER_UPDATE
          ],
        },
      ]
    },
    {
      collapse: true,
      name: "menu.groups",
      icon: SupervisedUserCircle,
      state: "groupsCollapse",
      permission: [
        Permissions.SPECIAL_USER_VIEW,
        Permissions.GROUP_VIEW
      ],
      views: [
        {
          path: "/groups",
          name: "menu.list_groups",
          icon: AccountCircle,
          mini: "GL",
          permission: [
            Permissions.GROUP_VIEW
          ],
        },
        {
          redirect: true,
          path: "/add-group",
          name: "group.add_new",
          icon: AccountCircle,
          mini: "GL",
          permission: [
            Permissions.GROUP_CREATE
          ],
        },
        {
          path: "/special-users",
          name: "menu.members",
          icon: AssignmentInd,
          mini: "GM",
          permission: [
            Permissions.SPECIAL_USER_VIEW
          ],
        }
      ]
    },
    {
      collapse: true,
      path: "/companies",
      name: "menu.companies",
      icon: Business,
      state: "companiesCollapse",
      permission: [
        Permissions.COMPANY_VIEW,
        Permissions.CATEGORY_VIEW
      ],
      views: [
        {
          path: "/companies",
          name: "menu.list_of_companies",
          icon: List,
          mini: "CS",
          permission: [
            Permissions.COMPANY_VIEW
          ],
        },
        {
          path: "/company-category",
          name: "menu.categories",
          icon: ListAlt,
          mini: "CC",
          permission: [
            Permissions.CATEGORY_VIEW
          ],
        }
        // {
        //   redirect: true,
        //   path: add-company-user || "/app/add-company" conflict ,
        //   name: "menu.add_company",
        //   icon: ListAlt,
        //   mini: "AC",
        //   permission: [
        //     Permissions.COMPANY_CREATE,
        //     Permissions.COMPANY_UPDATE,
        //   ],
        // },
      ]
    },
    {
      collapse: true,
      path: "/offers",
      name: "menu.offers",
      icon: Ballot,
      state: "offerCollapse",
      permission: [
        Permissions.OFFER_VIEW,
        Permissions.CATEGORY_VIEW
      ],
      views: [
        {
          path: "/offers",
          name: "menu.list_of_offers",
          icon: List,
          mini: "AU",
          permission: [
            Permissions.OFFER_VIEW
          ],
        },
        {
          path: "/offer-category",
          name: "menu.categories",
          icon: ListAlt,
          mini: "AU",
          permission: [
            Permissions.CATEGORY_VIEW
          ],
        },
        {
          redirect: true,
          path: "/add-offer",
          name: "menu.add_offer",
          icon: AccountCircle,
          mini: "AO",
          permission: [
            Permissions.OFFER_CREATE,
            Permissions.OFFER_UPDATE
          ],
        },
      ]
    },
    {
      path: "/notifications",
      name: "menu.notifications_logs",
      icon: Notifications,
      collapse: true,
      state: "notifications_logs",
      permission: [
        Permissions.NOTIFICATION_VIEW,
        Permissions.LOG_VIEW
      ],
      views: [
        // {
        //   path: "/notifications",
        //   name: "menu.notifications",
        //   icon: ListAlt,
        //   mini: "NA",
        //   permission: [
        //     Permissions.NOTIFICATION_VIEW
        //   ],
        // },
        {
          path: "/logs",
          name: "menu.logs",
          icon: List,
          mini: "AL",
          permission: [
            Permissions.LOG_VIEW
          ],
        },
        {
          path: "/logins",
          name: "menu.logins",
          icon: List,
          mini: "AL",
          permission: [
            Permissions.ACTIVITY_LOGIN_VIEW
          ],
        }
      ]
    },

    {
      path: "/reports",
      name: "menu.reports",
      icon: ImportExport,
      permission: [
        Permissions.REPORT_VIEW
      ],
    },

    {
      collapse: true,
      path: "/acl",
      name: "menu.user_management",
      icon: AssignmentInd,
      state: "aclCollapse",
      permission: [
        Permissions.ACL_VIEW,
        Permissions.ACL_VIEW,
        Permissions.PERMISSION_VIEW,

      ],
      views: [
        {
          path: "/acl",
          name: "menu.user_roles",
          icon: ListAlt,
          mini: "AR",
          permission: [
            Permissions.ACL_VIEW
          ],
        },
        {
          path: "/roles",
          name: "menu.roles",
          icon: ListAlt,
          mini: "R",
          permission: [
            Permissions.ROLE_VIEW
          ],
        },
        {
          path: "/permissions",
          name: "menu.permissions",
          icon: ListAlt,
          mini: "P",
          permission: [
            Permissions.PERMISSION_VIEW
          ],
        },
      ]
    },


    {
      path: "/dashboard",
      name: "menu.dashboard",
      icon: Dashboard,
      state: "CUDashCollapse",
      permission: [
        Permissions.CU_DASHBOARD_VIEW
      ],
    },


    {
      collapse: true,
      name: "menu.groups",
      icon: SupervisorAccount,
      state: "cu_group",
      permission: [
        Permissions.CU_GROUP_UPDATE,
        Permissions.CU_EMPLOYEE_VIEW
      ],
      views: [
        {
          path: "/my-group",
          name: "menu.my_group",
          icon: SupervisedUserCircle,
          state: "CUMyGroupCollapse",
          permission: [
            Permissions.CU_GROUP_UPDATE
          ],
        },
        {
          path: "/members",
          name: "menu.my_employees",
          icon: SupervisorAccount,
          state: "CUEmployees",
          permission: [
            Permissions.CU_EMPLOYEE_VIEW
          ],
        },
        {
          path: "/attached-group",
          name: "menu.attached_group",
          icon: GroupAdd,
          state: "CUAttachedGroup",
          permission: [
            Permissions.CU_GROUP_UPDATE
          ],
        },
      ]
    },


    {
      path: "/company-user",
      name: "menu.cu_company_users",
      icon: AssignmentInd,
      mini: "CUCompanyUsers",
      permission: [
        Permissions.CU_COMPANY_USER_VIEW
      ],
    },

    {
      custom: true,
      path: "/my_members",
      name: "menu.my_employees",
      icon: SupervisorAccount,
      state: "CUEmployees",
      permission: [
        Permissions.CU_CUSTOM_EMPLOYEE_VIEW
      ],
    },

    {
      path: "/companies",
      name: "menu.my_companies",
      icon: Business,
      state: "CUCompanies",
      permission: [
        Permissions.CU_COMPANY_VIEW
      ],
    },
    {
      path: "/my-offers",
      name: "menu.my_offers",
      icon: Ballot,
      state: "CUOffers",
      permission: [
        Permissions.CU_OFFER_VIEW
      ],
    },
    {
      path: "/managers",
      name: "menu.managers",
      icon: AssignmentInd,
      state: "CUManagers",
      permission: [
        Permissions.CU_MANAGER_VIEW
      ],
      showByGroupType: [
        'regular', 'golden'
      ],
    },
    {
      path: "/b2b",
      name: "menu.b2b_offers",
      icon: ListAlt,
      state: "CUB2B",
      permission: [
        Permissions.CU_B2B_VIEW
      ],
    },
  ];
}
