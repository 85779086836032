import React from 'react';
import { Puff } from 'react-loader-spinner';

export const LoaderSpinner = ({color}) => {
  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <Puff
        height="80"
        width="80"
        color={color || '#4cd74d'}
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  )
};
