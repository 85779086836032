import React, { useState, useRef } from "react";
// import useSwr from "swr";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import markerIcon from "../../assets/img/marker.png"
import {createUseStyles} from 'react-jss'

const styles = {
  clusterMarker:{
    color: "#fff",
    background: "#1978c8",
    borderRadius: "50%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '14px'
  },
  crimeMarker:{
    background: "none",
    border: "none",
    "& div": {
      width: "25px",
    }
  },
  customMarker: {
    background: "none",
    border: "none",
    "& div": {
      width: "45px",
      height: "45px",
      border: "3px solid #1978c8",
      borderRadius: "50%",
    }
  },
  mImg: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  },
  infoWindowStyle: {
    position: 'relative',
    bottom: 100,
    left: '-45px',
    width: 250,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    borderRadius: '5px'
  }
};
const useStyles = createUseStyles(styles);

const Marker = ({ children }) => children;

export default function App({companies}) {
  const classes = useStyles();
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [show, setShow] = useState(0);

  const points = companies && companies.map(crime => ({
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: crime.id,
      address: crime.address,
      logo: crime.torgid_company.logo ? crime.torgid_company.logo : null,
      cName: crime.torgid_company.name ? crime.torgid_company.name : '',
      show: false
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(crime.longitude || "0"),
        parseFloat(crime.latitude || "0")
      ]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const onChildClickCallback = (key) => {
    show === key ? setShow(0) : setShow(key);
  };

  return (
    <div style={{ height: "95%", width: "100%", zIndex: 9999999 }}>
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU'}}
        defaultCenter={{ lat: 64.9240687, lng: -18.847366 }}
        defaultZoom={6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
          setShow(0);
        }}
      >
        {
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {cluster: isCluster, point_count: pointCount} = cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className={classes.clusterMarker}
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker key={`crime-m-${cluster.properties.crimeId}`}
                lat={latitude}
                lng={longitude}
              >
                <button className={cluster.properties.logo ? classes.customMarker : classes.crimeMarker} key={`crime-${cluster.properties.crimeId}`}
                  onClick={() => onChildClickCallback(cluster.properties.crimeId)}
                >
                  <div style={{backgroundImage: `url(${cluster.properties.logo || markerIcon})`}} className={classes.mImg}> </div>
                </button>
                {show === cluster.properties.crimeId &&
                <div className={classes.infoWindowStyle}>
                  <div style={{ fontSize: 16, fontWeight: 'bold' }}>{cluster.properties.cName}</div>
                  <div style={{ fontSize: 12 }}>{cluster.properties.address}</div>
                </div>
                }
              </Marker>
            );
          })
        }
      </GoogleMapReact>
    </div>
  );
}