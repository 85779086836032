import React from "react";
import {withTranslation} from 'react-i18next';
import axios from "axios";

import moment from 'moment';
import TimePicker from 'rc-time-picker';
import "rc-time-picker/assets/index.css";

// @material-ui
import {Accordion, AccordionSummary, AccordionDetails ,Typography, AccordionActions, TextField } from "@mui/material";
import {ExpandMore, ExpandLess, AlarmOff, AlarmOn} from '@mui/icons-material';
import Autocomplete from "@mui/material/Autocomplete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";

// import GoogleMapPopup from "../../../components/GoogleMap/googleMapPopup";


class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: '',
      locations: [],
      changedStatus: false,
    };
  }


  static getDerivedStateFromProps(props, state) {
    if(!props.company) return null;

    if(props.company.company_from === 'Torgid') {
      let locs = props.company.torgid_company_locations;
      if ((locs.length>0 && state.locations.length===0 && state.changedStatus === false) || (locs.length>0 && state.locations.length>0 && locs[0].id !== state.locations[0].loc_id)) {
        let locations = [];
        props.company.torgid_company_locations.forEach(value => {

          const activeTimes = [];
          let openings = value.torgid_company_openings.reduce((r, a) => { r[a.name] = [...r[a.name] || [], a]; return r;}, {});

          openings && Object.keys(openings).map(function(items) {
            const name = openings[items] && openings[items][0].name ? openings[items][0].name : '';
            const activeTime = [
              { "name": name, "day": 0, "day_txt": "mán.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 1, "day_txt": "þri.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 2, "day_txt": "mið.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 3, "day_txt": "fim.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 4, "day_txt": "fös.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 5, "day_txt": "lau.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
              { "name": name, "day": 6, "day_txt": "sun.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            ];
            openings[items] && Object.keys(openings[items]).map(function(item) {
              activeTime[openings[items][item].day].nameState = 'success';
              activeTime[openings[items][item].day].active = true;
              activeTime[openings[items][item].day].active24 = false;
              activeTime[openings[items][item].day].open_time = openings[items][item].open_time;
              activeTime[openings[items][item].day].close_time = openings[items][item].close_time;
            });
            activeTimes.push(activeTime);
          });

          locations.push({
            "loc_id": value.id,
            "id": value.torgid_region ? value.torgid_region.id : "",
            "city": value.torgid_region ? value.torgid_region.city : "",
            "postal_code": value.torgid_region ? value.torgid_region.postal_code : "",
            "torgid_region_id": value.torgid_region ? value.torgid_region.id : "",
            "address": value.address,
            "name": value.name,
            "phone": value.phone,
            "description": value.description,
            "latitude": value.latitude,
            "longitude": value.longitude,
            "torgid_company_openings": activeTimes
          });
        });
        return { locations: locations};
      }
    } else if(props.company.company_from === '1819') {
      if ((props.company.branches.length>0 && state.locations.length===0 && state.changedStatus === false) || (props.company.branches.length===0 && state.locations.length===0 && props.company.address.length>0 && props.company.city.length>0 && props.company.zip>0) || (props.company.branches.length>0 && state.locations.length>0 && props.company.branches[0].id !== state.locations[0].loc_1819_id)) {
        let locations = [];

        if(props.company && props.company.address.length>0) {
          let reg_id = '';
          if(props.data && props.data.results && props.company.city && props.company.zip) {
            reg_id = props.data.results.find(function(el) {
              return el.city === props.company.city && el.postal_code === props.company.zip;
            });
          }
          const activeTimes = [];
          const activeTime = [
            { "name": '', "day": 0, "day_txt": "mán.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 1, "day_txt": "þri.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 2, "day_txt": "mið.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 3, "day_txt": "fim.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 4, "day_txt": "fös.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 5, "day_txt": "lau.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 6, "day_txt": "sun.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
          ];
          props.company.openings.map((item, itemI) => {
            activeTime[item.day_id].nameState = 'success';
            activeTime[item.day_id].active = true;
            activeTime[item.day_id].active24 = false;
            activeTime[item.day_id].open_time = item.opening_time;
            activeTime[item.day_id].close_time = item.closing_time;
          });
          activeTimes.push(activeTime);
          locations = [
            {
              "loc_1819_id": props.company.id,
              "loc_id": "",
              "id": reg_id ? reg_id.id : "",
              "torgid_region_id": reg_id ? reg_id.id : "",
              "city": props.company.city || "",
              "postal_code": props.company.zip || "",
              "address": props.company?.address +' '+ props.company?.address_nr || '',
              "name": '',
              "phone": '',
              "description": '',
              "latitude": props.company.latitude || '',
              "longitude": props.company.longitude || '',
              "torgid_company_openings": activeTimes
            }
          ];
        }



        props.company.branches.map((value, key) => {

          const activeTimes = [];
          const activeTime = [
            { "name": '', "day": 0, "day_txt": "mán.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 1, "day_txt": "þri.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 2, "day_txt": "mið.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 3, "day_txt": "fim.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 4, "day_txt": "fös.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 5, "day_txt": "lau.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
            { "name": '', "day": 6, "day_txt": "sun.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
          ];
          value.openings.map((item, itemI) => {
            activeTime[item.day_id].nameState = 'success';
            activeTime[item.day_id].active = true;
            activeTime[item.day_id].active24 = false;
            activeTime[item.day_id].open_time = item.opening_time;
            activeTime[item.day_id].close_time = item.closing_time;
          });
          activeTimes.push(activeTime);

          let reg_id = '';
          if(props.data && props.data.results && value.city && value.contacts_zip_id) {
            reg_id = props.data.results.find(function(el) {
              return el.city === value.city && el.postal_code === value.contacts_zip_id;
            });
          }

          locations.push({
            "loc_1819_id": props.company.branches[0].id,
            "loc_id": "",
            "id": reg_id ? reg_id.id : "",
            "torgid_region_id": reg_id ? reg_id.id : "",
            "city": value.city || "",
            "postal_code": value.contacts_zip_id || "",
            "address": value.address +' '+ value.address_nr || '',
            "name": value.name || '',
            "phone": value.phone || '',
            "description": value.description || '',
            "latitude": value.latitude || '',
            "longitude": value.longitude || '',
            "torgid_company_openings": activeTimes
          });
        });
        return { locations: locations, changedStatus: true};
      }
    }
    return null;
  }

  sendState() {
    return this.state;
  }

  hideAlert = () => {
    this.setState({alert: null})
  };

  htmlAlert = (lat, lng, index) => {
     // this.setState({alert:<GoogleMapPopup lat={lat} lng={lng} onConfirm={(data) => this.onConfirmCoordinate(data, index)} onCancel={() => this.hideAlert()}  />})
  };

  getCoordinates = (address, index) => {
    let newAddress = address.replace(/\s+/g, '+');
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${newAddress}&key=AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU`)
      .then((response) => {
        if(response.data.status === "OK") {
          let loc = response.data.results[0].geometry.location;
          let newState = Object.assign({}, this.state);
          newState['locations'][index]['latitude'] = loc.lat;
          newState['locations'][index]['longitude'] = loc.lng;
          this.setState(newState);
        } else {
          let msg = '';
          if(response.data.status === "ZERO_RESULTS") { msg = "Coordinates not found!"} else { msg = "Something went wrong!"}
          this.setState({alert: <SweetAlert title={msg} onConfirm={() => this.hideAlert()} showConfirm={false} closeOnClickOutside={true}/>});
          setTimeout(this.hideAlert, 2000);
        }
      });
  };

  onConfirmCoordinate = (data, index) => {
    let newState = Object.assign({}, this.state);
    newState['alert'] = null;
    newState['locations'][index]['latitude'] = data.latitude;
    newState['locations'][index]['longitude'] = data.longitude;
    this.setState(newState);
  };

  isValidated() {
    // var data = this.state.locations;
    // var error = false;
    // for (var i = 0; i < data.length; i++) {
    //   if (data[i].torgid_region_id === '' || data[i].address === '') {
    //     error = true;
    //     break;
    //   } else {
    //     for (var j = 0; j < data[i].torgid_company_openings.length; j++) {
    //       if(j>0 && data[i].torgid_company_openings[j][0].name === '') {
    //         error = true;
    //         break;
    //       }
    //     }
    //   }
    // }
    // if(error) {
    //   this.setState({alert: <SweetAlert
    //         style={{ display: "block", marginTop: "-100px" }}
    //         title="Please fill all required inputs"
    //         onConfirm={() => this.hideAlert()}
    //         showConfirm={false}
    //       />
    //     });
    //     setTimeout(this.hideAlert, 2000);
    //     return false;
    // }
    return true;
  }

  addLocation = () => {
    let locations = [...this.state.locations];
    locations.push({
      "torgid_region_id": "",
      "address": "",
      "name": "",
      "phone": "",
      "description": "",
      "latitude": "",
      "longitude": "",
      "id": "", "postal_code": "", "city": "",
      "torgid_company_openings": [[
        { "name": "", "day": 0, "day_txt": "mán.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 1, "day_txt": "þri.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 2, "day_txt": "mið.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 3, "day_txt": "fim.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 4, "day_txt": "fös.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 5, "day_txt": "lau.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 6, "day_txt": "sun.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
      ]]
    });
    this.setState({locations: locations});
  };

  removeLocation = (index) => {
    let data = [...this.state.locations];
    data.splice(index, 1);
    this.setState({locations: data, changedStatus: true});
  };

  removeActiveDays = (index, openIndex) => {
    let data = [...this.state.locations];
    data[index].torgid_company_openings.splice(openIndex, 1);
    this.setState({locations: data});
  };

  addMoreActiveDays = (index) => {
    let locations = [...this.state.locations];
    locations[index].torgid_company_openings.push(
      [
        { "name": "", "day": 0, "day_txt": "mán.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 1, "day_txt": "þri.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 2, "day_txt": "mið.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 3, "day_txt": "fim.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 4, "day_txt": "fös.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 5, "day_txt": "lau.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
        { "name": "", "day": 6, "day_txt": "sun.", "open_time": "08:00", "close_time": "18:00", active: false, active24: false },
      ]
    );
    this.setState({locations: locations});
  };

  handleChange = (data, type, locIndex) => {
    let newState = Object.assign({}, this.state.locations);
    newState[locIndex][type] = data;
    this.setState(newState);
  };

  handleChangeRegion = (data, locIndex) => {
    if(!data) return;
    let newState = Object.assign({}, this.state.locations);
    newState[locIndex]["torgid_region_id"] = data.id;
    newState[locIndex]["id"] = data.id;
    newState[locIndex]["postal_code"] = data.postal_code;
    newState[locIndex]["city"] = data.city;
    this.setState(newState);
  };

  handleActiveTime(locIndex, openIndex, index) {
    let locations = [...this.state.locations];
    let locItem = locations[locIndex].torgid_company_openings[openIndex];
    locItem[index] = {
          "day": index,
          "name": locItem[index].name,
          "day_txt": locItem[index].day_txt,
          "open_time": locItem[index].open_time,
          "close_time": locItem[index].close_time,
          active: !locItem[index].active,
          active24: locItem[index].active24
        };
    locations[locIndex].torgid_company_openings[openIndex] = locItem;
    this.setState({locations: locations});
  }

  handleTime24(locIndex, openIndex, index, type) {
    let locations = [...this.state.locations];
    let locItem = locations[locIndex].torgid_company_openings[openIndex];
    locItem[index] = {
          "day": index,
          "name": locItem[index].name,
          "day_txt": locItem[index].day_txt,
          "open_time": '00:00',
          "close_time": '23:59',
          active24: !locItem[index].active24
        };
    locations[locIndex].torgid_company_openings[openIndex] = locItem;
    this.setState({locations: locations});
  }

  handleChangeTime = (e, locIndex, openIndex, index, type) => {
    let val = e ? e.format('HH:mm'): moment('00:00', 'H:mm');
    let locations = [...this.state.locations];
    let locItem = locations[locIndex].torgid_company_openings[openIndex];
    locItem[index] = {
      "day": index,
      "name": locItem[index].name,
      "day_txt": locItem[index].day_txt,
      "open_time": type ==="st" ? val : locItem[index].open_time,
      "close_time": type ==="et" ? val : locItem[index].close_time,
      active: true,
      active24: false
    };
    locations[locIndex].torgid_company_openings[openIndex] = locItem;
    this.setState({locations: locations});
  };

  handleChangeActiveInfo = (value, locIndex, openIndex, index, type) => {
    let locations = [...this.state.locations];
    let locItem = locations[locIndex].torgid_company_openings[openIndex];
    locItem[index] = {
      "name": type === 'name' ? value : locItem[index].name,
      "day": index,
      "day_txt": locItem[index].day_txt,
      "open_time": locItem[index].open_time,
      "close_time": locItem[index].close_time,
      active: locItem[index].active,
      active24: locItem[index].active24,
    };
    locations[locIndex].torgid_company_openings[openIndex] = locItem;
    this.setState({locations: locations});
  };


  render() {

    const {classes, data, t } = this.props;

    const options = data.results &&  data.results.map((option) => {
      const firstLetter = option.torgid_region_name.name.toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    const renderLocations = this.state.locations.map((loc, locIndex) => {
      const renderActiveTime = loc.torgid_company_openings.map((values, openIndex) => {
        return <GridItem key={openIndex} xs={12} sm={12} >
          <GridContainer>
            <GridItem xs={12} sm={8}>
              <CustomInput
                labelText={ <span> {t('company.active.name')} {openIndex > 0 && t('required')}  </span> }
                id={"name_open_"+openIndex}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  required: true,
                  value: this.state.locations[locIndex].torgid_company_openings[openIndex][0].name,
                  onChange: event => this.handleChangeActiveInfo(event.target.value,  locIndex, openIndex, 0, 'name'),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={2}>
              <Button color="danger" size='sm' style={{marginTop: '30px'}} onClick={() => this.removeActiveDays(locIndex, openIndex)}>
                {t('company.active.remove')}
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-between" alignItems="stretch" >
          { values.map((value, index) => {
            const startTime = moment(value.open_time,'H:mm');
            const endTime = moment(value.close_time,'H:mm');
            return (
              <GridItem key={index} xs={12} sm={1} >
                <Button
                  key={index+"btn"}
                  color={value.active ? "success" : "default"}
                  size="sm"
                  onClick={() => this.handleActiveTime(locIndex, openIndex, index)}>
                  { value.day_txt }
                  {value.active ? <ExpandLess style={{marginRight: 0}} /> : <ExpandMore style={{marginRight: 0}} />}
                  {value.active24 ?
                    <AlarmOn style={{marginRight: 0}} onClick={() => this.handleTime24(locIndex, openIndex, index, 'on')}/> :
                    <AlarmOff style={{marginRight: 0}} onClick={() => this.handleTime24(locIndex, openIndex, index, 'off')} />}
                </Button>

                { value.active && (
                  <GridItem key={index} xs={12} sm={6} style={{padding: '0 !important'}}>
                    <TimePicker
                      style={{ width: 80, left: "-10px",  }}
                      showSecond={false}
                      value={startTime}
                      placeholder="Start"
                      use12Hours={false}
                      minuteStep={10}
                      onChange = {(e) => this.handleChangeTime(e, locIndex, openIndex, index, 'st')}
                    />
                    <TimePicker
                      style={{ width: 80, left: "-10px",  }}
                      showSecond={false}
                      value={endTime}
                      placeholder="End"
                      use12Hours={false}
                      minuteStep={10}
                      onChange = {(e) => this.handleChangeTime(e, locIndex, openIndex, index, 'et')}
                    />
                  </GridItem>
                )}
              </GridItem>);
            })}
          </GridContainer>
        </GridItem>
      });
      return (
        <Accordion key={"accordion"+locIndex} defaultExpanded style={{boxShadow: '0px 2px 8px 0px rgba(34, 60, 80, 0.4)'}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={"panel"+locIndex+"-content"}
            id={"panel"+locIndex+"-header"}
          >
            <Typography className={classes.heading} style={{color: '#00acc1'}}> {t('location')} {locIndex+1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={6} >
                <Autocomplete
                  id={"region"+locIndex}
                  onChange={(event, newValue) => {
                    this.handleChangeRegion(newValue, locIndex)
                  }}
                  value={loc.id ? {id: loc.id, postal_code: loc.postal_code, city: loc.city} : null}
                  options={options ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.postal_code+' '+option.city}
                  getOptionSelected={(option, value) => option.id === value.id}
                  style={{ marginTop: 10 }}
                  renderInput={(params) => <TextField {...params} label={`${t('company.select_region')}`} />}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={ <span> {t('address')} </span> }
                  id={"address"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: this.state.locations[locIndex].address,
                    onChange: event => this.handleChange(event.target.value, "address", locIndex),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={ <span> {t('location.name')}</span> }
                  id={"name"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: this.state.locations[locIndex].name,
                    onChange: event => this.handleChange(event.target.value, "name", locIndex),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={ <span> {t('phone')}  </span> }
                  id={"phone"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: 'number',
                    maxLength: '7',
                    value: this.state.locations[locIndex].phone,
                    onChange: event => this.handleChange(event.target.value, "phone", locIndex),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  labelText={ <span> {t('location.latitude')}  </span> }
                  id={"latitude"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: this.state.locations[locIndex].latitude,
                    onChange: event => this.handleChange(event.target.value, "latitude", locIndex),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  labelText={ <span> {t('location.longitude')}  </span> }
                  id={"longitude"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: this.state.locations[locIndex].longitude,
                    onChange: event => this.handleChange(event.target.value, "longitude", locIndex),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                {
                  this.state.locations[locIndex].address.length === 0 ? <Button color="default" key={locIndex + 'auto-map'} size="sm"> {t('location.fill_address')} </Button> :
                  <Button color="success" key={locIndex + 'auto-map'} size="sm" onClick={() => this.getCoordinates(this.state.locations[locIndex].address, locIndex)}>
                   {t('location.coordinates_auto')}
                  </Button>
                }
                <Button color="success" key={locIndex + 'man-map'} size="sm" onClick={() => this.htmlAlert(this.state.locations[locIndex].latitude, this.state.locations[locIndex].longitude, locIndex)}>
                  {t('location.coordinates_manually')}
                </Button>
              </GridItem>

              <GridItem xs={12} sm={12}>
                <CustomInput
                  labelText={ <span> {t('description')}  </span> }
                  id={"description"+locIndex}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: this.state.locations[locIndex].description,
                    onChange: event => this.handleChange(event.target.value, "description", locIndex),
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12}> <hr style={{marginBottom: 0}}/> </GridItem>
              <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}>{t('select_activate_days')}</GridItem>

              <GridItem xs={12} sm={11}>
                <GridContainer justifyContent="space-between" alignItems="stretch" >
                  {renderActiveTime}
                </GridContainer>
              </GridItem>

            </GridContainer>
          </AccordionDetails>
          <AccordionActions>
            <Button color="success" style={{float: 'left'}} onClick={() => this.addMoreActiveDays(locIndex)}> {t('company.active.addmore')} </Button>
            <Button color="danger" onClick={() => this.removeLocation(locIndex)}> {t('company.location.remove')} </Button>
          </AccordionActions>
        </Accordion>
      );
    });

    return (
      <div>
        {this.state.alert}
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {renderLocations}
            <Accordion onClick={() => this.addLocation()} style={{backgroundColor: '#00acc1', textAlign: 'center', color: "#fff", padding: '10px', cursor: 'pointer'}}>
                <Typography className={classes.heading}>{t('add_more_location')} </Typography>
            </Accordion>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}



export default withTranslation('',{ withRef: true })(Step2);