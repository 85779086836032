import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getAttachedGroup = (groupIds) => async dispatch => {
  dispatch({type: actionTypes.GET_ATTACHED_GROUP_START});
  try {
    const res = await axios.get('/api/attached_group?golden_groups='+groupIds);
    dispatch({type: actionTypes.GET_ATTACHED_GROUP_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ATTACHED_GROUP_FAIL});
  }
};


export const deleteAttachedGroup = (data) => async dispatch => {
  try {
    await axios.delete('/api/attached_group/' + data.id);
    window.location.reload();
  } catch (error) {}
};



