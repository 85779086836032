import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import defaultImage from "assets/img/placeholder.jpg";


class addCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id || null,
      title: props.data.title || "",
      titleState: props.data.title ? "success" : "",
      title_en: props.data.title_en || "",
      title_enState: props.data.title_en ? "success" : "",
      sort_order: props.data.sort_order || 0,
      logo: props.data.logo || ""
    };
  }

  verifyLength(value, length) {
    return value.length >= length;
  };

  isValidated() {
    if (
      this.state.titleState === "success" &&
      this.state.title_enState === "success"
    ) {
      return true;
    } else {
      if (this.state.titleState !== "success") {
        this.setState({ titleState: "error" });
      }
      if (this.state.title_enState !== "success") {
        this.setState({ title_enState: "error" });
      }
    }
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state, !!this.state.id);
      this.props.onCancel();
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  onUpload(data) {
    this.setState({ logo: data });
  }

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  render() {
    const {classes, t} = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={ this.state.id ? t('category.update') : t('category.add_new') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.titleState === "success"}
              error={this.state.titleState === "error"}
              labelText={ <span> {t('title')} <small> {t('required')} </small> </span> }
              id="title"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.title,
                onChange: event => this.change(event, "title", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.title_enState === "success"}
              error={this.state.title_enState === "error"}
              labelText={ <span> {t('title_en')} <small>{t('required')}</small> </span> }
              id="title_en"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.title_en,
                onChange: event => this.change(event, "title_en", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={ <span> {t('order')} </span> }
              id="sort_order"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.sort_order,
                onChange: event => this.change(event, "sort_order", "number"),
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12}>
            <PictureUpload imgHolder={this.state.logo ? this.state.logo : defaultImage} onUpload={(data) => this.onUpload(data)} />
          </GridItem>

        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addCategory);