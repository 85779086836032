import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: '266b4d42daba139c282347acb0bb9ace',
    plugins: [new BugsnagPluginReact(React)],
});

export default Bugsnag;
