import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom';
import { getAllowedRoutes, isLoggedIn, CheckStore, AuthRoute } from './utils';
import { AppRoutes } from './config';
import "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import Layout from "./layout";
import Pages404 from "views/NotFound"
import LoginPage from "views/auth/LoginPage";

const App = () => {
  let allowedRoutes = [];
  if (isLoggedIn()) allowedRoutes = getAllowedRoutes(AppRoutes);

  return (
    <Routes>
      <Route path="/login" element={<AuthRoute component={LoginPage} />} />

      <Route path="/" element={<CheckStore component={Layout} />}>
        {allowedRoutes.map((route) => {
          const { path, component:Component, children, title, permission, ...rest } = route;
          return (
            <Route {...rest} path={path} element={<Component children={children} />}/>
          )
        })}
      </Route>

      <Route path="*" element={<Pages404/>} />
    </Routes>
  )
};

export default App
