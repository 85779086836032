import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

// material-ui
import {Assignment, AddAlert} from "@mui/icons-material";
import { createUseStyles } from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {deleteAttachedGroup, getAttachedGroup} from "../../../store/actions/attachedGroup";
import DeleteButton from "../../../components/Main/DeleteButton";
import {LoaderSpinner} from "../../../components/Loader";
import {useNavigate} from "react-router-dom";

const style = {
  ...styles,
  ...stylesAlert,
  groupType: {
    textTransform: "capitalize"
  },
};

const useStyles = createUseStyles(style);

const AttachedGroups = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {attachedGroups, loading } = useSelector( state => state.attachedGroup );
  const {status, message, type} = useSelector( state => state.user.notification );
  const [alert, setAlert] = React.useState(null);
  let store = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    dispatch(getAttachedGroup(store.groupIds));
  }, [dispatch]);

  if (loading) return (<LoaderSpinner/>);

  const tableData = attachedGroups.results ?
    attachedGroups.results.map((value, index) => {
      return ([
        index+1,
        <img key={index} title={value.attached_group.name} src={value.attached_group.logo} style={{width: '25px', height: 'auto'}}/>,
        value.attached_group.name,
        value.torgid_special_company.name,
        [
         <DeleteButton key={value.id+'_delete'} action={deleteAttachedGroup(value)} setLimit={()=>{}}/>
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <GridContainer justifyContent="space-between" container>
              <GridItem md={3}>
                <CardHeader>
                  <CardIcon color="rose"> <Assignment/> </CardIcon>
                  <span className={classes.tableTitle}>{t('menu.attached_group')} </span>
                </CardHeader>
              </GridItem>
              <GridItem>
                <a href="https://tally.so/r/mBdODN" target="_blank">
                  <Button color="rose" className={classes.addButton}>
                    {t('group.add_new')}
                  </Button>
                </a>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              striped
              tableHead={["#", t('logo'), t('name'), 'Golden Group',  t('actions')]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[classes.center, classes.right, classes.right]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(AttachedGroups);