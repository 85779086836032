import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingRegion: false,
  regions: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function regionReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_REGION_NAME_START:
      return  {
        ...state,
        loading: true,
        loadingRegion: true
      };
    case actionTypes.GET_REGION_NAME_SUCCESS:
      return {
        ...state,
        regions: action.data,
        loading: false,
        loadingRegion: false
      };
    case actionTypes.GET_REGION_NAME_FAIL:
      return  {
        ...state,
        loading: false,
        loadingRegion: false
      };

    default:
      return state;
  }
}
