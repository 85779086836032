import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

// material-ui icons
import {Assignment, Visibility, AddAlert} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import stylesTable from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {getActivityLogs} from "../../store/actions/activityLog";
import Preview from "./preview";
import {LoaderSpinner} from "../../components/Loader";

const styles = {
  txtArea: {
    height: "84px",
    width: "100%",
    borderColor: "rgb(210, 210, 210)",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial",
    fontWeight: 400,
    lineHeight: 1.42857,
    resize: "none",
    padding: '10px'
  },
  txt: {
    textAlign: 'left',
    paddingBottom: '5px'
  },
  ...stylesTable,
  ...alertStyle
};
const useStyles = createUseStyles(styles);

const Logs = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {activityLog, loading} = useSelector( state => state.activityLog );
  const {status, message, type} = useSelector( state => state.activityLog.notification );
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    dispatch(getActivityLogs());
  }, [dispatch]);

  const infoButton = (data) => {
    return (
      <Button color="success" title={t('preview')} key={data.id + 'update'} className={classes.actionButton} onClick={() => htmlAlert(data)}>
        <Visibility className={classes.icon} />
      </Button>
    );
  };

  const hideAlert = () => { setAlert(null) };

  const htmlAlert = (data) => {
    setAlert(
      <Preview classes={classes} data={data} onCancel={() => hideAlert()} />
    );
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = !activityLog.results ? [[ ]] :
    activityLog.results.map((value, index) => {
      let date = new Date(value.created_at);
      return ([
        index+1,
        value.description,
        value.subject_id,
        value.causer_id,
        date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate(),
        [
          infoButton(value)
        ]
      ]);
    });

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}> <CardIcon color="info"> <Assignment /> </CardIcon> </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "ID",  t('description'), t('subject_id'), t('user_id'), t('created'),  t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Logs);