import { combineReducers } from 'redux'
import user from './reducers/user'
import companyUser from './reducers/companyUser'
import company from './reducers/company'
import companyCategory from './reducers/companyCategory'
import offer from './reducers/offer'
import category from './reducers/category'
import auth from './reducers/auth'
import dashboard from './reducers/dashboard'
import region from './reducers/region'
import acl from './reducers/acl'
import specialCompanies from './reducers/specialCompany'
import specialUser from './reducers/specialUser'
import activityLog from './reducers/activityLog'
import notification from "./reducers/notification";
import report from "./reducers/report";
import attachedGroup from "./reducers/attachedGroup";


export default combineReducers({
  user,
  companyUser,
  company,
  companyCategory,
  offer,
  category,
  auth,
  dashboard,
  region,
  acl,
  specialCompanies,
  specialUser,
  activityLog,
  notification,
  report,
  attachedGroup
})
