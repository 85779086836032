import {
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.js";

const chartsStyle = {
  cardTitle: {
    ...cardTitle,
    fontSize: "24px"
  },
  iconCard: {
    color: "#de2c48",
    float: "left"
  },
  cardCategory: {
    margin: "0",
    color: "#3C4858",
    fontWeight: 500,
    marginLeft: "40px",
    paddingTop: "12px",
    fontSize: "18px"
  },
  cardCategory2: {
    margin: "0",
    color: "#3C4858",
    fontWeight: 500,
    marginLeft: "40px",
    paddingTop: "0",
    fontSize: "18px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  legendTitle: {
    color: grayColor[0],
    margin: "10px 0 !important",
    display: "flex"
  },
  primary: {
    color: "#0088FE"
  },
  warning: {
    color: warningColor[0]
  },
  danger: {
    color: dangerColor[0]
  },
  success: {
    color: successColor[0]
  },
  info: {
    color: infoColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  gray: {
    color: grayColor[0]
  },
  cardFooter: {
    display: "block"
  },
  regName: {
    fontWeight: 400,
  },
  cardHeader: {
    paddingTop: '20px',
    paddingBottom: 0
  }


};

export default chartsStyle;
