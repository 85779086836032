import React, {useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {createUseStyles} from "react-jss"
import {Checkbox} from "@mui/material";
import {Assignment, AddAlert, Language, Email, Check} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {getCompanyUsersWithFilter} from "../../../store/actions/companyUser";
import {LoaderSpinner} from "../../../components/Loader";

const style = {
  choiche: {
    position: 'relative',
    float: 'left',
    textAlign: "center",
    cursor: "pointer",
    width: '50px',
  },
  iconCheckbox: {
    width: '50px',
    height: '50px'
  },
  logoImg: {
    width: "90%",
    height: "90%",
    opacity: '0.5',
    borderRadius: "50%"
  },
  ...styles,
  ...customCheckboxRadioSwitch
};


const useStyles = createUseStyles(style);

const CompanyUser = ({ t }) => {
  const classes = useStyles();
  const {companyUsers, loading} = useSelector( state => state.companyUser );
  const {status, message, type} = useSelector( state => state.companyUser.notification );
  const [alert, setAlert] = React.useState(null);
  const [emails, setEmails] = React.useState([]);

  const dispatch = useDispatch ();
  useEffect(() => {
    dispatch(getCompanyUsersWithFilter());
  }, [dispatch]);

  if (loading) return (<LoaderSpinner/>);

  const handleCheckEmail = (email) => {
    // let cEmail = {...emails}
    if(!emails.includes(email)) {
      setEmails(oldArray => [...oldArray, email]);
    } else {
      setEmails(emails.filter(item => item !== email));
    }
  };

  const handleSelectAll = () => {
    let allEmails = !companyUsers ? [] : companyUsers.map(val => {
      if(val.children[0].torgid_user && val.children[0].torgid_user.email) return val.children[0].torgid_user.email
    });
    setEmails(allEmails);
  };

  const tableData = companyUsers ?
    companyUsers.map((val, index) => {
      let value = val.children[0];
      let companyNames = val.children.length === 1 ? value.torgid_company ? value.torgid_company.name : '': val.children.map(data => data.torgid_company?.name+', ');
      let companySites = val.children.length === 1 ? value.torgid_company ? <a href={value.torgid_company.website} target="_blank"> <Button color="info" className={classes.actionButton}><Language className={classes.icon} /></Button> </a> : ''
        : val.children.map((data, index) => <a href={data.torgid_company?.website} key={index+'site'} target="_blank"> <Button color="info" className={classes.actionButton}><Language className={classes.icon} /></Button> </a> );
      return ([
        index+1,
        <Checkbox
          tabIndex={-1}
          checked={value.torgid_user && emails.indexOf(value.torgid_user.email) >= 0 }
          onClick={() => handleCheckEmail(value.torgid_user.email)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{checked: classes.checked, root: classes.checkRoot}}
        />,
        value.torgid_user && value.torgid_user.profile_picture ?  <img key={index} src={value.torgid_user.profile_picture} style={{width: '25px', height: 'auto'}}/> : '',
        value.torgid_user ? value.torgid_user.name : '',
        value.torgid_user ? <a href={"mailto:" + value.torgid_user.email } key={value.id + 'mail'} title={value.torgid_user.email} target="_blank"> <Button color="info" className={classes.actionButton}> <Email /> </Button> </a> : '',
        companySites || '',
        value.open_for_barter ? <span style={{color: 'green', fontSize: '30px', paddingLeft: '10px'}}>●</span> : <span style={{color: 'red', fontSize: '30px', paddingLeft: '10px'}}>●</span>,
        value.torgid_user && value.torgid_user.position ? value.torgid_user.position : '',
        companyNames || ''
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader >
               <CardIcon color="rose"> <Assignment /> </CardIcon>
              </CardHeader>
            </GridItem>
            <GridItem>
              <Button color="success" aria-label="edit" onClick={() => handleSelectAll()}> Select all </Button>
              <Button color="warning" aria-label="edit" disabled={emails.length === 0}
                      onClick={(e) => { window.location = "mailto:?bcc=" + emails.join(';')+'&subject=Torgið'; e.preventDefault()}} >
                Send Email
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            hover
            tableHead={["#", t('select'), t('picture'), t('name'), t('email'), t('website'), t('exchange'), t('position'), t('company')]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.center, classes.center]}
            customHeadCellClasses={[classes.center, classes.center, classes.center]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(CompanyUser);


