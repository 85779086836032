import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";


export const getCompanyUsers = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USERS_START});
  try {
    const res = await axios.get(  '/api/company_user');
    dispatch({type: actionTypes.GET_COMPANY_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USERS_FAIL});
  }
};

export const getCompanyUsersByCompanies = (companyIds) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USERS_BY_COMPANIES_START});
  try {
    const res = await axios.get(  '/api/company_user?company_ids='+companyIds );
    dispatch({type: actionTypes.GET_COMPANY_USERS_BY_COMPANIES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USERS_BY_COMPANIES_FAIL});
  }
};

export const getCompanyUsersWithFilter = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USERS_START});
  try {
    const res = await axios.get('/api/company_user?filter=company_user');
    dispatch({type: actionTypes.GET_COMPANY_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USERS_FAIL});
  }
};

export const searchCompanyUsers = (search) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USERS_START});
  try {
    const res = await axios.get('/api/company_user/search/'+search);
    dispatch({type: actionTypes.GET_COMPANY_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USERS_FAIL});
  }
};

export const deleteCompanyUser = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_USERS_START});
  try {
    const res = await axios.delete('/api/company_user/' + data.id);
    window.location.reload();
    dispatch({type: actionTypes.DELETE_COMPANY_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_USERS_FAIL});
  }
};

export const createUpdateCompanyUser = ({updateUser, update, updateProfile, ...user}, history) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_COMPANY_USERS_START : actionTypes.CREATE_COMPANY_USERS_START});
  try {

    const resUser = await axios({
      url: `${updateUser ? '/api/user'+`/${user.about.id}` : '/api/user'}`,
      method: updateUser ? 'PUT' : 'POST',
      data: {...user.about}
    });

    const res = await axios({
      url: `${update ? '/api/company_user'+`/${user.about.id}` : '/api/company_user'}`,
      method: update ? 'PUT' : 'POST',
      data: {
        "torgid_company_ids": user.about.companyIds,
        "group_ids": user.about.groupIds,
        "role_ids": user.about.role_ids,
        "torgid_user_id": updateUser ? user.about.id : resUser.data.results.id,
        "open_for_barter": user.about.open_for_barter,
        "hidden": user.about.hidden,
      }
    });
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_USERS_SUCCESS : actionTypes.CREATE_COMPANY_USERS_SUCCESS, data: res.data, userData: resUser.data, updateProfile: updateProfile});

    history(-1);
  } catch (error) {
    let errorMessage = 'Something went wrong';
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      errorMessage = res.message + ". " + message;
    }
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_USERS_FAIL : actionTypes.CREATE_COMPANY_USERS_FAIL, data: errorMessage});
  }
};


export const getCompanyUserByLogin = (data) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USER_BY_EMAIL_START});
  try {
    const res = await axios.get('/api/user/by_login/'+data);
    dispatch({type: actionTypes.GET_COMPANY_USER_BY_EMAIL_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USER_BY_EMAIL_FAIL});
  }
};

export const getCompanyUserProfile = ({...user}) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USER_PROFILE_START});
  try {
    const res = await axios.get('/api/company_user/report/' + user.id);
    dispatch({type: actionTypes.GET_COMPANY_USER_PROFILE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USER_PROFILE_FAIL});
  }
};






