import React from "react";
import {withTranslation} from 'react-i18next';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {createUseStyles} from "react-jss";
import {FormControlLabel, Checkbox} from "@mui/material";
import {Check} from "@mui/icons-material";

class addPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data ? props.data.id : null,
      name: props.data ? props.data.name : "",
      nameState: props.data && props.data.name ? "success" : "",
      permissions: !props.data || !props.data.permissions ? [] : props.data.permissions.map((value, index) => {
        return value.name
      })
    };
  }

  verifyLength(value, length) {
    return value.length >= length;
  };

  isValidated() {
    if (this.state.nameState === "success") {
      return true;
    }
    this.setState({ nameState: "error" });
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state, !!this.state.id);
      this.props.onCancel();
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  handleToggle = value => {
    const currentIndex = this.state.permissions.indexOf(value);
    const newChecked = [...this.state.permissions];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({permissions: newChecked});
  };


  render() {
    const {classes, permissionsList, t } = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px", width: '900px' }}
        title={ this.state.id ? t('acl.update_role') : t('acl.add_role') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.nameState === "success"}
              error={this.state.nameState === "error"}
              labelText={ <span> { t('name')} <small>{ t('required')}</small> </span> }
              id="name"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.name,
                onChange: event => this.change(event, "name", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer justifyContent="flex-start"  className={classes.inlineChecks}>
              {
                permissionsList.map((value, index) => {
                  return <GridItem xs={3} sm={3} key={index} alignItems="flex-start" container >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(value.name)}
                            checked={this.state.permissions.indexOf(value.name) !== -1 ? true : false}
                            checkedIcon={ <Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{ checked: classes.checked, root: classes.checkRoot}}
                          />
                        }
                        classes={{ label: classes.label, root: classes.labelRoot}}
                        label={value.name}
                      />
                  </GridItem>
                })
              }
            </GridContainer>
          </GridItem>
        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addPermission);