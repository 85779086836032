import React, {useEffect} from "react";
import {useNavigate, Outlet} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createUseStyles} from "react-jss";
import PerfectScrollbar from "perfect-scrollbar";
import cx from "classnames";
import {useTranslation} from "react-i18next";

import {logout} from "./store/actions/auth";
import Bugsnag from "./utils/bugsnag";

import NotFound from "./views/NotFound";
import styles from "./assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import IdleTimer from "./components/IdleTimer/IdleTimer";
import AdminNavbar from "./components/Navbars/AdminNavbar.js";
import Sidebar from "./components/Sidebar/Sidebar2.js";
import menus from "./config/menus.js";


var ps;
const ErrorBoundary = Bugsnag.getPlugin("react");
const useStyles = createUseStyles(styles);

export default function Layout(props) {
  const {...rest} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  // styles
  const mainPanelClasses = classes.mainPanel + " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
    });

  if(!localStorage.getItem('user')) {
    localStorage.clear();
    window.location = '/login';
  }

  // ref for main panel div
  const mainPanel = React.createRef();

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 3600, //expire after 10 seconds
      onTimeout: () => {
        dispatch(logout(navigate));
      },
      onExpired: () => {
        dispatch(logout(navigate));
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    let path = window.location.pathname;
    document.title = t(path.replace(/\//g, '')) + " | Spara";
  });

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) { ps.destroy(); }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <ErrorBoundary FallbackComponent={NotFound}>
      <div className={classes.wrapper}>

        <Sidebar
          routes={menus()}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={"red"}
          bgColor={"black"}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={() => sidebarMinimize()}
            miniActive={miniActive}
            brandText={getActiveRoute(menus())}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />

          <div className={classes.content}>
            <div className={classes.container}>
              <Outlet/>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
