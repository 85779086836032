import React, {useEffect} from "react";
import { useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import { getUserProfile } from "../../store/actions/user";
import {withTranslation} from 'react-i18next';

// @material-ui
import { Switch, FormControlLabel } from '@mui/material';
import {Email, Phone, PermContactCalendar, Wc, Home, PersonPinCircle, Language, Favorite, Check, Close, NotificationsActive, AccessTime } from '@mui/icons-material';
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import avatar from "assets/img/default-avatar2.jpg";
import {LoaderSpinner} from "../../components/Loader";
const useStyles = createUseStyles(styles);

const UserProfile = ({ t }) => {
  const classes = useStyles();
  const {state} = useLocation();
  const user = state && state.user || {};
  const {userProfile, loading} = useSelector( state => state.user )
  const dispatch = useDispatch ();

  useEffect(() => {
    dispatch(getUserProfile({...user}));
  }, [dispatch]);

  if (loading) return (<LoaderSpinner/>);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <GridContainer>
            <GridItem xs={6} sm={4} md={4}>
              <Card className={classes.ccard}>
                <CardHeader color="info" icon>
                  <CardIcon color="rose"> <AccessTime /> </CardIcon>
                  <p className={classes.cardCategory2}>{t('activates')}</p>
                  <h3 className={classes.cardTitle}> {userProfile.results && userProfile.results.activates} </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4}>
              <Card className={classes.ccard}>
                <CardHeader color="info" icon>
                  <CardIcon color="rose"> <Favorite /> </CardIcon>
                  <p className={classes.cardCategory2}>{t('favourites')}</p>
                  <h3 className={classes.cardTitle}> {userProfile.results && userProfile.results.favourites} </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4}>
              <Card className={classes.ccard}>
                <CardHeader color="info" icon>
                  <CardIcon color="rose"> <Favorite /> </CardIcon>
                  <p className={classes.cardCategory2}>{t('companies')}</p>
                  <h3 className={classes.cardTitle}>
                    {userProfile.results && userProfile.results.companies}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="rose"> <NotificationsActive /> </CardIcon>
              <h4 className={classes.cardIconTitle}> <small>{t('notifications')}</small> </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="flex-start">
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <GridContainer>
                    <GridItem xs={10} sm={8} className={classes.rowMargin}>
                      <h6 className={classes.text}>{t('user_profile.all_new_offers')}</h6>
                    </GridItem>
                    <GridItem xs={2} sm={4} className={classes.rowMargin}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.torgid_user_notification.all_offer}
                            value="false"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                      />
                    </GridItem>
                    <GridItem xs={10} sm={8} className={classes.rowMargin}>
                      <h6 className={classes.text}>{t('user_profile.new_offers_vaktin')}</h6>
                    </GridItem>
                    <GridItem xs={2} sm={4} className={classes.rowMargin}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.torgid_user_notification.custom_offer}
                            value="false"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                      />
                    </GridItem>
                    <GridItem xs={10} sm={8} className={classes.rowMargin}>
                      <h6 className={classes.text}>{t('user_profile.ann_from_torgid')}</h6>
                    </GridItem>
                    <GridItem xs={2} sm={4} className={classes.rowMargin}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.torgid_user_notification.torgid}
                            value="false"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <Clearfix />
            </CardBody>
          </Card>

        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#" onClick={e => e.preventDefault()}>
                <img src={user.profile_picture ? user.profile_picture : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{user.name}</h4>
              <h6 className={classes.cardCategory} title="Email"><Email  className={classes.cardIcon} /> {user.email}</h6>
              <h6 className={classes.cardCategory} title="Phone"><Phone  className={classes.cardIcon}  /> {user.phone}</h6>
              <h6 className={classes.cardCategory} title="Date of birth"><PermContactCalendar  className={classes.cardIcon}  /> {user.date_of_birth}</h6>
              <h6 className={classes.cardCategory} title="Gender"><Wc  className={classes.cardIcon}  /> {user.gender}</h6>
              <h6 className={classes.cardCategory} title="Address"><Home  className={classes.cardIcon}  /> {user.address}</h6>
              <h6 className={classes.cardCategory} title="Postal code"><PersonPinCircle  className={classes.cardIcon}  /> {user.torgid_region ? user.torgid_region.city + ' - ' + user.torgid_region.postal_code : ''}</h6>
              <h6 className={classes.cardCategory} title="Language"><Language  className={classes.cardIcon}  /> {user.language}</h6>
              <h6 className={classes.cardCategory} title="Subscribed"><Favorite  className={classes.cardIcon}  /> {user.subscribed ? <Check /> : <Close /> }</h6>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withTranslation('',{ withRef: true })(UserProfile);
