import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Permissions} from '../../config'

// material-ui icons
import {Assignment, Edit, AddAlert} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getCompanyCategories, createUpdateCompanyCategory, deleteCompanyCategory} from "../../store/actions/companyCategory"
import AddCategory from "./addCategory";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};
const useStyles = createUseStyles(style);

const Categories = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {categories, loadingCategories} = useSelector( state => state.companyCategory);
  const {status, message, type} = useSelector( state => state.companyCategory.notification );
  const [alert, setAlert] = React.useState(null);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const CATEGORY_UPDATE = userPermission.includes(Permissions.CATEGORY_UPDATE);
  const CATEGORY_DELETE = userPermission.includes(Permissions.CATEGORY_DELETE);

  useEffect(() => {
    dispatch(getCompanyCategories());
  }, [dispatch]);

  const hideAlert = () => { setAlert(null) };

  const onSubmit = (data, update) => {
    dispatch(createUpdateCompanyCategory({update:update, ...data}));
  };

  const updateButton = (data) => {
    return (
        <Button color="success" key={data.id + 'update'} className={classes.actionButton} onClick={() => htmlAlert(data)} title={t('edit')}>
          <Edit className={classes.icon} />
        </Button>
    );
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddCategory classes={classes} data={data} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  if (loadingCategories) return (<LoaderSpinner/>);

  const tableData = !categories.results ? [[ ]] : categories.results.map((value, index) => {
    return ([
      index+1,
      <img key={index} title={value.title} src={value.logo} style={{width: '25px', height: 'auto'}}/>,
      value.title,
      value.title_en,
      [
        CATEGORY_UPDATE && updateButton(value),
        CATEGORY_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteCompanyCategory({...value})} setLimit={()=>{}} />
      ]
    ]);
  });

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.categories')} </span>
                <span className={classes.tableCount} >  {`Showing ${categories.count} of ${categories.count} entries`} </span>
              </CardHeader>
            </GridItem>
            <GridItem>
              { userPermission.includes(Permissions.CATEGORY_CREATE) && <Button color="rose" className={classes.addButton} onClick={htmlAlert} > {t('category.add_new')} </Button> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "ID",  t('icon'), t('name'), t('name_en'),  t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Categories);