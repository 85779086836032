import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';
import {Permissions} from '../../config'

// material-ui icons
import {createUseStyles} from "react-jss";
import {Assignment, Edit, AddAlert, Search, NotInterested, CheckCircleOutline} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getCompanies, deleteCompany, searchCompanies, changeCompanyStatus} from "../../store/actions/company";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};
const useStyles = createUseStyles(style);


const Companies = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {companies, loading, loadingMore} = useSelector( state => state.company );
  const {status, message, type} = useSelector( state => state.company.notification );
  const [alert, setAlert] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(40);
  const [isActiveLoadMore, setIsActiveLoadMore] = React.useState(true);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const COMPANY_UPDATE = userPermission.includes(Permissions.COMPANY_UPDATE);
  const COMPANY_DELETE = userPermission.includes(Permissions.COMPANY_DELETE);
  // setLimit={()=>setPageLimit(pageLimit-1)}

  useEffect(() => {
    dispatch(getCompanies(page, false));
  }, [dispatch]);

  const loadMoreHandler = () => {
    setPage(page+1);
    dispatch(getCompanies(page+1, true));
  };

  const searchHandler = () => {
    if(search.length > 1) {
      setIsActiveLoadMore(false);
      let newSearch = search.replace(/%/g, '');
      dispatch(searchCompanies(newSearch));
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="The search text must contain at least 2 characters"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const hideAlert = () => { setAlert(null) };

  const updateButton = (data) => {
    return (
      <Button
        color="success"
        className={classes.actionButton}
        title={t('edit')}
        onClick={()=> navigate('/add-company', { state: {"data": data} })}
      >
        <Edit className={classes.icon} />
      </Button>
    );
  };

  const onchangeCompanyStatus = (company, status) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={status === 'disable' ?  'Viltu slökkva á fyrirtæki?' : 'Viltu virkja fyrirtæki?'}
        onConfirm={() => disabledCompanySuccess(company, status)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  const disabledCompanySuccess = (company, status) => {
    dispatch(changeCompanyStatus(company, status));
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={status === 'disable' ? 'Fyrirtæki óvirkt!' : 'Fyrirtæki virkt!'}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >{'    '}
      </SweetAlert>
    );
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = companies.results ?
    companies.results.map((value, index) => {
      return ([
        index+1,
        <img key={index} title={value.logo} src={value.logo} style={{width: '25px', height: 'auto'}}/>,
        value.name,
        value.kennitala,
        <Button key={value.id+'type'} color={value.type === "premium" ? 'warning' : 'default'} size="sm" className={classes.actionButton}>{value.type}</Button>,
        value.offer_limit,
        value.torgid_offers_count,
        value.torgid_company_category_nxns ? value.torgid_company_category_nxns.map(item => <img src={item.torgid_company_category.logo} title={item.torgid_company_category.title || ''} style={{width: '25px', height: '25px', padding: '4px'}}/> ) : '',
        [
          COMPANY_UPDATE && <Button
            color={ value.disabled ? 'success' : "warning"}
            key={value.id + 'disabled_enabled'}
            className={classes.actionButton}
            onClick={() => onchangeCompanyStatus(value.id, value.disabled ? 'enable' : 'disable')}
            title={t('disable')}
          >
            {value.disabled ? <CheckCircleOutline className={classes.icon} /> : <NotInterested className={classes.icon} />}
          </Button>,
          COMPANY_UPDATE && updateButton(value),
          COMPANY_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteCompany({...value})} setLimit={()=>setPageLimit(pageLimit-1)} />
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.companies')} </span>
                {isActiveLoadMore && <span className={classes.tableCount} >  {`Showing ${(page*pageLimit) < companies.count ? (page*pageLimit) : companies.count} of ${companies.count} entries`} </span> }
              </CardHeader>
            </GridItem>
            <GridItem>
              <CustomInput
                inputProps={{
                  value: search,
                  onChange: event => setSearch(event.target.value),
                  onKeyPress: e => (e.key === 'Enter' && searchHandler()),
                  placeholder: t('search'),
                  inputProps: { "aria-label": "Search",  className: classes.searchInput }
                }}
              />
              <Button color="rose" aria-label="edit" justIcon size="sm" onClick={() => searchHandler()} >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
              </Button>
            </GridItem>
            <GridItem>
              { userPermission.includes(Permissions.COMPANY_CREATE) &&  <NavLink to={{pathname: '/add-company', state:{}}}> <Button color="rose" className={classes.addButton}> {t('company.add_new')} </Button> </NavLink> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "#", t('logo'), t('name'), t('kennitala'), t('type'), t('offer_limit'), t('offers'), t('category'), t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.center]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[classes.center, classes.right, classes.right]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
        <GridItem xs={12} >
          {isActiveLoadMore && companies.results &&
            <Button color="rose" disabled={companies.results.length < (page*pageLimit)} fullWidth onClick={() => loadMoreHandler()}>
              {t('load_more')} &nbsp; {loadingMore && <LoaderSpinner/>}
            </Button>
          }
        </GridItem>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Companies);