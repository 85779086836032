import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from 'react-i18next';
import {createUseStyles} from "react-jss";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import SnackbarCustom from "../../../components/Snackbar/Snackbar.js";

// @material-ui
import {AddAlert} from "@mui/icons-material";

import Step1 from "./AddUserSteps/Step1.js";
import {getRegions} from "../../../store/actions/region";
import {createUpdateCompanyUser} from "../../../store/actions/companyUser";
import {LoaderSpinner} from "../../../components/Loader";


import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    colorDisabled: "fff"
  },
  disabledInputIcon: {
    colorDisabled: "eee"
  },
  inputAdornment: {
    position: "relative"
  },
  subscribed: {
    marginTop: "10px"
  },
  selectMenuItemSelected: {
    color: "#e91e63"
  },
  selectMenu: {
    color: "#e91e63"
  },
  select: {
    color: "#4c535a"
  },
  selectFormControl: {
    marginTop: '15px'
  },
  ...wizardStyle
};
const useStyles = createUseStyles(style);

const EditProfile = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.user.notification );
  const {regions, loading} = useSelector( state => state.region );
  const isUpdating = true;
  const userData =  state && state.user ||  {};

  const onSubmit = (userData) => {
    let userStore = JSON.parse(localStorage.getItem('user'));
    userData.about.companyIds = userStore.companyIds;
    userData.about.groupIds = userStore.groupIds;
    if(userStore.roles.length === 1 && userStore.roles[0] === 'group_admin') {
      userData.about.role_ids = [28];
    } else if(userStore.roles.length === 1 && userStore.roles[0] === 'company_user') {
      userData.about.role_ids = [4];
    } else if(userStore.roles.length > 1 && userStore.roles.includes('company_user') && userStore.roles.includes('group_admin')) {
      userData.about.role_ids = [4, 28];
    }
    dispatch(createUpdateCompanyUser({updateUser: isUpdating, update:true, updateProfile: true, ...userData}, history));
  };

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);


  if (loading) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
      <GridItem xs={12} sm={10}>
        <Wizard
          validate
          classes={classes}
          history={history}
          user={userData}
          data={regions}
          steps={[
            { stepName: t('company_user.about'), stepComponent: Step1, stepId: "about" }
          ]}
          title={isUpdating ? t('company_user.update_profile') : t('company_user.build_profile')}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={t('finish')}
          finishButtonClick={onSubmit}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(EditProfile);