import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from 'react-i18next';
import {createUseStyles} from "react-jss";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import SnackbarCustom from "../../../components/Snackbar/Snackbar.js";

import {AddAlert} from "@mui/icons-material";
import Step1 from "./AddUserSteps/Step1.js";

import {createUpdateCompanyUser, getCompanyUserByLogin} from "../../../store/actions/companyUser";
import {getRegions} from "../../../store/actions/region";
import {LoaderSpinner} from "../../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    colorDisabled: "fff"
  },
  disabledInputIcon: {
    colorDisabled: "eee"
  },
  inputAdornment: {
    position: "relative"
  },
  subscribed: {
    marginTop: "10px"
  },
  selectMenuItemSelected: {
    color: "#e91e63"
  },
  selectMenu: {
    color: "#e91e63"
  },
  select: {
    color: "#4c535a"
  },
  selectFormControl: {
    marginTop: '15px'
  },
  ...wizardStyle
};
const useStyles = createUseStyles(styles);


const AddCompanyUser = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.companyUser.notification );
  const {companyUser} = useSelector( state => state.companyUser );
  const {regions, loadingRegion} = useSelector( state => state.region );
  const isUpdating = !!(state && state.user) || !!companyUser.results;
  const userDataFromState = state && state.user;
  const companies = JSON.parse(localStorage.getItem('user')).companies;

  const renderUserData = () => {
    if(userDataFromState && userDataFromState.id) {
      return {
        ...userDataFromState,
        companyIds: [userDataFromState.torgid_company_id]
      }
    } else if(userDataFromState && userDataFromState.length === 1) {
      return {
        ...userDataFromState[0],
        companyIds: [userDataFromState[0].torgid_company_id]
      }
    }
    else if(userDataFromState && userDataFromState.length > 1) {
      return {
        ...userDataFromState[0],
        companyIds: userDataFromState && userDataFromState.map(data => data.torgid_company_id)
      }
    }

    return false;
  };

  const userData = renderUserData() || companyUser.results ||  {};

  const onSubmit = (userData) => {
    userData.about.groupIds = [];
    userData.about.role_ids = [4];
    dispatch(createUpdateCompanyUser({updateUser: isUpdating, update:isUpdating, updateProfile: userDataFromState && userDataFromState.isprofile ? userDataFromState.isprofile : false, ...userData}, history));
  };

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const checkEmail = (data) => {
    dispatch(getCompanyUserByLogin(data));
  };

  if (loadingRegion) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
      <GridItem xs={12} sm={10}>
        <Wizard
          validate
          classes={classes}
          history={history}
          user={userData}
          companies={companies || {}}
          checkEmail={checkEmail}
          data={regions}
          steps={[
            { stepName: t('company_user.about'), stepComponent: Step1, stepId: "about" },
          ]}
          title={isUpdating ? t('company_user.update_profile') : t('company_user.build_profile')}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={t('finish')}
          finishButtonClick={onSubmit}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(AddCompanyUser);