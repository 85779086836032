import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';

// @material-ui
import {InputLabel, Select, MenuItem, FormControl} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import defaultImage from "assets/img/placeholder.jpg";

class addCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id || null,
      name: props.data.name || "",
      nameState: props.data.name ? "success" : "",
      name_en: props.data.name_en || "",
      name_enState: props.data.name_en ? "success" : "",
      parent_id: props.data.parent_id || 0,
      sort_order: props.data.sort_order || 0,
      icon: props.data.icon || ""
    };
  }

  verifyLength(value, length) {
    return value.length >= length;
  };

  isValidated() {
    if (
      this.state.nameState === "success" &&
      this.state.name_enState === "success"
    ) {
      return true;
    } else {
      if (this.state.nameState !== "success") {
        this.setState({ nameState: "error" });
      }
      if (this.state.name_enState !== "success") {
        this.setState({ name_enState: "error" });
      }
    }
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state, !!this.state.id);
      this.props.onCancel();
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  onUpload(data) {
    this.setState({ icon: data });
  }

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  render() {
    const {classes, categories, t } = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={ this.state.id ? t('category.update') : t('category.add_new') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.nameState === "success"}
              error={this.state.nameState === "error"}
              labelText={ <span> {t('name')} <small> {t('required')} </small> </span> }
              id="name"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.name,
                onChange: event => this.change(event, "name", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.name_enState === "success"}
              error={this.state.name_enState === "error"}
              labelText={ <span> {t('name_en')} <small>{t('required')}</small> </span> }
              id="name_en"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.name_en,
                onChange: event => this.change(event, "name_en", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} variant="standard">
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('categories')} </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu, style: {zIndex: 35001} }}
                classes={{ select: classes.select }}
                value={this.state.parent_id}
                onChange={e => this.handleChange(e.target.value, 'parent_id')}
                inputProps={{ name: "categories",  id: "categories-select"}}
              >
                <MenuItem classes={{root: classes.selectMenuItem}} value={0}> {t('parent_category')} </MenuItem>
                { categories && categories.map((value, index) => {
                  return <MenuItem
                    classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                    value={value.id}
                    key={value.id}
                  >
                      {value.name}
                  </MenuItem>
                 })
                }
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={ <span> {t('order')} </span> }
              id="sort_order"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.sort_order,
                onChange: event => this.change(event, "sort_order", "number"),
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12}>
            <PictureUpload imgHolder={this.state.icon ? this.state.icon : defaultImage} onUpload={(data) => this.onUpload(data)} />
          </GridItem>

        </GridContainer>
      </SweetAlert>
    );
  }
}

addCategory.propTypes = {
  classes: PropTypes.object
};
export default withTranslation('',{ withRef: true })(addCategory);