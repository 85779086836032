import React from "react";
import {Backup} from "@mui/icons-material";
import {createUseStyles} from 'react-jss'
import {withTranslation} from "react-i18next";

const style = {
  pictureContainer: {
    height: "80px"
  },
  inputLabel: {
    padding: '6px 20px 6px 40px',
    cursor: 'pointer',
    backgroundColor: '#4caf50',
    color: '#fff',
    boxShadow: '0px 0px 5px 0px #000000',
  },
  inputIcon: {
    position: 'relative',
    left: '-28px',
    top: '7px'
  }
};

const useStyles = createUseStyles(style);

const FileUpload = (props) => {
  const t = props.t;
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [msg, setMsg] = React.useState(props.msg || 'Please select a file .txt with a list of users');
  const [disable] = React.useState(props.disabled || false);
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    if (e.target.value.length == 0) {
      setMsg(<span>Error: File is not selected</span>);
      return;
    }
    let newFile = e.target.files[0];
    var fileTypes = ['txt'];  //acceptable file types
    var extension = newFile && newFile.name.split('.').pop().toLowerCase(),  //file extension from input file
      isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types

    if (!isSuccess) {
      setMsg(<span>Error: File type is not supported</span>);
      return;
    }

    reader.onloadend = () => {
      setFile(newFile);
      setMsg(<span>Success</span>);
      let newTxt = reader.result.replace(/\s+|,/g, ' ').toLowerCase();
      // console.log('newTxt', newTxt);
      props.onUpload(newTxt);
    };
    if (newFile) {
      reader.readAsText(newFile);
    }
  };

  return (
    <div className={classes.pictureContainer}>
      <h6 className="description">{t('example')}: spara@spara.is 5461819 5512100490</h6>
      <label htmlFor="file-upload" className={classes.inputLabel}>
        <Backup className={classes.inputIcon}/>
        {msg}
      </label>
      <input type="file" id="file-upload" style={{display: 'none'}} onChange={e => handleImageChange(e)} disabled={disable} />
    </div>
  );
};

export default withTranslation('',{ withRef: true })(FileUpload);