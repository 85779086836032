import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';
import { Permissions } from '../../../config'

import {createUseStyles} from "react-jss"
import {Checkbox, TextField} from "@mui/material";
import {AddAlert, Assignment, Search, Check} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import {
  getSpecialUsersByGroup,
  createSpecialUser,
  deleteSpecialUser,
  updateSpecialUser, searchSpecialUsersByGroup, deleteSpecialUserIds, getSpecialUsers
} from "../../../store/actions/specialUser";
import AddSpecialUser from "./AddEmployee";
import { getSpecialCompanyByUserId} from "../../../store/actions/specialCompany";
import Autocomplete from "@mui/material/Autocomplete";
import {getAttachedGroup} from "../../../store/actions/attachedGroup";
import {LoaderSpinner} from "../../../components/Loader";

const style = {
  choiche: {
    position: 'relative',
    float: 'left',
    textAlign: "center",
    cursor: "pointer",
    width: '50px',
  },
  iconCheckbox: {
    width: '50px',
    height: '50px',
    border: '2px solid #eee'
  },
  iconSelected: {
    color: '#C42431',
    opacity: 1,
    border: '2px solid #C42431',
    "& > span:first-child": {
      borderColor: '#C42431',
    },
    "& img": {
      opacity: 1
    }
  },
  logoImg: {
    width: "90%",
    height: "90%",
    opacity: '0.5',
    borderRadius: "50%"
  },
  ...styles,
  ...stylesAlert,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const styleAdd = {
  txtArea: {
    height: "84px",
    width: "100%",
    borderColor: "rgb(210, 210, 210)",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial",
    fontWeight: 400,
    lineHeight: 1.42857,
    resize: "none"
  },
  txtAreaError: {
    borderColor: "#e91e63",
  },
  txtAreaSuccess: {
    borderColor: "#6bbd6e",
  },
  txtSuccess: {
    color: "#6bbd6e",
    fontWeight: 'bold'
  },
  choiche: {
    position: 'relative',
    float: 'left',
    textAlign: "center",
    cursor: "pointer",
    // marginTop: "20px"
    width: '60px',
    margin: '5px 0'
  },
  iconCheckbox: {
    width: '50px',
    height: '50px',
    border: '2px solid #eee'
  },
  logoImg: {
    width: "90%",
    height: "90%",
    opacity: '0.5',
    borderRadius: "50%"

  },
  companies: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  error: {
    textAlign: 'center',
    color: '#C42431',
    fontWeight: 'bold'
  },
  iconSelected: {
    color: '#C42431',
    opacity: 1,
    border: '2px solid #C42431',
    "& > span:first-child": {
      borderColor: '#C42431',
    },
    "& img": {
      opacity: 1
    }
  },
  ...stylesAlert,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const useStylesAdd = createUseStyles(styleAdd);
const useStyles = createUseStyles(style);

const Employees = ({ t }) => {
  const dispatch = useDispatch ();
  const classes = useStyles();
  const classesAdd = useStylesAdd();
  const [alert, setAlert] = React.useState(null);

  const store = JSON.parse(localStorage.getItem('user'));
  const userPermission = store.permission;
  const canUpdate = userPermission.includes(Permissions.SPECIAL_USER_UPDATE);
  const canDelete =  userPermission.includes(Permissions.SPECIAL_USER_DELETE);
  const groupIds = store.groupIds;

  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(100);
  const [isActiveLoadMore, setIsActiveLoadMore] = React.useState(true);
  const [specialUsersList, setSpecialUsersList] = React.useState([]);

  const {attachedGroups, loading:attachedGroup } = useSelector( state => state.attachedGroup );
  const {specialUsers, loadMore, loading} = useSelector( state => state.specialUser );
  const {specialCompanies, loading:loadingSpecialCompanies } = useSelector( state => state.specialCompanies );
  const {status, message, type} = useSelector( state => state.specialUser.notification );
  const [specialUsersState, setSpecialUsersState] = React.useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    dispatch(getAttachedGroup(store.groupIds));
    dispatch(getSpecialUsersByGroup(store.groupIds, page, false));
    dispatch(getSpecialCompanyByUserId(store.user.torgid_user_id));
  }, [dispatch]);

  useEffect(() => {
    if(attachedGroups.results && specialUsers.results) {
      if(attachedGroups.results.length>0) {
        let gids = attachedGroups.results.map(item => item.attached_group.id);
        const result1 = specialUsers.results.filter(str => !gids.some(term => str.torgid_special_companies.includes(term)));
        setSpecialUsersState(result1)
      } else {
        setSpecialUsersState(specialUsers.results)
      }
    }

  },[attachedGroups.results, specialUsers.results]);

  if (attachedGroup || loadingSpecialCompanies || loading) return (<LoaderSpinner/>);

  const loadMoreConnectedUsers = () => {
    dispatch(getSpecialUsersByGroup(store.groupIds, page+1, true));
    setPage(page+1);
  };

  const onSubmit = (data) => {
    let temp = data.users.replace(/^\s+|\s+$/g, '').split(/\s+/);
    data.users = temp.join(' ');
    let companies = data.companies;
    companies.push('1');
    data.groups = companies.join(',');
    dispatch(createSpecialUser({...data}));
  };

  const hideAlert = () => { setAlert(null) };

  const searchHandler = () => {
    if(search.length > 1) {
      setIsActiveLoadMore(false);
      let newSearch = search.replace(/%/g, '');
      dispatch(searchSpecialUsersByGroup(groupIds, newSearch));
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="The search text must contain at least 2 characters"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const htmlAlert = () => {
    setAlert(
      <AddSpecialUser classes={classesAdd} companies={specialCompanies.results} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const handleChange = (index, company_id) => {
    let newState = Object.assign([], specialUsersState);
    let companies = newState[index].torgid_special_companies;
    let newCompanies = typeof companies === 'string' ? companies.split(',').map(x=>+x) : companies;
    newCompanies.indexOf(company_id) < 0 ? newCompanies.push(company_id) : newCompanies.splice(newCompanies.indexOf(company_id), 1);
    newState[index].torgid_special_companies = newCompanies;
    setSpecialUsersState(newState)
  };

  const updateBtn = (index, id, companies) => {
    return <Button
      key={'save'+index}
      color="success"
      className={classes.actionButton}
      onClick={() => dispatch(updateSpecialUser(id, companies) )}
    > {t('save')} </Button>
  };

  const deleteBtn = (index, data) => {
    return <Button
      key={'delete'+index}
      color="danger"
      className={classes.actionButton}
      onClick={() => handleDeleteBtn(data)}
    > {t('delete')} </Button>
  };

  const handleDeleteBtn = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px", color: "#000" }}
        title={t('are_you_sure')}
        onConfirm={() => handleDeleteSuccess(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('delete_yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

    const handleDeleteSuccess = (data) => {
      let specialIds = specialCompanies.results.map(item => item.id).sort();
      let userSpecialAr = data.torgid_special_companies.split(',').map(x => +x)
      let userSpecialIds = userSpecialAr.filter(item => item !== 1).sort(function (a, b) {
        return a - b;
      });
      let companies = userSpecialIds.filter(item => !specialIds.includes(item));
      if(companies.length === 0) {
        dispatch(deleteSpecialUser(data.id));
      } else {
        if(userSpecialAr.indexOf(1) !== -1) {
          companies.push(1);
        }
        dispatch(updateSpecialUser(data.id, companies) )
      }
      hideAlert();
    };

  const handleCheckUsers = (id) => {
    if(!specialUsersList.includes(id)) {
      setSpecialUsersList(oldArray => [...oldArray, id]);
    } else {
      setSpecialUsersList(specialUsersList.filter(item => item !== id));
    }
  };
  const handleSelectAll = () => {
    let allIds = !specialUsersState ? [] : specialUsersState.map(val => val.id);
    setSpecialUsersList(allIds);
  };

  const changeFilter = (item) => {
    let ids = item.map(data => data.id);
    setFilter(ids);
    dispatch(getSpecialUsersByGroup(ids.length === 0 ? store.groupIds : ids, page, false));
  };


  const connectedUsersData = specialUsersState ? specialUsersState.map((value, index) => {
    let user_id = value.torgid_user_id;
    let name = value.torgid_user ? value.torgid_user.name : '';
    let email = value.torgid_user ? value.torgid_user.email : value.email;
    let phone = value.torgid_user ? value.torgid_user.phone : value.phone;
    let kennitala = value.torgid_user ? value.torgid_user.kennitala : value.kennitala;
    let userSpecCompanies = [];

    if(specialCompanies.results) {
      specialCompanies.results.map((comp, compIndex) =>
        userSpecCompanies.push(
          <div className={classes.choiche} key={compIndex+'spec'}>
            <Checkbox
              checked={value.torgid_special_companies.indexOf(comp.id) >= 0 }
              tabIndex={-1}
              onClick={() => handleChange(index, comp.id)}
              checkedIcon={<img key={index+'img1'} src={comp.logo}  className={classes.logoImg}/>}
              icon={<img key={index+'img2'} src={comp.logo}  className={classes.logoImg} />}
              classes={{checked: classes.iconSelected, root: classes.iconCheckbox}}
            />
          </div>
        )
      );
    }

    return ([
      index+1,
      <Checkbox
        tabIndex={-1}
        checked={specialUsersList.indexOf(value.id) >= 0}
        onClick={() => handleCheckUsers(value.id)}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked, root: classes.checkRoot}}
      />,
      user_id,
      name,
      email,
      phone,
      kennitala,
      userSpecCompanies,
      [
        canUpdate && updateBtn(index, value.id, value.torgid_special_companies),
        canDelete && deleteBtn(index, value)
        // canDelete && <DeleteButton key={value.id+'_delete'} action={deleteSpecialUser(user_id, email, phone, kennitala)} setLimit={()=>setPageLimit(pageLimit-1)} />
      ]
    ]);
  }) :  [[ ]];

  let filterDropdown = <Autocomplete
      multiple
      id={"companies"}
      onChange={(event, newValue) => {
        changeFilter(newValue)
      }}
      value={specialCompanies && filter ? filter.map(item => specialCompanies.results.find(spec => spec.id === item)) : []}
      options={specialCompanies && specialCompanies.results ? specialCompanies.results : []}
      getOptionLabel={(option) => option?.name}
      getOptionSelected={(option, value) => value && option.id === value.id}
      renderOption={(props, option) => (
        <div><img key={props.id+'imgId'} loading="lazy" width="20" src={props.logo} style={{marginRight: '10px'}}/>{props.name}</div>
      )}
      style={{ marginTop: 10 }}
      renderInput={(params) => <TextField {...params} variant="standard" label={`${t('special_companies')}`} />}
    />;

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridContainer justifyContent="flex-end" container>
      <GridItem md={2}>
        { userPermission.includes(Permissions.SPECIAL_USER_CREATE) && <Button color="rose" style={{marginTop: '40px'}} onClick={htmlAlert} > {t('add_new_special_user')} </Button> }
      </GridItem>
    </GridContainer>

    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader >
              <CardIcon color="rose"><Assignment /></CardIcon>
              <span className={classes.tableTitle}>{t('menu.my_employees')} </span>
              {isActiveLoadMore && <span className={classes.tableCount} > {`Showing ${(page*pageLimit) < specialUsers.count ? (page*pageLimit) : specialUsers.count } of ${specialUsers.count} entries`} </span> }
              </CardHeader>
           </GridItem>
            <GridItem md={3}>
              <CustomInput
                inputProps={{
                  value: search,
                  onChange: event => setSearch(event.target.value),
                  onKeyPress: e => (e.key === 'Enter' && searchHandler()),
                  placeholder: t('search'),
                  inputProps: { "aria-label": "Search",  className: classes.searchInput }
                }}
              />
              <Button color="rose" aria-label="edit" justIcon size="sm" onClick={() => searchHandler()} >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
              </Button>
            </GridItem>
            <GridItem style={{marginTop: '10px'}}>
              <Button color="success" size="sm" aria-label="edit" disabled={!specialUsersState} onClick={() => handleSelectAll()}> Select all </Button>
              <DeleteButton disabledbtn={specialUsersList.length === 0} action={deleteSpecialUserIds(specialUsersList, groupIds)} setLimit={()=>{}} msg="Delete"/>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            hover
            tableHead={["#", 'Select', t('user_id'), t('name'), t('email'), t('phone'), t('kennitala'), filterDropdown, t('actions') ]}
            tableData={ connectedUsersData }
            customCellClasses={[classes.center, classes.center, classes.center]}
            customHeadCellClasses={[classes.center, classes.center, classes.center]}
          />
        </CardBody>
        {isActiveLoadMore &&
        <Button color="rose" disabled={specialUsersState && specialUsersState.length < (page*pageLimit)} fullWidth onClick={() => loadMoreConnectedUsers()}>
          {t('load_more')}  &nbsp; {loadMore && <LoaderSpinner/>}
        </Button>
        }
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Employees);