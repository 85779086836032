import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import {createUseStyles} from "react-jss";

// @mui/material components
import { AppBar, Toolbar, Hidden } from '@mui/material';
import {Menu, MoreVert, ViewList} from '@mui/icons-material';

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import {withTranslation} from "react-i18next";

const useStyles = createUseStyles(styles);

const AdminNavbar = (props) => {
  const {t} = props;
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({[" " + classes[color]]: color});
  const sidebarMinimize = classes.sidebarMinimize;
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            <Button
              justIcon
              round
              color="white"
              onClick={props.sidebarMinimize}
            >
              {props.miniActive ? (
                <ViewList className={classes.sidebarMiniIcon} />
              ) : (
                  <MoreVert className={classes.sidebarMiniIcon} />
              )}
            </Button>
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {t(brandText)}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};


export default withTranslation('',{ withRef: true })(AdminNavbar);