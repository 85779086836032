import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  appUsers: [],
  userProfile: [],
  userByEmail: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        appUsers: action.data,
        loading: false
      };
    case actionTypes.GET_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_USERS_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_MORE_USERS_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_USERS_SUCCESS:
      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          results: [...state.appUsers.results, ...action.data.results]
        },
        loadingMore: false
      };
    case actionTypes.GET_MORE_USERS_FAIL:
      return  {
        ...state,
        loadingMore: false
      };

    case actionTypes.CREATE_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_USERS_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_USERS_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.DELETE_USERS_START:
      return  {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_USERS_SUCCESS:
      const id = action.data.results.id;
      const appUsers = state.appUsers.results.filter((item) => item.id !== id);
      state.appUsers.results = appUsers
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_USERS_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.data,
        loading: false
      };
    case actionTypes.GET_USER_PROFILE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_USER_PROFILE_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_USER_BY_EMAIL_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        userByEmail: action.data,
        loading: false,
        notification: { status: true, type: 'success', message: "User with this Email was found" }
      };
    case actionTypes.GET_USER_BY_EMAIL_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: 'User with this email not found' }
      };

    default:
      return state;
  }
}
