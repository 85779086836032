import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  specialCompanies: [],
  specialCompaniesById: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function specialCompanyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_SPECIAL_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_SPECIAL_COMPANY_SUCCESS:
      return {
        ...state,
        specialCompanies: action.data,
        loading: false
      };
    case actionTypes.GET_SPECIAL_COMPANY_BYGROUP_SUCCESS:
      const options = action.data.results.map((option) => {
        const firstLetter = option.name.charAt(0).toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      }).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
      return {
        ...state,
        specialCompanies: {
          ...action.data,
          "results": options
        },
        loading: false
      };
    case actionTypes.GET_SPECIAL_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_SPECIAL_COMPANY_BY_ID_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_SPECIAL_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        specialCompaniesById: action.data.results,
        loading: false
      };
    case actionTypes.GET_SPECIAL_COMPANY_BY_ID_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.CREATE_SPECIAL_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_SPECIAL_COMPANY_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_SPECIAL_COMPANY_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_SPECIAL_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_SPECIAL_COMPANY_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_SPECIAL_COMPANY_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    default:
      return state;
  }
}
