import React from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

// material-ui icons
import {AddAlert} from "@mui/icons-material";
import {FormControl} from "@mui/material";
import {createUseStyles} from "react-jss"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import Datetime from "react-datetime";
import {getReport} from "../../store/actions/report";

const style = {
};

const useStyles = createUseStyles(style);

const Reports = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.activityLog.notification );
  const [date, setDate] = React.useState(null);


  const handleBtn = () => {
    dispatch(getReport(date));
  };


  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardBody>
          <GridContainer justifyContent="start" container style={{margin: '20px 0', color: '#000'}}>
            <GridItem xs={12} sm={3}>
              <FormControl fullWidth>
                <Datetime
                  initialValue = {new Date()}
                  onChange={e => setDate(e)}
                  timeFormat={false}
                  className={classes.datePicker}
                  inputProps={{ placeholder: 'Select date' }}
                  dateFormat="yyyy-MM-D"
                  classes={{ label: classes.label }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Button color="success" style={{padding: '12px 12px'}} disabled={!date} onClick={() => handleBtn()}> GO  </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Reports);