import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';

// @mui/material components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";

class addPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id || null,
      name: props.data.name || "",
      nameState: props.data.name ? "success" : ""
    };
  }

  verifyLength(value, length) {
    return value.length >= length;
  };

  isValidated() {
    if (this.state.nameState === "success") {
      return true;
    }
    this.setState({ nameState: "error" });
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state, !!this.state.id);
      this.props.onCancel();
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  render() {
    const {classes, t } = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={ this.state.id ? t('acl.update_perm') : t('acl.add_perm')}
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={this.state.nameState === "success"}
              error={this.state.nameState === "error"}
              labelText={ <span> {t('name')} <small> {t('required')} </small> </span> }
              id="name"
              formControlProps={{fullWidth: true }}
              inputProps={{
                value: this.state.name,
                onChange: event => this.change(event, "name", "length", 3),
              }}
            />
          </GridItem>
        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addPermission);