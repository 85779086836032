import _ from 'lodash';
import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  companyUsers: [],
  companyUsersCount: 0,
  companyUser: [],
  companyUserProfile: [],
  count: 0,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };


    case actionTypes.GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsersCount: action.data.results.length,
        companyUsers: _.chain(action.data.results)
          .groupBy("torgid_user_id")
          .map((value, key) => ({ parent_id: key, children: value }))
          .value(),
        loading: false,
        count: action.data.count
      };
    case actionTypes.GET_COMPANY_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_USERS_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_COMPANY_USERS_BY_COMPANIES_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_USERS_BY_COMPANIES_SUCCESS:
      let companyUserData = action.data.results.filter(obj => { return obj.hidden === false });
      return {
        ...state,
        companyUsersCount: action.data.results.length,
        companyUsers: _.chain(companyUserData)
          .groupBy("torgid_user_id")
          .map((value, key) => ({ parent_id: key, children: value }))
          .value(),
        loading: false,
        count: action.data.count
      };
    case actionTypes.GET_COMPANY_USERS_BY_COMPANIES_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_MORE_COMPANY_USERS_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_COMPANY_USERS_SUCCESS:
      let companyUserDataMore = action.data.results.sort((a, b) => a.id - b.id);
      let newData = _.chain(companyUserDataMore)
        .groupBy("torgid_user_id")
        .map((value, key) => ({ parent_id: key, children: value }))
        .value();
      let compareData = newData.length>0 ? [...state.companyUsers, ...newData] : [...state.companyUsers];
      return {
        ...state,
        companyUsersCount: state.companyUsersCount + action.data.results.length,
        companyUsers: compareData,
        loadingMore: false
      };
    case actionTypes.GET_MORE_COMPANY_USERS_FAIL:
      return  {
        ...state,
        loadingMore: false
      };

    case actionTypes.CREATE_COMPANY_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_COMPANY_USERS_SUCCESS:
      return  {
        ...state,
        companyUser: [],
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_COMPANY_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_COMPANY_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_COMPANY_USERS_SUCCESS:
      if(action.updateProfile) {
        let store = JSON.parse(localStorage.getItem('user'));
        store.user.torgid_user = action.userData.results;
        store.user.open_for_barter = action.data.results.open_for_barter;
        store.user.hidden = action.data.results.hidden;
        localStorage.setItem('user', JSON.stringify(store));
      }
      return  {
        ...state,
        companyUser: [],
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_COMPANY_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.DELETE_COMPANY_USERS_START:
      return  {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_COMPANY_USERS_SUCCESS:
      const id = action.data.results.id;
      const data = state.companyUsers.results.filter((item) => item.id !== id);
      state.companyUsers.results = data
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_COMPANY_USERS_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_COMPANY_USER_BY_EMAIL_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        companyUser: action.data,
        loading: false,
        notification: { status: true, type: 'success', message: "User with this Email was found" }
      };
    case actionTypes.GET_COMPANY_USER_BY_EMAIL_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: 'User with this email not found' }
      };


    case actionTypes.GET_COMPANY_USER_PROFILE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_USER_PROFILE_SUCCESS:
      return {
        ...state,
        companyUserProfile: action.data,
        loading: false
      };
    case actionTypes.GET_COMPANY_USER_PROFILE_FAIL:
      return  {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
