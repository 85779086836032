import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {createUseStyles} from "react-jss";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import SnackbarCustom from "../../../components/Snackbar/Snackbar.js";
import {AddAlert} from "@mui/icons-material";

import Step1 from "./AddSteps/Step1.js";
import Step3 from "./AddSteps/Step3.js";

import {useDispatch, useSelector} from "react-redux";
import {getCategories} from "../../../store/actions/category";
import {getSpecialCompany} from "../../../store/actions/specialCompany";
import {createUpdateOffer, getOfferById} from "../../../store/actions/offer";
import {getCompanies, getCompanyById} from "../../../store/actions/company";
import {LoaderSpinner} from "../../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const style = {
  inputAdornment: {
    position: "relative"
  },
  dSelected: {
    color: "#e91e63",
    fontSize: '14px'
  },
  selectMenu: {
    color: "#e91e63",
    fontSize: '14px'
  },
  select: {
    color: "#4c535a",
    fontSize: '14px'
  },
  txtArea: {
    height: "84px",
    width: "100%",
    borderColor: "rgb(210, 210, 210)",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial",
    fontWeight: 400,
    lineHeight: 1.42857,
    resize: "none"
  },
  txtAreaError: {
    borderColor: "#e91e63",
  },
  txtAreaSuccess: {
    borderColor: "#6bbd6e",
  },
  paddingRow: {
    padding: '15px 15px !important'
  },
  textField: {
    margin: '0 5px !important'
  },
  label: {
    fontSize: '14px'
  },
  selectLabel: {
    fontSize: '14px'
  },
  special_option: {
    paddingLeft: '10px'
  },
  special_option_label: {
    color: "#403f3f"
  },
  uploader_div: {
    color: "#999"
  },
  add_more_image_btn: {
    padding: "2px 0 2px 2px",
    marginLeft: "10px"
  },
  icon: {
    position: "absolute",
    top: "10%",
    left: 0,
    width: "50px",
    height: "80%",
    color: "#fff"
  },
  btn: {
    height: "50px",
    paddingLeft: "60px",
    paddingRight: "10px",
    width: '32%',
    whiteSpace: 'normal'
  },
  count: {
    backgroundColor: 'red',
    position: 'absolute',
    top: '-7px',
    right: 0,
    width: '18px',
    height: '18px',
    borderRadius: '50px',
    color: '#fff',
    border: '1px solid #fff',
    fontSize: '10px',
    lineHeight: '18px',
    textAlign: 'center'
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...wizardStyle
};

const useStyles = createUseStyles(style);


const AddOffer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.offer.notification );
  const {categories, loadingCat} = useSelector( state => state.category );
  const {specialCompanies, loadingSpec } = useSelector( state => state.specialCompanies );
  const {loadingSave, offerById, loadingOfferById} = useSelector( state => state.offer );
  const {company, companies, loading} = useSelector( state => state.company );
  const isUpdating = !!(state && state.offer);
  const offerDataByState = state && state.offer;
  let store = JSON.parse(localStorage.getItem('user'));
  let user_is_group_admin = !!(store && store.roles.includes('group_admin'));

  useEffect(() => {
    if(offerDataByState && offerDataByState.id) {
      dispatch(getOfferById(offerDataByState.id));
    }
  }, [offerDataByState]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getSpecialCompany());
    if(user_is_group_admin) {
      dispatch(getCompanies());
    }
  }, [dispatch]);

  const getData = (data) => {
    dispatch(getCompanyById(data));
  };


  const onSubmit = (data) => {
    if(!loadingSave) {
      if (data.about.discount === 'discount' || data.about.discount === 'price') {
        let discount = data.about.discountInput || '0';
        // let sdiscount = data.about.special_discount || '0';
        // data.about.special_discount = data.about.discount === 'discount' ? sdiscount.slice(0,1) === '-' ? sdiscount+"%" : "-"+sdiscount+"%" : sdiscount.trim()+" kr."
        data.about.discount = data.about.discount === 'discount' ? discount.slice(0, 1) === '-' ? discount + "%" : "-" + discount + "%" : discount.trim() + " kr."
      }
      data.about.torgid_offer_active_times = data.about.torgid_offer_active_times.filter(function (el) {
        return el.active === true
      });

      if (data.about.torgid_special_company_ids.length === 1 && data.about.torgid_special_company_ids[0] === 0) {
        data.about.torgid_special_company_id = null;
        data.about.group_ids = null;
      } else {
        data.about.group_ids = data.about.torgid_special_company_ids.join(',');
        data.about.torgid_special_company_id = 999;
      }
      
      dispatch(createUpdateOffer({update: isUpdating, ...data}, 'my-offers'));
    }
  };

  if (loadingCat || loadingSpec || loadingOfferById || (user_is_group_admin && loading)) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status} />
      <GridItem xs={12} sm={10}>
        <Wizard
          validate
          classes={classes}
          history={history}
          offerData={offerDataByState ? offerById : {}}
          categories={categories || {}}
          data={specialCompanies || {}}
          data2={company || {}}
          getData={getData}
          companies={companies}
          currentLang={localStorage.getItem('lang') ? localStorage.getItem('lang') : 'is'}
          steps={[
            { stepName: t('offer'), stepComponent: Step1, stepId: "about" },
            { stepName: t('category'), stepComponent: Step3, stepId: "category" },
          ]}
          title={isUpdating ? t('offer.update') : t('offer.build')}
          subtitle=""
          finishButtonClick={onSubmit}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={loadingSave ? 'Loading...' : t('finish')}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(AddOffer);