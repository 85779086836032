import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      torgid_category_id:  [],
      alert: null,
      categoriesState: '',
      selectedCategoriesCount: {}
    };
  }

  componentDidMount() {
    if(this.props.offerData.torgid_offer_category_nxns) {
      let activeCategories = [];
      let selectedCategoriesCount = {};
      this.props.offerData.torgid_offer_category_nxns.map((obj) => {
        if(obj.torgid_offer_category) {
          activeCategories.push(obj.torgid_offer_category_id);
          selectedCategoriesCount[obj.torgid_offer_category.parent_id] = (selectedCategoriesCount[obj.torgid_offer_category.parent_id]+1) || 1;
        }
      });
      this.setState({
        torgid_category_id: activeCategories,
        selectedCategoriesCount: selectedCategoriesCount
      });
    }
  }


  sendState() {
    return this.state;
  }
  hideAlert = () => {
    this.setState({alert: null})
  };
  isValidated() {
    if(this.state.torgid_category_id.length === 0 ) {
      this.setState({alert: <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Please choose a category!"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        />
      });
      setTimeout(this.hideAlert, 2000);
    } else {
      return true
    }
  }

  render() {
    const {classes} = this.props;

    const renderCategory = (i) => {
      let categories=[];
      if(i === 0) {
        categories = this.props.categories[0].children.map((value, index) => mainButton(value, index));
      } else {
        let categoryData = this.props.categories.find(item => item.parent_id == i);
        if (categoryData) {
          categories = categoryData.children.map((value, index) => {
            let color = this.state.torgid_category_id.indexOf(value.id) !== -1 ? 'warning' : 'info';
            return <Button color={color} key={index} className={classes.btn} onClick={() => {choose(value.id, index, i)}} >
              <img src={value.icon} className={classes.icon}/>
              {this.props.currentLang === 'en' && value.name_en ? value.name_en : value.name}
            </Button>
          });
          let len = categories.length+1;
          categories[len]= <Button color='danger' key={len} onClick={() => renderCategory(0)} > Back </Button>
        } else {
          let categoryData = this.props.categories[0].children.findIndex(item => item.id == i);
          this.choose(i, categoryData, i);
        }
      }
      this.setState({ categoriesState: categories });
    };

    const mainButton = (value, index) => {
      return <Button color={this.state.selectedCategoriesCount[value.id] ? 'warning' : 'success'} className={classes.btn} key={index} onClick={() => renderCategory(value.id)}>
        <img src={value.logo} className={classes.icon}/>
        {this.props.currentLang === 'en' && value.name_en ? value.name_en : value.name}
        {this.state.selectedCategoriesCount[value.id] && <span className={classes.count}> {this.state.selectedCategoriesCount[value.id]} </span>}
      </Button>
    };

    const choose = (id, index, parentId) => {
      let copyState = {...this.state};
      let exist = copyState.torgid_category_id.indexOf(id);
      if(exist === -1) {
        copyState.torgid_category_id.push(id);
        copyState.selectedCategoriesCount[parentId] = (copyState.selectedCategoriesCount[parentId]+1) || 1;
      } else {
        copyState.torgid_category_id.splice(exist, 1);
        copyState.selectedCategoriesCount[parentId] = (copyState.selectedCategoriesCount[parentId]-1) || 0;
      }

      if(this.state.categoriesState.length === 0) {
        copyState.categoriesState = this.props.categories[0].children.map((value, index) => mainButton(value, index));
      }

      copyState.categoriesState[index] = <Button color={exist === -1 ? 'warning' : 'info'} className={classes.btn} key={index}
                                                 onClick={() => {choose(id, index, parentId)}}>
        {copyState.categoriesState[index].props.children}
      </Button>;
      this.setState({copyState});
    };

    return (
      <div>
        {this.state.alert}
        <GridContainer justifyContent="center">
          <GridItem container  >
            {
              (this.state.categoriesState.length === 0 && this.props.categories.length > 0) ?
                this.props.categories[0].children.map((value, index) => mainButton(value, index))
                : this.state.categoriesState
            }
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}



export default (Step3);
