import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  report: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function reportReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: action.data,
        loading: false
      };
    case actionTypes.GET_REPORT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_REPORT_FAIL:
      return  {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
