import React from "react";
import {withTranslation} from 'react-i18next';
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {InputLabel, Select, MenuItem, FormControl} from "@mui/material";

class addCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id || "",
      user_id: props.data.id || "",
      role: props.data.roles ? props.data.roles[0].name : "",
    };
  }

  inputConfirmAlertNext = e => {
    this.props.onSubmit(this.state, !!this.state.id);
    this.props.onCancel();
  };

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  render() {
    const {classes, users, roles, t } = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={ this.state.id ? t('acl.update_user_role') : t('acl.add_user_role') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} variant="standard">
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('users')} </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu, style: {zIndex: 35001} }}
                classes={{ select: classes.select }}
                value={this.state.user_id}
                onChange={e => this.handleChange(e.target.value, 'user_id')}
                inputProps={{ name: "users",  id: "users-select"}}
              >
                { users && users.map((val, index) => {
                  let value = val.children[0];
                  let companyNames = val.children.length === 1 ? value.torgid_company?.name : val.children.map(data => data.torgid_company?.name+', ');
                  return <MenuItem
                    classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                    value={value.torgid_user_id}
                    key={value.torgid_user_id}
                  >
                      {value.torgid_user ? value.torgid_user.name : '' + ' ('+companyNames+')'}
                  </MenuItem>
                 })
                }
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12}>
            <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} variant="standard">
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('roles')} </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu, style: {zIndex: 35001} }}
                classes={{ select: classes.select }}
                value={this.state.role}
                onChange={e => this.handleChange(e.target.value, 'role')}
                inputProps={{ name: "roles",  id: "roles-select"}}
              >
                { roles && roles.map((value, index) => {
                  return <MenuItem
                    classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                    value={value.name}
                    key={value.id}
                  >
                    {value.name}
                  </MenuItem>
                })
                }
              </Select>
            </FormControl>
          </GridItem>

        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addCategory);