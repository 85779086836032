import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from 'react-i18next';

// core components
import Wizard from "../../components/Wizard/Wizard.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import SnackbarCustom from "../../components/Snackbar/Snackbar.js";

// @material-ui
import {AddAlert} from "@mui/icons-material";
import Step1 from "./AddUserSteps/Step1.js";

import {getCompanies} from "../../store/actions/company";
import {createUpdateCompanyUser, getCompanyUserByLogin} from "../../store/actions/companyUser";
import {getRegions} from "../../store/actions/region";
import {getSpecialCompany} from "../../store/actions/specialCompany";
import {LoaderSpinner} from "../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import {createUseStyles} from "react-jss";
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    colorDisabled: "fff"
  },
  disabledInputIcon: {
    colorDisabled: "eee"
  },
  inputAdornment: {
    position: "relative"
  },
  subscribed: {
    marginTop: "10px"
  },
  selectMenuItemSelected: {
    color: "#e91e63"
  },
  selectMenu: {
    color: "#e91e63"
  },
  select: {
    color: "#4c535a"
  },
  selectFormControl: {
    marginTop: '15px'
  },
  ...wizardStyle
};


const useStyles = createUseStyles(style);

const AddCompanyUser = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.companyUser.notification );
  const {companyUser} = useSelector( state => state.companyUser );
  const {companies, loading } = useSelector( state => state.company );
  const {regions, loadingRegion} = useSelector( state => state.region );
  const isUpdating = !!(state && state.user) || !!companyUser.results;
  const userDataFromState = state && state.user;
  const {specialCompanies, loading:loadingGroup } = useSelector( state => state.specialCompanies );

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getRegions());
    dispatch(getSpecialCompany());
  }, [dispatch]);

  const checkEmail = (data) => {
    dispatch(getCompanyUserByLogin(data));
  };

  const onSubmit = (userData) => {
    dispatch(createUpdateCompanyUser({updateUser: isUpdating, update:!!(state && state.user), updateProfile: userDataFromState && userDataFromState.isprofile ? userDataFromState.isprofile : false, ...userData}, history));
  };

  const renderUserData = () => {
    if(userDataFromState && userDataFromState.id) {
      return {
        ...userDataFromState,
        companyIds: [userDataFromState.torgid_company_id],
        groupIds: [userDataFromState.torgid_special_company_id]
      }
    } else if(userDataFromState && userDataFromState.length === 1) {
      return {
        ...userDataFromState[0],
        companyIds: [userDataFromState[0].torgid_company_id],
        groupIds: [userDataFromState[0].torgid_special_company_id]
      }
    }
    else if(userDataFromState && userDataFromState.length > 1) {
      let gids = [];
      userDataFromState && userDataFromState.forEach(data => {
        if(data.torgid_special_company_id && data.torgid_special_company_id !== null) {
          gids.push(data.torgid_special_company_id);
        }
      });

      return {
        ...userDataFromState[0],
        companyIds: userDataFromState && userDataFromState.map(data => data.torgid_company_id),
        groupIds: gids
      }
    }

    return false;
  };

  const userData = renderUserData() || companyUser.results ||  {};

  if (loading || loadingRegion ||  loadingGroup) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
      <GridItem xs={12} sm={10}>
        <Wizard
          classes={classes}
          validate
          history={history}
          user={userData}
          companies={companies || {}}
          checkEmail={checkEmail}
          data={regions}
          company={specialCompanies}
          steps={[
            { stepName: t('company_user.about'), stepComponent: Step1, stepId: "about" }
          ]}
          title={isUpdating ? t('company_user.update_profile') : t('company_user.build_profile')}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={t('finish')}
          finishButtonClick={onSubmit}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(AddCompanyUser);