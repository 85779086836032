import * as actionTypes from '../actionTypes';

const initialState = {
  loadingCategories: true,
  categories: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_COMPANY_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.GET_COMPANY_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.data,
        loadingCategories: false
      };
    case actionTypes.GET_COMPANY_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };


    case actionTypes.CREATE_COMPANY_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.CREATE_COMPANY_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_COMPANY_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_COMPANY_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.UPDATE_COMPANY_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_COMPANY_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };



    case actionTypes.DELETE_COMPANY_CATEGORY_START:
      return  {
        ...state,
        // loading: true
      };
    case actionTypes.DELETE_COMPANY_CATEGORY_SUCCESS:
      const id = action.data.results.id;
      const data = state.categories.results.filter((item) => item.id !== id);
      state.categories.results = data;
      return {
        ...state,
        // loading: false
      };
    case actionTypes.DELETE_COMPANY_CATEGORY_FAIL:
      return  {
        ...state,
        // loading: false
      };


    default:
      return state;
  }
}
