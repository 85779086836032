import React, {useEffect} from "react";
import { useDispatch, useSelector} from 'react-redux';
import { withTranslation } from 'react-i18next';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";

import {createUseStyles} from "react-jss";
import {Wc, Group, PersonPinCircle, Loyalty, Beenhere, LibraryAddCheck} from "@mui/icons-material";

import i18n from "../../locales/i18n";
import {getOfferReport} from "../../store/actions/offer";
import {Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {LoaderSpinner} from "../../components/Loader";

const styles = {
  ...alertStyles,
  danger: {
    backgroundColor: '#e8413d'
  },
  cardIconTitle: {
    color: '#000'
  },
  container: {
    borderTop: "1px solid #717172",
    margin: "0 0 20px 0"
  }
};
const useStyles = createUseStyles(styles);

const OfferReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lang = i18n.language.toLowerCase();
  const currentLang = lang.includes("is") ? 'is' :  'en';
  const t = props.t;
  const value = props.data;
  const {report, loadingReport} = useSelector( state => state.offer );
  const RADIAN = Math.PI / 180;

  useEffect(() => {
    dispatch(getOfferReport(value.id));
  }, [dispatch]);


  const genderData = report.results ? report.results.genders.filter(item => item.name !== 'other') : [{name: 'Male', value: 0},{name: 'Female', value: 0}];
  const agesData = report.results && report.results.ages ? Object.keys(report.results.ages).map(function(key) { return { name: key, value: report.results.ages[key] } }) : [{name: '', value: 0}];
  const data = report.results ? report.results.region_of_users : [{ name: 'Reykjanes', value: 0 }, { name: 'Suðurland', value: 0 }, { name: 'Höfuðborgarsvæðið', value: 0 }];
  const activateAndFavouriteData = report.results ? report.results.activates_and_favourites : [{name: '', activates: 0, favourites: 0}];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A1FF3C', '#3D5CFF', '#A2D5FF', '#FFFA0A'];
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };
  const renderCustomizedLabelValue = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value}
      </text>
    );
  };
  const wrapperStyle = {
    bottom: '0',
    left: 0,
    lineHeight: '24px',
  };


  const offerClicksTypes = {
    company: t('dash.company'),
    email: t('dash.email'),
    offer: t('dash.offer'),
    offer_link: t('dash.offer_link'),
    phone: t('dash.phone'),
    shared: t('dash.shared'),
    website: t('dash.website'),
  };
  //
  // const offerClicksData = !report.results ? [[]] : Object.entries(report.results.offer_clicls[0]).map(([k,v]) => {
  //   return <GridItem key={offerClicksTypes[k]+'key'}> <span style={{color: '#0db3c7', textTransform: 'capitalize'}}>{offerClicksTypes[k]} - </span> <span >{v ? v : 0}</span></GridItem>
  // });20% af legsteinum


  const offerActivatesData = !report.results ? [[]] : report.results.activates.sort(function(a, b){return b.id-a.id}).map((value, index) => {
    let date = value.created_at ? new Date(value.created_at) : '';
    return ([
      index+1,
      value.torgid_user && value.torgid_user.phone ? value.torgid_user.phone.replace(value.torgid_user.phone.substring(value.torgid_user.phone.length-6, value.torgid_user.phone.length-2), "****") : 'User not found',
      date ? date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() : ''
    ]);
  });

  const offerFavouritesData = !report.results ? [[]] : report.results.favourites.sort(function(a, b){return b.id-a.id}).map((value, index) => {
    let date = value.created_at ? new Date(value.created_at) : '';
    return ([
      index+1,
      value.torgid_user && value.torgid_user.phone ? value.torgid_user.phone.replace(value.torgid_user.phone.substring(value.torgid_user.phone.length-6, value.torgid_user.phone.length-2), "****") : 'User not found',
      date ? date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() : ''
    ]);
  });

  return (
    <SweetAlert
      showCancel
      style={{ display: "block", width: '1000px', maxHeight: '100%', overflow: 'scroll', backgroundColor: '#efefef' }}
      title={ currentLang === 'en' && value.title_en ? value.title_en : value.title }
      onCancel={() => props.onCancel()}
      onConfirm={() => props.onCancel()}
      showConfirm={false}
      closeOnClickOutside={true}
      cancelBtnCssClass={classes.button + " " + classes.danger}
    >
      { loadingReport ?
          <LoaderSpinner/>
          :
          <GridContainer justifyContent="space-around" container >

            <GridItem xs={10} sm={10} className={classes.container}/>

            <GridContainer justifyContent="space-around">
              <GridItem xs={12} sm={4}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info"> <Wc /> </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('dash.user_gender')}</h4>
                  </CardHeader>
                  <CardBody>
                    <ResponsiveContainer width="100%" height={160}>
                      <PieChart height={160}>
                        <Pie
                          data={genderData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={60}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {genderData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={
                              (entry.name === 'male' && '#0088FE') || (entry.name === 'female' && '#f423c6') || (entry.name === 'other' && '#FFBB28')
                            } />
                          ))}
                        </Pie>
                        <Legend iconSize={10} layout="horizontal" verticalAlign="bottom" wrapperStyle={wrapperStyle} />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={4}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info"> <Group /> </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('dash.user_ages')}</h4>
                  </CardHeader>
                  <CardBody>
                    <ResponsiveContainer width="100%" height={160}>
                      <BarChart
                        height={160}
                        data={agesData}
                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="value" name={t('users')} fill="#0088FE" />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={4}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info"> <PersonPinCircle /> </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('dash.user_region')}</h4>
                  </CardHeader>
                  <CardBody>
                    <ResponsiveContainer width="100%" height={160}>
                      <PieChart height={160}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabelValue}
                          outerRadius={50}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Legend iconSize={10} layout="horizontal" verticalAlign="bottom" wrapperStyle={wrapperStyle} />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>


            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="info" icon style={{textAlign:'left'}}>
                  <CardIcon color="info"> <LibraryAddCheck /> </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t('dash.offer_ac_fav')}</h4>
                </CardHeader>
                <CardBody>
                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart
                      height={200}
                      data={activateAndFavouriteData}
                      margin={{top: 5, right: 30, left: 20, bottom: 5}}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="activates" name={t('dash.activates')} fill="#0088FE" />
                      <Bar dataKey="favourites" name={t('dash.favourites')} fill="#00C49F" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardBody>
              </Card>
            </GridItem>


            {/*<GridItem xs={12} sm={12}>*/}
            {/*  <Card>*/}
            {/*    <CardHeader color="info" icon style={{textAlign:'left'}}>*/}
            {/*      <CardIcon color="info"> <LocalOffer /> </CardIcon>*/}
            {/*      <h4 className={classes.cardIconTitle}>{t('dash.offer_clicks')}</h4>*/}
            {/*    </CardHeader>*/}
            {/*    <CardBody>*/}
            {/*      <GridContainer justifyContent="space-between">*/}
            {/*        {offerClicksData}*/}
            {/*      </GridContainer>*/}
            {/*    </CardBody>*/}
            {/*  </Card>*/}
            {/*</GridItem>*/}

            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader color="info" icon style={{textAlign:'left'}}>
                  <CardIcon color="info"> <Beenhere /> </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {t('dash.activates')}
                    {report && report.results && report.results.activates ? ' ('+report.results.activates.length+')' : ''}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Table tableData={offerActivatesData}/>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader color="info" icon  style={{textAlign:'left'}}>
                  <CardIcon color="info"> <Loyalty /> </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {t('dash.favourites')}
                    {report && report.results && report.results.favourites ? ' ('+report.results.favourites.length+')' : ''}
                  </h4>
                </CardHeader>
                <CardBody>
                    <Table tableData={offerFavouritesData}/>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      }

    </SweetAlert>
  );

};

export default withTranslation('',{ withRef: true })(OfferReport);