import React, {useEffect} from "react";
import { withTranslation } from 'react-i18next';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "components/CustomButtons/Button.js";

import {createUseStyles} from "react-jss";
import {Email, Phone, Web} from "@mui/icons-material";
import i18n from "../../locales/i18n";

import offerPreviewStyle from "assets/jss/material-dashboard-pro-react/offerPreviewStyle";
import {useDispatch, useSelector} from "react-redux";
import {getOfferById} from "../../store/actions/offer";
import {LoaderSpinner} from "../../components/Loader";

const styles = {
  ...offerPreviewStyle,
  salert: {
    padding: '0 !important',
    borderRadius: '30px !important',
    width: '36em !important'
  }
};
const useStyles = createUseStyles(styles);

const OfferPreview = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {offerById, loadingOfferById} = useSelector( state => state.offer );
  const lang = i18n.language.toLowerCase();
  const currentLang = lang.includes("is") ? 'is' :  'en';
  const t = props.t;
  const tagType = { "kynning": '#0eae00', "2 fyrir 1": '#0089cf', "discount": '#faa214', "sérkjör": '#cf0000'};
  const [active_days] = React.useState([
    { "active_day": 0, "day": "mán.", "start_time": "", "end_time": "", active: false },
    { "active_day": 1, "day": "þri.", "start_time": "", "end_time": "", active: false },
    { "active_day": 2, "day": "mið.", "start_time": "", "end_time": "", active: false },
    { "active_day": 3, "day": "fim.", "start_time": "", "end_time": "", active: false },
    { "active_day": 4, "day": "fös.", "start_time": "", "end_time": "", active: false },
    { "active_day": 5, "day": "lau.", "start_time": "", "end_time": "", active: false },
    { "active_day": 6, "day": "sun.", "start_time": "", "end_time": "", active: false },
  ]);

  useEffect(() => {
    if(props.data && props.data.id) {
      dispatch(getOfferById(props.data.id));
    }
  }, [props.data]);

  const calculateOfferStatus = (activeTimes) => {
    var now = new Date().getDay()-1;
    var active_day = activeTimes.find(obj => { return obj.active_day === now });
    var d = new Date();
    var nowHours = d.getHours();
    var nowMinutes = d.getMinutes();

    if(active_day) {
      let startTime = active_day.start_time.split(":");
      let endTime = active_day.end_time.split(":");
      if (startTime[0] === "00" && endTime[0] === "23") {
        return <div> <span className="status active" /> <span style={{color: 'green'}}>●</span> {t('offer_is_available_all_day')} </div>
      }
      if (endTime[0] <= nowHours && endTime[1] <= nowMinutes) {
        return <div> <span className="status active" /> <span style={{color: 'red'}}>●</span> {`${t('offer_was_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`} </div>
      }
      if (startTime[0] >= nowHours) {
        return <div> <span className="status active" /> <span style={{color: 'red'}}>●</span> {`${t('offer_will_be_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`} </div>
      }
      return <div> <span className="status active" /> <span style={{color: 'green'}}>●</span> {`${t('offer_is_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]} ${t('offer_today')}`}</div>
    }
    return <div> <span className="status inactive" /> <span style={{color: 'red'}}>●</span> {t('offer_not_available_today')} </div>
  };

  if (loadingOfferById || Object.keys(offerById).length === 0) return (
    <SweetAlert
      style={{ display: "block" }}
      onCancel={() => props.onCancel()}
      onConfirm={() => props.onCancel()}
      showConfirm={false}
      closeOnClickOutside={true}
      title={false}
      customClass={classes.salert}
    >
      <LoaderSpinner/>
    </SweetAlert>
  );

  return (
    <SweetAlert
      style={{ display: "block" }}
      onCancel={() => props.onCancel()}
      onConfirm={() => props.onCancel()}
      showConfirm={false}
      closeOnClickOutside={true}
      title={false}
      customClass={classes.salert}
    >
      <GridContainer container direction="row" alignItems="flex-start">
        <div className={classes.box}>

          <div style={{backgroundImage: `url(${offerById.offer_images && offerById.offer_images.length>0 ? offerById.offer_images[0].image : offerById.image})`}} className={classes.boxImg2}> </div>

          <div className={classes.discount2} style={{backgroundColor: tagType[offerById.discount] ? tagType[offerById.discount] : tagType['discount']}}>
            <span className={classes.discountLeft} /> <span className={classes.discountTxt}> {offerById.discount}</span> <span className={classes.discountRight} />
          </div>

          <div className={classes.box_body2}>
            <div className={classes.box_title}> {currentLang === 'en' && offerById.title_en ? offerById.title_en : offerById.title} </div>
            <div className={classes.box_desc2}> {currentLang === 'en' && offerById.description_en ? offerById.description_en : offerById.description} </div>

            {
              offerById.torgid_offer_active_times && <div>
                <div className={classes.mStatus2}> {calculateOfferStatus(offerById.torgid_offer_active_times )} </div>
                <GridContainer justifyContent="space-between" className={classes.activeDays}>
                  {active_days.map((activeValue, activeIndex) => {
                    var result = offerById.torgid_offer_active_times.find(obj => { return obj.active_day === activeValue.active_day });
                    return result ? <Button key={activeIndex+'active'} color="warning" size="sm" style={{padding: '12px 12px'}}> {activeValue.day}  </Button>
                      : <Button key={activeIndex+'noactive'} color="default" size="sm" style={{padding: '12px 12px'}}> {activeValue.day}  </Button>
                  })}
                </GridContainer>
              </div>
            }

            <div className={classes.box_company_name2}> {offerById.torgid_company?.name} </div>
            <div className={classes.box_desc}> {currentLang === 'en' && offerById.torgid_company?.description_en ? offerById.torgid_company.description_en : offerById.torgid_company?.description} </div>

            <GridContainer justifyContent="space-between" className={classes.contacts}>

              {offerById.torgid_company.website && <div className="site"> <a href={offerById.torgid_company.website} target="_blank" title={offerById.torgid_company.website}> <Web className={classes.icon}/> {t('website')} </a> </div>}
              {offerById.torgid_company.email && <div className="email"> <a href={"mailto:" + offerById.torgid_company.email} target="_blank" title={offerById.torgid_company.email}> <Email className={classes.icon}/> {t('email')} </a> </div>}
              {offerById.torgid_company.phone && <div className="phone"> <a href={"tel:" +offerById.torgid_company.phone} target="_blank" title={offerById.torgid_company.phone}> <Phone className={classes.icon}/> {t('phone')} </a> </div>}

            </GridContainer>

          </div>

        </div>
      </GridContainer>
    </SweetAlert>
  );

};

export default withTranslation('',{ withRef: true })(OfferPreview);