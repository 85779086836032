import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {getCompanyUserProfile } from "../../../store/actions/companyUser";
import {withTranslation} from 'react-i18next';

// @material-ui
import {createUseStyles} from "react-jss";
import {Email, Phone, PermContactCalendar, Wc, Home, PersonPinCircle, Language, Favorite, Check, Close, Ballot, Business} from '@mui/icons-material';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import avatar from "assets/img/faces/marc.jpg";
import {LoaderSpinner} from "../../../components/Loader";
const useStyles = createUseStyles(styles);

const CompanyUserProfile = ({ t }) => {
  const classes = useStyles();
  const {state} = useLocation();
  const user = state && state.user || {};
  const {companyUserProfile, loading} = useSelector( state => state.companyUser )
  const dispatch = useDispatch ();

  useEffect(() => {
    dispatch(getCompanyUserProfile({...user.torgid_user}));
  }, [dispatch]);

  if (loading) return (<LoaderSpinner/>);

  const offersData = companyUserProfile.results ?
    companyUserProfile.results.offers.map((value, index) => {
      return ([
        index+1,
        value.title || '',
        value.approved ? 'yes' : 'no',
      ]);
    }) :  [[ ]];

  const companiesData = companyUserProfile.results ?
    companyUserProfile.results.companies.map((value, index) => {
      return ([
        index+1,
        value.name || '',
        value.phone || '',
        value.email || ''
      ]);
    }) :  [[ ]];
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>

          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info"> <Ballot /> </CardIcon>
                  <p className={classes.cardCategory}> {t('offers')} </p>
                  <h3 className={classes.cardTitle}>
                    {companyUserProfile.results && companyUserProfile.results.offers.length}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info"> <Business /> </CardIcon>
                  <p className={classes.cardCategory}>{t('companies')}</p>
                  <h3 className={classes.cardTitle}>
                    {companyUserProfile.results && companyUserProfile.results.companies.length}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>

          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"> <Ballot /> </CardIcon>
              <h4 className={classes.cardIconTitle}> <small>{t('offers')}</small> </h4>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHead={["#", t('title'),  t('approved')]}
                tableData={offersData}
                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[classes.center, classes.right, classes.right]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            </CardBody>
          </Card>

          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"> <Business /> </CardIcon>
              <h4 className={classes.cardIconTitle}><small>{t('companies')}</small></h4>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHead={[ "#", t('name'), t('phone'), t('email') ]}
                tableData={ companiesData}
                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[classes.center, classes.right, classes.right]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            </CardBody>
          </Card>

        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#" onClick={e => e.preventDefault()}>
                <img src={user.torgid_user && user.torgid_user.profile_picture ? user.torgid_user.profile_picture : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{user.torgid_user && user.torgid_user.name}</h4>
              <h6 className={classes.cardCategory} title="Email"><Email  className={classes.cardIcon} /> {user.torgid_user && user.torgid_user.email}</h6>
              <h6 className={classes.cardCategory} title="Phone"><Phone  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.phone}</h6>
              <h6 className={classes.cardCategory} title="Date of birth"><PermContactCalendar  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.date_of_birth}</h6>
              <h6 className={classes.cardCategory} title="Gender"><Wc className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.gender}</h6>
              <h6 className={classes.cardCategory} title="Address"><Home  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.address}</h6>
              <h6 className={classes.cardCategory} title="Postal code"><PersonPinCircle  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.postalcode}</h6>
              <h6 className={classes.cardCategory} title="Language"><Language  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.language}</h6>
              <h6 className={classes.cardCategory} title="Subscribed"><Favorite  className={classes.cardIcon}  /> {user.torgid_user && user.torgid_user.subscribed ? <Check /> : <Close /> }</h6>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withTranslation('',{ withRef: true })(CompanyUserProfile);