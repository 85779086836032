const offerPreviewStyle = {
  boxMain: {
    padding: '10px',
    marginBottom: '40px'
  },
  boxMain2: {
    padding: '10px',
    marginBottom: '40px',
    transition: 'all 0.5s ease',
    cursor: 'pointer',
    "&:hover": {
      transform: 'scale(1.03) !important'
    }
  },
  box: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: 'auto',
    paddingBottom: '20px',
    backgroundColor: "#fff",
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: '0 3px 14.2px rgba(0,0,0,.074)',
    borderRadius: '15px',
    textAlign: 'left'
  },
  boxImg: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: '300px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 0%',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderBottom: '1px solid rgba(0,0,0,.125)'
  },
  boxImg2: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: '400px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderBottom: '1px solid rgba(0,0,0,.125)'
  },
  discount: {
    position: 'absolute',
    top: '-15px',
    right: 0,
    width: 'auto',
    height: '30px'
  },
  discount2: {
    position: 'absolute',
    top: '384px',
    right: '31px',
    width: 'auto',
    height: '30px'
  },
  discountLeft: {
    position: 'relative',
    float: 'left',
    width: '21px',
    height: '21px',
    left: '-10px',
    top: '4px',
    transform: 'rotate(45deg)',
    backgroundColor: 'inherit',
    borderRadius: '2px'
  },
  discountRight: {
    position: 'relative',
    float: 'right',
    width: '21px',
    height: '21px',
    right: '-10px',
    top: '4px',
    transform: 'rotate(45deg)',
    backgroundColor: 'inherit',
    borderRadius: '2px'
  },
  discountTxt: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '30px',
    fontWeight: 'bold'
  },
  box_body: {
    position: 'relative',
    float: 'left',
    width: '90%',
    left: '5%',
    marginTop: '25px',
    color: '#313131',
    fontSize: '16px',
  },
  box_body2: {
    position: 'relative',
    float: 'left',
    width: '80%',
    left: '10%',
    marginTop: '25px',
    color: '#313131',
    fontSize: '16px',
  },
  box_title: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '25px'
  },
  box_company_name: {
    fontSize: '18px',
    fontWeight: '500',
    padding: '10px 0'
    // lineHeight: '26px'
  },
  box_company_name2: {
    padding: '20px 0 0 0',
    position: 'relative',
    float: 'left',
    width: '100%',
    borderTop: '1px solid #eee',
    marginTop: '20px',
    fontSize: '22px',
    fontWeight: '500'
  },
  box_desc: {
    position: 'relative',
    float: 'left',
    width: '100%',
    margin: '10px 0',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '18px'
  },
  box_desc_short: {
    position: 'relative',
    float: 'left',
    width: '100%',
    margin: '10px 0 0',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '18px'
  },
  box_desc2: {
    position: 'relative',
    float: 'left',
    width: '100%',
    margin: '10px 0',
    fontSize: '18px'
  },

  activeDays: {
    position: 'relative',
    float: 'left',
    width: '95%',
    left: '5%'
  },
  mStatus: {
    position: 'relative',
    float: 'left',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '10px',
  },
  mStatus2: {
    position: 'relative',
    float: 'left',
    width: '100%',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px',
  },
  contacts: {
    margin: '20px 0',
    padding: '20px 10px 0 10px',
    // borderTop: '1px solid rgba(0,0,0,.125)',
    width: '100%',
    color: '#003a58',
    fontSize: '20px',
    fontWeight: 500,
    cursor: 'pointer',
    "& a": {
      color: '#003a58'
    },
    "& a:hover": {
      color: '#faa113'
    }
  },
  icon: {
    position: 'relative',
    float: 'left',
  },
  icon2: {
    position: 'relative',
    float: 'left',
    color: '#4caf50',
    fontSize: '40px',
    cursor: 'pointer',
    "&:hover": {
      color: '#faa113'
    }
  },
};

export default offerPreviewStyle;
