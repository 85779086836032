import _ from 'lodash';
import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  categories: [],
  count: 0,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_CATEGORY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_CATEGORY_SUCCESS:
      let data = action.data.results.sort((a, b) => a.id - b.id);
      return {
        ...state,
        count: action.data.count,
        categories: _.chain(data)
          .groupBy("parent_id")
          .map((value, key) => ({ parent_id: key, children: value }))
          .value(),
        loading: false
      };
    case actionTypes.GET_CATEGORY_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.DELETE_CATEGORY_START:
      return  {
        ...state
      };
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      if(action.data.results.parent_id ===0) {
        state.categories[0].children.find((o, i) => {
          if (o.id == action.data.results.id) {
            state.categories[0].children.splice(i,1);
            return true;
          }
        });
      } else {
        state.categories.find((o, i) => {
          if (o.parent_id == action.data.results.parent_id) {
            state.categories[i].children.find((s, si) => {
              if (s.id == action.data.results.id) {
                state.categories[i].children.splice(si,1);
                return true; // stop searching
              }
            });
          }
        });
      }
      return {
        ...state
      };
    case actionTypes.DELETE_CATEGORY_FAIL:
      return  {
        ...state
      };


    case actionTypes.CREATE_CATEGORY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_CATEGORY_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_CATEGORY_START:
      return  {
        ...state
      };
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      if(action.data.results.parent_id ===0) {
        state.categories[0].children.find((o, i) => {
          if (o.id == action.data.results.id) {
            state.categories[0].children[i] = {
              id: action.data.results.id,
              name: action.data.results.name,
              name_en: action.data.results.name_en,
              name_pl: action.data.results.name_pl,
              parent_id: action.data.results.parent_id,
              sort_order: action.data.results.sort_order,
              icon: action.data.results.icon
            };
            return true; // stop searching
          }
        });
      } else {
        state.categories.find((o, i) => {
          if (o.parent_id == action.data.results.parent_id) {
            state.categories[i].children.find((s, si) => {
              if (s.id == action.data.results.id) {
                state.categories[i].children[si] = {
                  id: action.data.results.id,
                  name: action.data.results.name,
                  name_en: action.data.results.name_en,
                  name_pl: action.data.results.name_pl,
                  parent_id: action.data.results.parent_id,
                  sort_order: action.data.results.sort_order,
                  icon: action.data.results.icon
                };
                return true; // stop searching
              }
            });
          }
        });
      }
      return  {
        ...state,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return  {
        ...state,
        notification: { status: true, type: 'error', message: action.data }
      };

    default:
      return state;
  }
}
