import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {createUseStyles} from "react-jss";

import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import SnackbarCustom from "../../../components/Snackbar/Snackbar.js";
import {AddAlert} from "@mui/icons-material";

import {useDispatch, useSelector} from "react-redux";
import {createUpdateSpecialCompany,  getSpecialCompanyById} from "../../../store/actions/specialCompany";
import Step1 from "./AddSteps/Step1.js";
import {LoaderSpinner} from "../../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
const style = {
  inputAdornment: {
    position: "relative"
  },
  colorPickerIcon: {
    position: "relative",
    cursor: 'pointer',
    color: '#de2c49',
    '& svg': {
      color: '#de2c49'
    }
  },
  ...wizardStyle
};

const useStyles = createUseStyles(style);

const AddGroup = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.offer.notification );
  const {specialCompaniesById, loading } = useSelector( state => state.specialCompanies );
  const isUpdating = !!(state && state.special_company_data);
  const dataByState = state && state.special_company_data;
  let store = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {
    if(dataByState && dataByState.id) {
      dispatch(getSpecialCompanyById(dataByState.id));
    }
    else if(store && store.roles[0] === 'group_admin' && store.user.torgid_special_company_id) {
      dispatch(getSpecialCompanyById(store.user.torgid_special_company_id));
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(createUpdateSpecialCompany(!!data.about.id, data, history));
  };

  if (loading) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status} />
      <GridItem xs={12} sm={10}>
        <Wizard
          validate
          classes={classes}
          history={history}
          data={dataByState || store.roles[0] === 'group_admin' ? specialCompaniesById : {}}
          steps={[
            { stepName: t('menu.groups'), stepComponent: Step1, stepId: "about" },
          ]}
          title={isUpdating ? t('group.update') : t('group.add_new')}
          subtitle=""
          finishButtonClick={onSubmit}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={t('finish')}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(AddGroup);