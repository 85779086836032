import React from "react";
import Datetime from "react-datetime";
import {withTranslation} from 'react-i18next';

import moment from 'moment';
import TimePicker from 'rc-time-picker';
import "rc-time-picker/assets/index.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextareaAutosize,
  Radio,
  RadioGroup,
  Checkbox,
  TextField
} from "@mui/material";
import {ExpandMore, ExpandLess, AlarmOff, AlarmOn, Add} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/MultipleUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from "@mui/material/Autocomplete";

const activateOfferType = {
  "email": 'Netfang sem á að senda á',
  "website": 'Settu vefsíðu hér',
  "promocode": 'Settu kóða hér',
  "phone": 'Settu símanúmer hér',
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.store = JSON.parse(localStorage.getItem('user'));
    this.user_is_group_admin = !!(this.store && this.store.roles.includes('group_admin'));
    this.user_is_company_user = !!(this.store && this.store.roles.includes('company_user'));
    this.user_is_group_id = this.store.user.torgid_special_company_id
    this.state = {
      alert: '',
      torgid_user_id: JSON.parse(localStorage.getItem('user')).user.torgid_user_id,
      id: props.offerData.id || "",
      title: props.offerData.title || "",
      titleState: props.offerData.title ? "success" : "",
      title_en: props.offerData.title_en || "",
      description: props.offerData.description || "",
      descriptionState: props.offerData.description ? "success" : "",
      description_en: props.offerData.description_en || "",
      discount: !props.offerData.discount ? "" : (props.offerData.discount.includes('%') ? 'discount' : props.offerData.discount.includes('kr.') ? 'price' : props.offerData.discount),
      discountInput: !props.offerData.discount ? "" : (props.offerData.discount.includes('%') ? props.offerData.discount.slice(0, -1) : props.offerData.discount.includes('kr.') ? props.offerData.discount.slice(0, -3) : ""),
      // special_discount: !props.offerData.special_discount ? "" : props.offerData.special_discount.includes('%') ? props.offerData.special_discount.slice(0, -1)  : props.offerData.special_discount,
      sort_order: props.offerData.sort_order || 0,
      approved: props.offerData.id ? props.offerData.approved : false,
      image: '',
      images: props.offerData.offer_images && props.offerData.offer_images.length > 0 ? props.offerData.offer_images : props.offerData &&  props.offerData.image !== null ? [{"id": null, "image": props.offerData.image}] : [],
      imagesState: props.offerData.offer_images && props.offerData.offer_images.length > 0 ? "success" : props.offerData.image !== null  ? "success" : "",
      action_type: props.offerData.action_type || 'timer',
      action_value: props.offerData.action_value || '',
      promocode: props.offerData.promocode || '',
      offer_link: props.offerData.offer_link || '',
      is_active: props.offerData.id ? props.offerData.is_active : true,
      start_date: props.offerData.start_date || "",
      end_date: props.offerData.end_date || "",
      torgid_offer_active_times: [
        { "active_day": 0, "day": "mán.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 1, "day": "þri.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 2, "day": "mið.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 3, "day": "fim.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 4, "day": "fös.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 5, "day": "lau.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 6, "day": "sun.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
      ],
      active_times_status: false,
      torgid_special_company_id: props.offerData.torgid_special_company ? props.offerData.torgid_special_company.id : this.user_is_group_admin ? this.user_is_group_id : 0,
      companyId: props.offerData.id && props.offerData.torgid_company ? props.offerData.torgid_company.id : this.user_is_company_user ? JSON.parse(localStorage.getItem('user')).user.torgid_company_id : '',
      companyState: props.offerData.id && props.offerData.torgid_company ? "success" : this.user_is_company_user ? "success" : "",
      companies: JSON.parse(localStorage.getItem('user')).companies,
      torgid_special_company_ids: !props.offerData.id ?
        this.store.roles.length === 1 && this.store.roles[0] === 'group_admin' && this.store.groupIds.length === 1 ? this.store.groupIds : []
        :
        props.offerData.group_ids ? props.offerData.group_ids.split(',').map(x=>+x) : props.offerData.torgid_special_company_id ? [props.offerData.torgid_special_company_id] : [0],
      groupState: props.offerData.group_ids || this.user_is_group_admin ? "success" : props.offerData.id && props.offerData.group_ids === null ? "success" :  "",
    };
  }

  componentDidMount() {
    if(this.props.offerData.torgid_offer_active_times) {
      let activeDaysState = [...this.state.torgid_offer_active_times];
      this.props.offerData.torgid_offer_active_times.forEach(value => {
        activeDaysState[value.active_day].active = true;
        activeDaysState[value.active_day].start_time = value.start_time;
        activeDaysState[value.active_day].end_time = value.end_time;
      });
      this.setState({torgid_offer_active_times: activeDaysState});
    }
    if(!this.state.id && this.state.companyId && !this.user_is_group_admin) {
      this.props.getData(this.state.companyId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.offerData.id && state.companyId && props.data2 && props.data2.results && state.active_times_status) {
      let activeDays = [
        { "active_day": 0, "day": "mán.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 1, "day": "þri.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 2, "day": "mið.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 3, "day": "fim.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 4, "day": "fös.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 5, "day": "lau.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
        { "active_day": 6, "day": "sun.", "start_time": "08:00", "end_time": "18:00", active: false, active24: false },
      ];
      if(props.data2.results.torgid_company_locations.length > 0) {
        props.data2.results.torgid_company_locations[0].torgid_company_openings.forEach(value => {
          activeDays[value.day].active = true;
          activeDays[value.day].start_time = value.open_time;
          activeDays[value.day].end_time = value.close_time;
        });
      }
      return {torgid_offer_active_times: activeDays, active_times_status: false};
    }
    return null;
  }

  sendState() {
    return this.state;
  }

  verifyLength(value, length) {
    return value.length >= length;
  }

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  handleChangeOption = (event) => {
    const {target: { value }} = event;
    let last = value[value.length-1];
    if(last === 0 || last === 1 || last === 3) {
      this.setState({ 'torgid_special_company_ids': [last] });
    } else {
      if(value.indexOf(0)> -1 || value.indexOf(1)> -1 || value.indexOf(3)> -1) {
        this.setState({ 'torgid_special_company_ids': [last] });
      } else {
        this.setState({ 'torgid_special_company_ids': value });
      }
    }
    this.setState({'groupState': 'success'})
  };

  handleChangeGroup = (data) => {
    this.setState({ 'torgid_special_company_id': data });
    this.setState({'groupState': 'success'})
  };

  handleChangeCompanyGroup = (data, type) => {
    if(!this.state.id && !this.user_is_group_admin) {
      this.props.getData(data.id);
    }
    this.setState({
      [type]: data.id,
      companyState: 'success',
      active_times_status: true
    });
  };

  handleChangeCompany = (data, type) => {
    if(!this.state.id && !this.user_is_group_admin) {
      this.props.getData(data);
    }
    this.setState({
      [type]: data,
      companyState: 'success',
      active_times_status: true
    });
  };

  onUpload(data, index, id) {
    let images = this.state.images;
    if(images[index].image && images[index].image !== "" && images[index].image.includes("http")) {
      images[index].deleted = true;
      images.push(
        {
          "id": null,
          "image": data,
          "sort_order": this.state.images.length+1
        }
      )
    } else {
      images[index].id = id;
      images[index].image = data;
      images[index].sort_order = index+1;
    }

    this.setState({
      images: images,
      imagesState: "success"
    });
  }

  changeImageOrder(currentIndex, newIndex) {
    let images = this.state.images;
    let currentItem = images[currentIndex];
    images.splice(currentIndex, 1);
    images.splice(newIndex, 0, currentItem);
    this.setState({images: images});
  }

  deleteImageItem(index) {
    let i = 0;
    let images = this.state.images;
    if(images[index].id === null && (!images[index].image || images[index].image === "")){
      images.splice(index, 1)
    } else {
      images[index].deleted = true;
    }

    images.map((item, itemIndex) => {
      if(!item.deleted) {
        i++;
        images[itemIndex].sort_order = i;
      }
    });
    this.setState({images: images});
  }

  addNewImage = () => {
    this.setState({
      "images": [
        ...this.state.images,
        {
          "id": null,
          "image": "",
          "sort_order": this.state.images.length+1
        }
      ]
    })
  };

  change(event, stateName, type, stateNameEqualTo) {
    if (type === "length") {
      if (this.verifyLength(event.target.value, stateNameEqualTo)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    if (
      this.state.titleState === "success" &&
      this.state.descriptionState === "success" &&
      this.state.companyState === "success" &&
      this.state.imagesState === "success" &&
      this.state.groupState === "success"
    ) {
      return true;
    } else {
      if (this.state.titleState !== "success") {
        this.setState({ titleState: "error" });
      }
      if (this.state.descriptionState !== "success") {
        this.setState({ descriptionState: "error" });
      }
      if (this.state.companyState !== "success") {
        this.setState({ companyState: "error" });
      }
      if (this.state.imagesState !== "success") {
        this.setState({ imagesState: "error" });
      }
      if (this.state.groupState !== "success") {
        this.setState({ groupState: "error" });
      }
    }
    return false;
  }


  handleActiveTime(index) {
    let activeTimes = [...this.state.torgid_offer_active_times];
    activeTimes[index] = {
      "active_day": index,
      "day": activeTimes[index].day,
      "start_time": activeTimes[index].start_time,
      "end_time": activeTimes[index].end_time,
      active: !activeTimes[index].active,
      active24: activeTimes[index].active24,
    };
    this.setState({torgid_offer_active_times: activeTimes});
  }

  handleActiveTimeDef(index) {
    let activeTimes = [...this.state.torgid_offer_active_times];
    activeTimes[index] = {
      "active_day": index,
      "day": activeTimes[index].day,
      "start_time": '00:00',
      "end_time": '23:59',
      active: true,
      active24: !activeTimes[index].active24
    };
    this.setState({torgid_offer_active_times: activeTimes});
  }

  handleChangeTime = (e, index, type) => {
    let val = e ? e.format('HH:mm') : '00:00';
    let activeTimes = [...this.state.torgid_offer_active_times];
    activeTimes[index] = {
      "active_day": index,
      "day": activeTimes[index].day,
      "start_time": type ==="st" ? val : activeTimes[index].start_time,
      "end_time": type ==="et" ? val : activeTimes[index].end_time,
      active: true,
      active24: false
    };
    this.setState({torgid_offer_active_times: activeTimes});
  };

  hideAlert = () => {
    this.setState({alert: null})
  };

  autoTranslate = (state, type, fromLn, toLn) => {
    let textOld = this.state[state];
    let text = textOld.replace(/&/g, '');
    axios.get(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU&q=${text}&source=${fromLn}&target=${toLn}`)
      .then((response) => {
        if(response.status === 200) {
          let translatedText = response.data.data.translations[0].translatedText;
          this.setState({  [type]: translatedText.replace(new RegExp('&#39;', 'g'), "'") });
        } else {
          this.setState({alert: <SweetAlert title={response.statusText} onConfirm={() => this.hideAlert()} showConfirm={false} closeOnClickOutside={true}/>});
          setTimeout(this.hideAlert, 2000);
        }
      });
  };

  render() {
    const {classes, offerData, data, companies, t } = this.props;
    const renderActiveTime = this.state.torgid_offer_active_times && this.state.torgid_offer_active_times.map((value, index) => {
    const startTime = moment(value.start_time,'H:mm');
    const endTime = moment(value.end_time,'H:mm');
      return (
        <GridItem key={index} xs={12} sm={1}>
          <Button
          key={index+"btn"}
          color={value.active ? "success" : "default"}
          size="sm"
          onClick={() => this.handleActiveTime(index)}>
            { this.props.t(value.day) }
            {value.active ? <ExpandLess style={{marginRight: 0}} /> : <ExpandMore style={{marginRight: 0}} />}
          </Button>
          {value.active && value.active24 ? <AlarmOn onClick={() => this.handleActiveTimeDef(index)}/> : <AlarmOff  onClick={() => this.handleActiveTimeDef(index)} />}
          { value.active && (
            <GridItem key={index} xs={12} sm={6}>
              <TimePicker
                style={{ width: 80, left: "-10px",  }}
                showSecond={false}
                value={startTime}
                placeholder="Start"
                use12Hours={false}
                minuteStep={10}
                onChange = {(e) => this.handleChangeTime(e, index, 'st')}
              />
              <TimePicker
                style={{ width: 80, left: "-10px",  }}
                showSecond={false}
                value={endTime}
                placeholder="End"
                use12Hours={false}
                minuteStep={10}
                onChange = {(e) => this.handleChangeTime(e, index, 'et')}
              />
            </GridItem>

          )}
      </GridItem>);
    });

    const optionsCompany = companies.results &&  companies.results.filter(item => item.id !== 1305).map((option) => {
      const firstLetter = option.name.charAt(0).toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    return (
      <GridContainer justifyContent="space-between">
        {this.state.alert}

        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.titleState === "success"}
            error={this.state.titleState === "error"}
            labelText={ <span> {t('offer_title')} </span> }
            id="title"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.title,
              onChange: event => this.change(event, "title", "length", 5),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText={ <span> {t('offer_title_en')} </span> }
            id="title_en"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.title_en,
              onChange: event => this.change(event, "title_en"),
            }}
            helperText={this.state.title ? <Button color="success" size="sm" onClick={() => this.autoTranslate('title','title_en', 'is', 'en')}> {t('translate_is_en')} </Button> : ''}
          />
        </GridItem>

        <GridItem xs={12} sm={12} >
          <div className={classes.uploader_div}>
            Images
            <Button color="success" key={'report'} size="sm" disabled={this.state.images.length === 4} className={classes.add_more_image_btn} onClick={() => this.addNewImage()}>
              <Add/>
            </Button>
          </div>
          <GridContainer justifyContent="space-between">
            {
              this.state.images.map((item, index) => {
                if(!item.deleted) {
                  return (
                    <GridItem xs={12} sm={6} style={{margin: '5px 0'}}>
                      <PictureUpload
                        imgHolder={item.image || null}
                        index={index+1}
                        id={item.id}
                        onChangeOrder={(currentIndex, newIndex) => this.changeImageOrder(currentIndex-1, newIndex-1)}
                        onUpload={(data, i, id) => this.onUpload(data, i-1, id)}
                        deleteItem={(i) => this.deleteImageItem(i-1)}
                        disabledDeleteBtn={this.state.images.length === 1}
                        errorStatus={this.state.imagesState}
                      />
                    </GridItem>
                  )
                }
              })
            }
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} >
          {this.user_is_group_admin && companies && <Autocomplete
            id={"companies"}
            onChange={(event, newValue) => {
              this.handleChangeCompanyGroup(newValue, 'companyId')
            }}
            value={this.state.companyId ? optionsCompany.find(item => item.id === this.state.companyId) : null }
            options={optionsCompany ? optionsCompany.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={t('companies')} error={this.state.companyState === "error"}/>}
          />
          }

          {!this.user_is_group_admin && this.state.companies.length>1 &&
          <FormControl fullWidth className={classes.selectFormControl} >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('companies')} </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={this.state.companyId}
              onChange={e => this.handleChangeCompany(e.target.value, 'companyId')}
              inputProps={{ name: "companyId", id: "companyId"}}
            >
              {
                this.state.companies.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((value, index) =>
                  <MenuItem key={index} value={value.id}> {value.name} </MenuItem>
                )
              }
            </Select>
          </FormControl>
          }
        </GridItem>

        <GridItem xs={12} sm={6} className={classes.paddingRow}>
          <TextareaAutosize
            className={`
              ${classes.txtArea} 
              ${this.state.descriptionState === "success" && classes.txtAreaSuccess} 
              ${this.state.descriptionState === "error" && classes.txtAreaError}
            `}
            minRows={4}
            maxRows={8}
            id="description"
            aria-label="description"
            placeholder={t('offer_desc_is')}
            value={this.state.description}
            onChange={event => this.change(event, "description", "length", 10)}
          />
        </GridItem>

        <GridItem xs={12} sm={6}  className={classes.paddingRow}>
          <TextareaAutosize
            className={classes.txtArea}
            minRows={4}
            maxRows={8}
            id="description_en"
            aria-label="description_en"
            placeholder={t('offer_desc_en')}
            value={this.state.description_en}
            onChange={event => this.change(event, "description_en")}
          />
          {!this.state.description ? <Button color="default" size="sm">  {t('please_fill_description')} </Button>
            : <Button color="success" size="sm" onClick={() => this.autoTranslate('description', 'description_en', 'is', 'en')}> {t('translate_is_en')} </Button>
          }
        </GridItem>


        {(this.store.roles.length === 1 && this.store.roles[0] === 'group_admin' && this.store.groupIds.length > 1) && data.results &&
          <GridItem xs={12} sm={6}>
          <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} error={this.state.groupState === "error"}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('special_companies')} </InputLabel>
            <Select
              MenuProps={{className: classes.selectMenu, style: {zIndex: 35001}}}
              classes={{select: classes.select}}
              value={this.state.torgid_special_company_ids}
              onChange={(e) => this.handleChangeOption(e)}
              // onChange={e => this.handleChange(e.target.value, 'torgid_special_company_id')}
              inputProps={{name: "special_company", id: "roles-select"}}
              multiple
              renderValue={(selected) => selected.map((value, index) => { return value === 0 ? t('offer.special_companies_Default') : (data.results.find((x) => x.id === value)?.name || "") + ", "})}
            >
              {
                data.results && data.results.filter(item => item.id !== 1 && item.id !== 3  && this.store.groupIds.includes(item.id)).map((value, index) => (
                  <MenuItem key={value.id} value={value.id}>
                    <Checkbox checked={this.state.torgid_special_company_ids.indexOf(value.id) > -1}/>
                    {value.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </GridItem>
        }

        {this.store.roles.includes('company_user') && data.results &&
        <GridItem xs={12} sm={6}>
          <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} error={this.state.groupState === "error"}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('special_companies')} </InputLabel>
              <Select
                MenuProps={{className: classes.selectMenu, style: {zIndex: 35001}}}
                classes={{select: classes.select}}
                value={this.state.torgid_special_company_ids}
                onChange={(e) => this.handleChangeOption(e)}
                // onChange={e => this.handleChange(e.target.value, 'torgid_special_company_id')}
                inputProps={{name: "special_company", id: "roles-select"}}
                multiple
                renderValue={(selected) => selected.map((value, index) => { return value === 0 ? t('offer.special_companies_Default') : (data.results.find((x) => x.id === value)?.name || "") + ", "})}
              >
                <MenuItem key={'torgid_special_company_id_null'} value={0} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}>
                  <RadioGroup row aria-labelledby="default_opt" value={this.state.torgid_special_company_ids.indexOf(0) > -1 ? 0 : null}
                              name='row-group_0' className={classes.special_option}>
                    <FormControlLabel className={classes.special_option_label} value={0} control={<Radio/>} label={t('offer.special_companies_Default')}/>
                  </RadioGroup>
                </MenuItem>
                <MenuItem key={'torgid_special_company_id_lyk'} value={1} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}>
                  <RadioGroup row aria-labelledby="default_opt"
                              value={this.state.torgid_special_company_ids.indexOf(1) > -1 ? 1 : null}
                              name='row-group_1' className={classes.special_option}>
                    <FormControlLabel className={classes.special_option_label} value={1} control={<Radio/>} label={t('offer.special_companies_Lykilkjör')}/>
                  </RadioGroup>
                </MenuItem>
                <MenuItem key={'torgid_special_company_id_b2b'} value={3}
                          classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}>
                  <RadioGroup row aria-labelledby="default_opt"
                              value={this.state.torgid_special_company_ids.indexOf(3) > -1 ? 3 : null}
                              name='row-group_3' className={classes.special_option}>
                    <FormControlLabel className={classes.special_option_label} value={3} control={<Radio/>}
                                      label={t('offer.special_companies_B2B')}/>
                  </RadioGroup>
                </MenuItem>

                {
                  data.results && data.results.filter(item => item.id !== 1 && item.id !== 3 && item.hidden !== true).map((value, index) => (
                    <MenuItem key={value.id} value={value.id}>
                      <Checkbox checked={this.state.torgid_special_company_ids.indexOf(value.id) > -1}/>
                      {value.name}
                    </MenuItem>
                  ))
                }
              </Select>
          </FormControl>
        </GridItem>
        }

        <GridItem xs={12} sm={3} style={{marginTop: '20px'}}>
          <FormControl>
            <Datetime
              value={this.state.start_date}
              onChange={e => this.handleChange(e, 'start_date')}
              timeFormat={false}
              className={classes.datePicker}
              inputProps={{ placeholder: t('offer_date_from') }}
              dateFormat="yyyy-MM-D"
              classes={{ label: classes.label }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={3} style={{marginTop: '20px'}}>
          <FormControl>
            <Datetime
              value={this.state.end_date}
              onChange={e => this.handleChange(e, 'end_date')}
              timeFormat={false}
              className={classes.datePicker}
              inputProps={{ placeholder: t('offer_date_till') }}
              dateFormat="yyyy-MM-D"
            />
          </FormControl>
        </GridItem>


        <GridItem xs={12} sm={6} style={{margin: '10px 0 20px 0'}}>
          <FormControl fullWidth className={classes.selectFormControl} variant="standard">
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {t('offer_new_discount')}
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={this.state.discount}
              onChange={e => this.handleChange(e.target.value, 'discount')}
              inputProps={{ name: "discount", id: "discount"}}
            >
              <MenuItem classes={{ selected: classes.dSelected}} value="discount"> {t('offer_discount')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="price"> {t('offer_price')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="2 fyrir 1"> {t('offer_2_for_1')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="sérkjör"> {t('offer_special')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="tilkynning"> {t('offer_announcement')} </MenuItem>
            </Select>
          </FormControl>
          {(this.state.discount === 'discount' || this.state.discount === 'price') &&
          <CustomInput
            labelText={ <span> {t('offer_displayed_discount')} </span> }
            id="discount_input"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.discountInput,
              onChange: event => this.change(event, "discountInput"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {this.state.discount === 'discount' ? '%' : 'kr.'}
                </InputAdornment>
              )
            }}
          />
          }

          {/*{this.state.discount === 'discount' &&*/}
          {/*<CustomInput*/}
          {/*  labelText={ <span> {t('offer_discount_spec_discount')} </span> }*/}
          {/*  id="discount_input"*/}
          {/*  formControlProps={{ fullWidth: true }}*/}
          {/*  inputProps={{*/}
          {/*    value: this.state.special_discount,*/}
          {/*    onChange: event => this.change(event, "special_discount"),*/}
          {/*    endAdornment: (*/}
          {/*      <InputAdornment position="end" className={classes.inputAdornment}>%</InputAdornment>*/}
          {/*    )*/}
          {/*  }}*/}
          {/*/>*/}
          {/*}*/}
        </GridItem>

        <GridItem xs={12} sm={6} style={{margin: '10px 0 20px 0'}}>
          <FormControl fullWidth className={classes.selectFormControl} variant="standard">
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {t('activate_offer')}
            </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={this.state.action_type}
              onChange={e => this.handleChange(e.target.value, 'action_type')}
              inputProps={{ name: "action_type", id: "action_type"}}
            >
              <MenuItem classes={{ selected: classes.dSelected}} value="timer"> {t('offer_timer')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="promocode"> {t('offer_promocode')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="email"> {t('offer_email')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="phone"> {t('offer_phone')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="website"> {t('offer_website')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="barcode"> {t('offer_barcode')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="qrcode"> {t('offer_qr_code')} </MenuItem>
            </Select>
          </FormControl>
          {((this.state.action_value || this.state.action_type) && this.state.action_type !== 'timer') &&
          <CustomInput
            labelText={ <span> {activateOfferType.hasOwnProperty(this.state.action_type) ? t('offer_active_'+this.state.action_type) : 'Value'} </span> }
            id="action_value"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.action_value,
              onChange: event => this.change(event, "action_value"),
            }}
          />
          }
          {
            this.state.action_type === 'promocode' && <CustomInput
                labelText={ <span> {t('offer_link')} </span> }
                id="offer_link"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.offer_link,
                  onChange: event => this.change(event, "offer_link"),
                }}
              />
          }
        </GridItem>

        <GridItem xs={12} sm={12}> <hr style={{marginBottom: 0}}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}>{t('offer.select_days_and_time')}</GridItem>

        <GridItem xs={12} sm={11}>
          <GridContainer justifyContent="space-between" alignItems="stretch" >
            {renderActiveTime}
          </GridContainer>
        </GridItem>

      </GridContainer>
    );
  }
}



export default withTranslation('',{ withRef: true })(Step1);