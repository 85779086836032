import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  dashboard: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_DASHBOARD_REPORT_SUCCESS:
      let actionData = action.data;
      const nameOfMonth = new Date().toLocaleString('default', {month: 'long'}).toLowerCase();
      if(actionData.results.new_users_by_year && actionData.results.new_users_current_month) {
       const indexNewUserMonth = actionData.results.new_users_by_year.findIndex(item => item.month === nameOfMonth);
       if(indexNewUserMonth>=0) {
         actionData.results.new_users_by_year[indexNewUserMonth].total = actionData.results.new_users_current_month;
       }
      }
      if(actionData.results.activates_and_favourites_by_year && actionData.results.offer_activates_current_month) {
        const indexOfferActiveMonth = actionData.results.activates_and_favourites_by_year.findIndex(item => item.month === nameOfMonth);
        if(indexOfferActiveMonth>=0) {
          actionData.results.activates_and_favourites_by_year[indexOfferActiveMonth].activates = actionData.results.offer_activates_current_month;
        }
      }
      return {
        ...state,
        dashboard: actionData,
        loading: false
      };
    case actionTypes.GET_DASHBOARD_REPORT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_DASHBOARD_REPORT_FAIL:
      return  {
        ...state,
        loading: false
      };


    default:
      return state;
  }
}
