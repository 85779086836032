import * as actionTypes from '../actionTypes';

const initialState = {
  loadMore: false,
  loading: true,
  specialUsers: {},
  count: 0,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_SPECIAL_USERS_START:
      return  {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SPECIAL_USERS_MORE_START:
      return  {
        ...state,
        loadMore: true
      };
    case actionTypes.GET_SPECIAL_USERS_SUCCESS:
      return {
        ...state,
        specialUsers: action.loadMore ? {...state.specialUsers, results: [...state.specialUsers.results, ...action.data.results]} : action.data,
        loading: false,
        loadMore: action.loadMore && false
      };
    case actionTypes.GET_SPECIAL_USERS_FAIL:
      return  {
        ...state,
        loadMore: false
      };

    case actionTypes.SEARCH_SPECIAL_USERS_SUCCESS:
      return {
        ...state,
        specialUsers: action.data,
        loading: false,
      };


    case actionTypes.DELETE_SPECIAL_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_SPECIAL_USERS_SUCCESS:
      const id = action.data.results[0];
      const data = state.specialUsers.results.filter((item) => item.id !== id);
      state.specialUsers.results = data;
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_SPECIAL_USERS_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.UPDATE_SPECIAL_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_SPECIAL_USERS_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_SPECIAL_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.CREATE_SPECIAL_USERS_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_SPECIAL_USERS_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_SPECIAL_USERS_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };




    default:
      return state;
  }
}
