/*eslint-disable*/
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {NavLink, useNavigate} from "react-router-dom";
import cx from "classnames";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

// @mui/material components
import {Drawer, List, ListItem, ListItemText, Hidden, Collapse, Icon} from '@mui/material';
import {Edit} from '@mui/icons-material';

// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import avatar from "../../assets/img/default-avatar.png";
import logo_mini from "../../assets/img/logo_spara_transparent.png";
import {createUseStyles} from "react-jss";
var ps;

const useStyles = createUseStyles(sidebarStyle);

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const {className, user, headerLinks, links} = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}


// this creates the intial state of this component based on the collapse routes
// that it gets through this.props.routes
const getCollapseStates = (routes) => {
  let initialState = {};
  routes.map(prop => {
    if (prop.collapse) {
      initialState = {
        [prop.state]: getCollapseInitialState(prop.views),
        ...getCollapseStates(prop.views),
        ...initialState
      };
    }
    return null;
  });
  return initialState;
};

// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
const getCollapseInitialState = (routes) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
      return true;
    } else if (window.location.href.indexOf(routes[i].path) !== -1) {
      return true;
    }
  }
  return false;
};

const SideBar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {routes, bgColor, color, t} = props;
  const [openAvatar, setOpenAvatar] = useState(false);
  const [miniActive, setMiniActive] = useState(props.miniActive);
  const [collapseState, setCollapseState] = useState(getCollapseStates(props.routes));
  const mainPanel = React.createRef();

  useEffect(() => {
    setMiniActive(props.miniActive)
  },[props.miniActive]);

  if(!localStorage.getItem('user')) {
    localStorage.clear();
    window.location = '/login';
  }

  const store = JSON.parse(localStorage.getItem('user'));
  const userPermission = store.permission

  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  const openCollapse = (collapse) => {
    setOpenAvatar(!openAvatar);
  };

  const createLinks = routes => {

    const itemText = classes.itemText + " " +
      cx({
        [classes.itemTextMini]: props.miniActive && miniActive,
        [classes.itemTextMiniRTL]: props.rtlActive && props.miniActive && miniActive,
        [classes.itemTextRTL]: props.rtlActive
      });
    const collapseItemText = classes.collapseItemText + " " +
      cx({
        [classes.collapseItemTextMini]: props.miniActive && miniActive,
        [classes.collapseItemTextMiniRTL]: props.rtlActive && props.miniActive && miniActive,
        [classes.collapseItemTextRTL]: props.rtlActive
      });
    const itemIcon = classes.itemIcon + " " + cx({ [classes.itemIconRTL]: props.rtlActive });
    const caret = classes.caret + " " + cx({ [classes.caretRTL]: props.rtlActive });
    const collapseItemMini = classes.collapseItemMini + " " + cx({ [classes.collapseItemMiniRTL]: props.rtlActive });

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if(prop.permission && !prop.permission.some(r=> userPermission.includes(r))) {
        return null
      }
      if (prop.collapse) {
        var st = _.mapValues(collapseState, () => false);
        st['userPermission'] = userPermission;
        st[prop["state"]] = !collapseState[prop.state];

        const navLinkClasses = classes.itemLink + " " + cx({ [" " + classes.collapseActive]: getCollapseInitialState(prop.views) });
        return (
          <ListItem
            key={key}
            className={cx(
              {[classes.item]: prop.icon !== undefined},
              {[classes.collapseItem]: prop.icon === undefined}
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                setCollapseState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon}/>
                )
              ) : (
                <span className={collapseItemMini}>
                  {props.rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : props.t(prop.name)}
                secondary={
                  <b className={ caret + " " +  (collapseState[prop.state] ? classes.caretActive : "")} />
                }
                disableTypography={true}
                className={cx(
                  {[itemText]: prop.icon !== undefined},
                  {[collapseItemText]: prop.icon === undefined}
                )}
              />
            </NavLink>
            <Collapse in={collapseState[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses = classes.collapseItemLink + " " + cx({ [" " + classes[color]]: activeRoute(prop.path) });
      const navLinkClasses = classes.itemLink + " " + cx({ [" " + classes[color]]: activeRoute(prop.path) });

      if(prop.custom) {
        if(localStorage.getItem('user')) {
          let userStore = JSON.parse(localStorage.getItem('user'));
          let hasSpecialCompany = false;
          userStore.companies && userStore.companies.map(item => {
            if(item.torgid_special_company_id === 4) {
              hasSpecialCompany = true;
            }
          });
          if(hasSpecialCompany) {
            return (
              <ListItem
                key={key}
                className={cx(
                  {[classes.item]: prop.icon !== undefined},
                  {[classes.collapseItem]: prop.icon === undefined}
                )}
              >
                <NavLink
                  to={prop.path}
                  className={cx(
                    {[navLinkClasses]: prop.icon !== undefined},
                    {[innerNavLinkClasses]: prop.icon === undefined}
                  )}
                >
                  {prop.icon !== undefined ? (
                    typeof prop.icon === "string" ? (
                      <Icon className={itemIcon}>{prop.icon}</Icon>
                    ) : (
                      <prop.icon className={itemIcon}/>
                    )
                  ) : (
                    <span className={collapseItemMini}>
                {props.rtlActive ? prop.rtlMini : prop.mini}
              </span>
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : props.t(prop.name)}
                    disableTypography={true}
                    className={cx(
                      {[itemText]: prop.icon !== undefined},
                      {[collapseItemText]: prop.icon === undefined}
                    )}
                  />
                </NavLink>
              </ListItem>
            );
          }
        }
      }

      if (prop.custom) {
        return null;
      }

      return (
        <ListItem
          key={key}
          className={cx(
            {[classes.item]: prop.icon !== undefined},
            {[classes.collapseItem]: prop.icon === undefined}
          )}
        >
          <NavLink
            to={prop.path}
            className={cx(
              {[navLinkClasses]: prop.icon !== undefined},
              {[innerNavLinkClasses]: prop.icon === undefined}
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon}/>
              )
            ) : (
              <span className={collapseItemMini}>
                {props.rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={props.rtlActive ? prop.rtlName : props.t(prop.name)}
              disableTypography={true}
              className={cx(
                {[itemText]: prop.icon !== undefined},
                {[collapseItemText]: prop.icon === undefined}
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  const itemText = classes.itemText + " " +
    cx({
      [classes.itemTextMini]: props.miniActive && miniActive,
      [classes.itemTextMiniRTL]: props.rtlActive && props.miniActive && miniActive,
      [classes.itemTextRTL]: props.rtlActive
    });
  const collapseItemText = classes.collapseItemText + " " +
    cx({
      [classes.collapseItemTextMini]: props.miniActive && miniActive,
      [classes.collapseItemTextMiniRTL]: props.rtlActive && props.miniActive && miniActive,
      [classes.collapseItemTextRTL]: props.rtlActive
    });
  const userWrapperClass = classes.user + " " + cx({ [classes.whiteAfter]: bgColor === "white" });
  const caret = classes.caret + " " + cx({ [classes.caretRTL]: props.rtlActive });
  const collapseItemMini = classes.collapseItemMini + " " + cx({ [classes.collapseItemMiniRTL]: props.rtlActive });
  const photo = classes.photo + " " + cx({ [classes.photoRTL]: props.rtlActive });
  var user = null;
  var isCompanyUser = '';
  let userRole;
  let cLogo;
  let uRole;
  if(localStorage.getItem('user')) {
    let userParse = JSON.parse(localStorage.getItem('user'));
    userParse.user.isprofile = true;
    user = userParse.user;
    isCompanyUser = userParse && userParse.roles && (userParse.roles.includes('company_user') || userParse.roles.includes('group_admin'))
    userRole = userParse && userParse.roles && userParse.roles[0];
    cLogo = userParse && userRole && userRole === 'company_user' && userParse.companies.length === 1 && userParse.companies[0].logo ? userParse.companies[0].logo : logo_mini;
    uRole = userParse && userRole && userRole === 'company_user';
  }
  var userWrap = (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img src={user && user.torgid_user && user.torgid_user.profile_picture ? user.torgid_user.profile_picture : avatar} className={classes.avatarImg} alt="..."/>
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <div
            // to={"#"}
            style={{cursor: 'pointer'}}
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openAvatar")}
          >
            <ListItemText
              primary={user && user.torgid_user.name}
              secondary={
                <b
                  className={ caret + " " + classes.userCaret + " " +
                  (openAvatar ? classes.caretActive : "")
                  }
                />
              }
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </div>
          {openAvatar && <Collapse className={classes.dropdownWrapConteiner} in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              <ListItem className={classes.collapseItem}>
                <div
                  onClick={()=> navigate(isCompanyUser ? '/edit-profile' : '/add-company-user', { state: {"user": user} })}
                  className={ classes.itemLink + " " + classes.userCollapseLinks }>
                  <Edit style={{position: "relative", float: "left", width: "20px", height: "20px", marginRight: "20px"}} />
                  <ListItemText
                    primary={t('menu.edit_profile')}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </div>
              </ListItem>
            </List>
          </Collapse> }
        </ListItem>
      </List>
    </div>
  );
  var links = (
    <List className={classes.list}>{createLinks(routes)}</List>
  );

  const logoNormal =
    classes.logoNormal +
    " " +
    cx({
      [classes.logoNormalSidebarMini]: props.miniActive && miniActive,
      [classes.logoNormalSidebarMiniRTL]: props.rtlActive && props.miniActive && miniActive,
      [classes.logoNormalRTL]: props.rtlActive
    });
  const logoClasses = classes.logo + " " + cx({ [classes.whiteAfter]: bgColor === "white" });
  var brand = (
    <div className={logoClasses}>
      <Link to="/" className={logoNormal}>
        <img src={cLogo} alt="logo" className={miniActive ? classes.img_mini : classes.img_main }/>
      </Link>
    </div>
  );
  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.drawerPaperRTL]: props.rtlActive
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
      navigator.platform.indexOf("Win") > -1
    });


  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"]
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={userWrap}
            headerLinks={<AdminNavbarLinks rtlActive={props.rtlActive}/>}
            links={links}
          />
          <div
            className={classes.background}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          // onMouseOver={() => setMiniActive(false)}
          // onMouseOut={() => setMiniActive(true)}
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"]
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={userWrap}
            links={links}
          />
          <div
            className={classes.background}
          />
          <p style={{zIndex: 9999, textAlign: "center"}}> &copy; {1900 + new Date().getYear()}{" "}Spara </p>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withTranslation('',{ withRef: true })(SideBar);