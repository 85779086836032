import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  notificationData: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_NOTIFICATION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationData: action.data,
        loading: false
      };
    case actionTypes.GET_NOTIFICATION_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.UPDATE_NOTIFICATION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS:
      const id = action.data.results.id;
      const data = state.notificationData.results.filter((item) => item.id !== id);
      state.notificationData.results = data;
      state.notificationData.results.count = state.notificationData.results.count -1;
      return {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_NOTIFICATION_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    default:
      return state;
  }
}
