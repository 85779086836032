import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';
import { Permissions } from '../../../config'

// material-ui icons
import {createUseStyles} from "react-jss";
import {Checkbox} from "@mui/material";
import {AddAlert, Assignment, Search, Check} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";


import {
  getSpecialUsersByGroup,
  createSpecialUser,
  deleteSpecialUser,
  updateSpecialUser, searchSpecialUsersByCompany, deleteSpecialUserIds, getSpecialUsersByCompany
} from "../../../store/actions/specialUser";
import AddSpecialUser from "./AddEmployee";
import {LoaderSpinner} from "../../../components/Loader";


const styleAdd = {
  txtArea: {
    height: "84px",
    width: "100%",
    borderColor: "rgb(210, 210, 210)",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial",
    fontWeight: 400,
    lineHeight: 1.42857,
    resize: "none"
  },
  txtAreaError: {
    borderColor: "#e91e63",
  },
  txtAreaSuccess: {
    borderColor: "#6bbd6e",
  },
  txtSuccess: {
    color: "#6bbd6e",
    fontWeight: 'bold'
  },
  choiche: {
    position: 'relative',
    float: 'left',
    textAlign: "center",
    cursor: "pointer",
    // marginTop: "20px"
    width: '60px',
    margin: '5px 0'
  },
  iconCheckbox: {
    width: '50px',
    height: '50px'
  },
  logoImg: {
    width: "90%",
    height: "90%",
    opacity: '0.5',
    borderRadius: "50%"

  },
  companies: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  error: {
    textAlign: 'center',
    color: '#C42431',
    fontWeight: 'bold'
  },
  ...styles,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const style = {
  choiche: {
    position: 'relative',
    float: 'left',
    textAlign: "center",
    cursor: "pointer",
    width: '50px',
  },
  iconCheckbox: {
    width: '50px',
    height: '50px'
  },
  logoImg: {
    width: "90%",
    height: "90%",
    opacity: '0.5',
    borderRadius: "50%"
  },
  ...styles,
  ...stylesAlert,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const useStyles = createUseStyles(style);
const useStylesAdd = createUseStyles(styleAdd);

const Employees = ({ t }) => {
  const dispatch = useDispatch ();
  const classes = useStyles();
  const classesAdd = useStylesAdd();
  const [alert, setAlert] = React.useState(null);

  const store = JSON.parse(localStorage.getItem('user'));
  const userPermission = store.permission;
  const canDelete =  userPermission.includes(Permissions.SPECIAL_USER_DELETE);
  const companyIds = store.companyIds;
  const companies = store.companies;

  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(100);
  const [isActiveLoadMore, setIsActiveLoadMore] = React.useState(true);
  const [specialUsersList, setSpecialUsersList] = React.useState([]);

  const {specialUsers, loadMore, loading} = useSelector( state => state.specialUser );
  const {specialCompanies, loading:loadingSpecialCompanies } = useSelector( state => state.specialCompanies );
  const {status, message, type} = useSelector( state => state.specialUser.notification );
  const [specialUsersState, setSpecialUsersState] = React.useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    dispatch(getSpecialUsersByCompany(companyIds, page, false));
  }, [dispatch]);

  useEffect(() => {
    setSpecialUsersState(specialUsers.results)
  },[specialUsers.results]);

  if (loading) return (<LoaderSpinner/>);

  const loadMoreConnectedUsers = () => {
    dispatch(getSpecialUsersByCompany(companyIds, page+1, true));
    setPage(page+1);
  };

  const onSubmit = (data) => {
    let temp = data.users.replace(/^\s+|\s+$/g, '').split(/\s+/);
    data.users = temp.join(' ');
    data.groups = "1,4";
    dispatch(createSpecialUser({...data}));
  };

  const hideAlert = () => { setAlert(null) };

  const searchHandler = () => {
    if(search.length > 1) {
      setIsActiveLoadMore(false);
      let newSearch = search.replace(/%/g, '');
      dispatch(searchSpecialUsersByCompany(companyIds, newSearch));
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="The search text must contain at least 2 characters"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const htmlAlert = () => {
    setAlert(
      <AddSpecialUser classes={classesAdd} companies={companies} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const handleChange = (index, company_id) => {
    let newState = Object.assign([], specialUsersState);
    let companies = newState[index].torgid_special_companies;
    let newCompanies = typeof companies === 'string' ? companies.split(',').map(x=>+x) : companies;
    newCompanies.indexOf(company_id) < 0 ? newCompanies.push(company_id) : newCompanies.splice(newCompanies.indexOf(company_id), 1);
    newState[index].torgid_special_companies = newCompanies;
    setSpecialUsersState(newState)
  };

  const updateBtn = (index, id, companies) => {
    return <Button
      key={'save'+index}
      color="success"
      className={classes.actionButton}
      onClick={() => dispatch(updateSpecialUser(id, companies) )}
    > {t('save')} </Button>
  };

  const deleteBtn = (index, data) => {
    return <Button
      key={'delete'+index}
      color="danger"
      className={classes.actionButton}
      onClick={() => handleDeleteBtn(data)}
    > {t('delete')} </Button>
  };

  const handleDeleteBtn = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px", color: "#000" }}
        title={t('are_you_sure')}
        onConfirm={() => handleDeleteSuccess(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t('delete_yes')}
        cancelBtnText={t('cancel')}
        showCancel
      />
    );
  };

  const handleDeleteSuccess = (data) => {
    let specialIds = data.torgid_special_companies.split(',').map(x => +x);

    if(specialIds.length === 2 || (specialIds.length === 1 && specialIds[0] === 4)) {
      dispatch(deleteSpecialUser(data.id));
    } else {
      var index = specialIds.indexOf(4);
      if (index !== -1) {
        specialIds.splice(index, 1);
      }
      dispatch(updateSpecialUser(data.id, specialIds) )
    }
    hideAlert();
  };

  const handleCheckUsers = (id) => {
    if(!specialUsersList.includes(id)) {
      setSpecialUsersList(oldArray => [...oldArray, id]);
    } else {
      setSpecialUsersList(specialUsersList.filter(item => item !== id));
    }
  };

  const handleSelectAll = () => {
    let allIds = !specialUsersState ? [] : specialUsersState.map(val => val.id);
    setSpecialUsersList(allIds);
  };

  const changeFilter = (item) => {
    let ids = item.map(data => data.id);
    setFilter(ids);
    dispatch(getSpecialUsersByGroup(ids.length === 0 ? store.groupIds : ids, page, false));
  };

  const connectedUsersData = specialUsersState ? specialUsersState.map((value, index) => {
    let user_id = value.torgid_user_id;
    let name = value.torgid_user ? value.torgid_user.name : '';
    let email = value.torgid_user ? value.torgid_user.email : value.email;
    let phone = value.torgid_user ? value.torgid_user.phone : value.phone;
    let kennitala = value.torgid_user ? value.torgid_user.kennitala : value.kennitala;

    return ([
      index+1,
      <Checkbox
        tabIndex={-1}
        checked={specialUsersList.indexOf(value.id) >= 0}
        onClick={() => handleCheckUsers(value.id)}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked, root: classes.checkRoot}}
      />,
      user_id,
      name,
      email,
      phone,
      kennitala,
      value.torgid_company_id,
      <div className={classes.choiche} key={index+'spec'}>
        <Checkbox
          checked={true}
          tabIndex={-1}
          // onClick={() => handleChange(index, 4)}
          checkedIcon={<img key={index+'img1'} src="https://spara.s3.eu-west-2.amazonaws.com/group/logo/641676992585.png"  className={classes.logoImg}/>}
          icon={<img key={index+'img2'} src="https://spara.s3.eu-west-2.amazonaws.com/group/logo/641676992585.png"  className={classes.logoImg} />}
          classes={{checked: classes.iconCheckboxChecked, root: classes.iconCheckbox}}
        />
      </div>,
      [
        // canUpdate && updateBtn(index, value.id, value.torgid_special_companies),
        canDelete && deleteBtn(index, value)
        // canDelete && <DeleteButton key={value.id+'_delete'} action={deleteSpecialUser(user_id, email, phone, kennitala)} setLimit={()=>setPageLimit(pageLimit-1)} />
      ]
    ]);
  }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridContainer justifyContent="flex-end" container>
      <GridItem md={2}>
        { userPermission.includes(Permissions.SPECIAL_USER_CREATE) && <Button color="rose" style={{marginTop: '40px'}} onClick={htmlAlert} > {t('add_new_special_user')} </Button> }
      </GridItem>
    </GridContainer>

    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader >
                <CardIcon color="rose"><Assignment /></CardIcon>
                <span className={classes.tableTitle}>{t('menu.my_employees')} </span>
                {isActiveLoadMore && <span className={classes.tableCount} > {`Showing ${(page*pageLimit) < specialUsers.count ? (page*pageLimit) : specialUsers.count } of ${specialUsers.count} entries`} </span> }
              </CardHeader>
            </GridItem>
            <GridItem md={3}>
              <CustomInput
                inputProps={{
                  value: search,
                  onChange: event => setSearch(event.target.value),
                  onKeyPress: e => (e.key === 'Enter' && searchHandler()),
                  placeholder: t('search'),
                  inputProps: { "aria-label": "Search",  className: classes.searchInput }
                }}
              />
              <Button color="rose" aria-label="edit" justIcon size="sm" onClick={() => searchHandler()} >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
              </Button>
            </GridItem>
            <GridItem style={{marginTop: '10px'}}>
              <Button color="success" size="sm" aria-label="edit" disabled={!specialUsersState} onClick={() => handleSelectAll()}> Select all </Button>
              <DeleteButton disabledbtn={specialUsersList.length === 0} action={deleteSpecialUserIds(specialUsersList)} setLimit={()=>{}} msg="Delete"/>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            hover
            tableHead={["#", 'Select', t('user_id'), t('name'), t('email'), t('phone'), t('kennitala'), t('company'), t('special_companies'), t('actions') ]}
            tableData={ connectedUsersData }
            customCellClasses={[classes.center, classes.center, classes.center]}
            customHeadCellClasses={[classes.center, classes.center, classes.center]}
          />
        </CardBody>
        {isActiveLoadMore &&
        <Button color="rose" disabled={specialUsersState && specialUsersState.length < (page*pageLimit)} fullWidth onClick={() => loadMoreConnectedUsers()}>
          {t('load_more')}  &nbsp; {loadMore && <LoaderSpinner/>}
        </Button>
        }
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Employees);