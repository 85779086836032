import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";


export const getSpecialUsers = (page, loadMore, filter = null) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_SPECIAL_USERS_MORE_START : actionTypes.GET_SPECIAL_USERS_START});
  try {
    const res = await axios.get(`/api/special_user?page=${page}${filter ? '&group_ids='+filter : ''}`);
    dispatch({type:  actionTypes.GET_SPECIAL_USERS_SUCCESS, data: res.data, loadMore: loadMore});
  } catch (error) {
    dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
  }
};

export const getSpecialUsersByGroup = (groupIds, page, loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_SPECIAL_USERS_MORE_START : actionTypes.GET_SPECIAL_USERS_START});
  try {
    const res = await axios.get('/api/special_user/by_group?group_ids='+groupIds+'&page='+page);
    dispatch({type: actionTypes.GET_SPECIAL_USERS_SUCCESS, data: res.data, loadMore: loadMore});
  } catch (error) {
    dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
  }
};

export const searchSpecialUsers = (search) => async dispatch => {
  dispatch({type: actionTypes.GET_SPECIAL_USERS_START});
  try {
    const res = await axios.get('/api/special_user/search/'+search);
    dispatch({type: actionTypes.SEARCH_SPECIAL_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
  }
};

export const getSpecialUsersByCompany = (companyIds, page, loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_SPECIAL_USERS_MORE_START : actionTypes.GET_SPECIAL_USERS_START});
  try {
      const res = await axios.get('/api/special_user/by_company_id?company_ids='+companyIds+'&page='+page);
      dispatch({type: actionTypes.GET_SPECIAL_USERS_SUCCESS, data: res.data, loadMore: loadMore});
    } catch (error) {
      dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
    }
};

export const searchSpecialUsersByGroup = (groupdId, search) => async dispatch => {
  dispatch({type: actionTypes.GET_SPECIAL_USERS_START});
  try {
    const res = await axios.get(`/api/special_user/search_by_group?group_ids=${groupdId}&search=${search}`);
    dispatch({type: actionTypes.SEARCH_SPECIAL_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
  }
};

export const searchSpecialUsersByCompany = (companyIds, search) => async dispatch => {
  dispatch({type: actionTypes.GET_SPECIAL_USERS_START});
  try {
      const res = await axios.get(`/api/special_user/search_by_company_id?company_ids=${companyIds}&search=${search}`);
      dispatch({type: actionTypes.SEARCH_SPECIAL_USERS_SUCCESS, data: res.data});
    } catch (error) {
      dispatch({type: actionTypes.GET_SPECIAL_USERS_FAIL});
    }
};

export const createSpecialUser = ({...data}) => async dispatch => {
  dispatch({type:  actionTypes.CREATE_SPECIAL_USERS_START});
  try {
    const res = await axios({
      url: `/api/special_user/`,
      method: 'POST',
      data: {
        ...data
      }
    });
    dispatch({type: actionTypes.CREATE_SPECIAL_USERS_SUCCESS, data: res.data});
    window.location.reload();
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.CREATE_SPECIAL_USERS_FAIL, data: errorMessage});
  }
};

export const updateSpecialUser = (id, companies) => async dispatch => {
  dispatch({type:  actionTypes.UPDATE_SPECIAL_USERS_START});
  try {
    const res = await axios({
      url: `/api/special_user/update`,
      method: 'POST',
      data: {
        id: id,
        user_companies: companies.join(','),
      }
    });
    dispatch({type: actionTypes.UPDATE_SPECIAL_USERS_SUCCESS, data: res.data});
    window.location.reload();
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_SPECIAL_USERS_FAIL, data: errorMessage});
  }
};


export const deleteSpecialUser = (id) => async dispatch => {
  dispatch({type: actionTypes.DELETE_SPECIAL_USERS_START});
  try {
    const res = await axios.delete('/api/special_user/' + id);
    dispatch({type: actionTypes.DELETE_SPECIAL_USERS_SUCCESS, data: res.data});
    window.location.reload();
  } catch (error) {
    dispatch({type: actionTypes.DELETE_SPECIAL_USERS_FAIL});
  }
};


export const deleteSpecialUserIds = (ids, groupIds) => async dispatch => {
  dispatch({type: actionTypes.DELETE_SPECIAL_IDS_START});
  try {
    const res = await axios({
      url: `/api/special_user/delete_by_ids`,
      method: 'POST',
      data: {
        ids: ids,
        group_ids: groupIds
      }
    });
    window.location.reload();
    dispatch({type: actionTypes.DELETE_SPECIAL_IDS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_SPECIAL_IDS_FAIL});
  }
};