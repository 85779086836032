import React, {useRef} from 'react';
import { Permissions } from '../../config'

// material-ui
import {createUseStyles} from "react-jss";
import {Sort, ArrowDropUp, ArrowDropDown} from "@mui/icons-material";
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table'
import { useTranslation } from 'react-i18next';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import exStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles from "../../assets/jss/material-dashboard-pro-react/components/tableStyle";


function DefaultColumnFilter ({column: { Header, filterValue, setFilter}}) {
  return (
    <CustomInput
      formStyle={{marginTop: '0px', paddingTop: '0'}}
      inputProps={{
        value: filterValue || '',
        onChange: e => setFilter(e.target.value || undefined),
        placeholder: Header
      }}
    />
  );
};


const style = {
  tableTitle: {
    position: 'absolute',
    top: '10px',
    color: '#09b1c5',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  pagination_row:{
    margin: '10px auto',
    textAlign: 'center',
    paddingTop: '20px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  tableBodyRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
  },
  tableResponsive: {
    // width: "100%",
    minHeight: "0.1%",
    overflowX: "auto"
  },
  ...exStyles,
  ...styles
};

const useStyles = createUseStyles(style);


const CompanyUsersTable = (props) => {
  const classes = useStyles();
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const COMPANY_USER_UPDATE = userPermission.includes(Permissions.COMPANY_USER_UPDATE);
  const COMPANY_USER_DELETE = userPermission.includes(Permissions.COMPANY_USER_DELETE);
  const data = props.data;
  const { t } = useTranslation();
  const cardRef = useRef();

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        hideSorting: true,
        disableFilters: true,
        Cell: ({ cell }) => {
          return parseInt(cell.row.id)+1
        },
      },
      {
        Header: 'User ID',
        accessor: (values) => {
          let value = values.children[0];
          return value.torgid_user_id
        }
      },
      {
        Header: t('picture'),
        disableFilters: true,
        hideSorting: true,
        accessor: (values) => {
          let value = values.children[0];
          return value.torgid_user && value.torgid_user.profile_picture ? <img key={value.id+"img"} title={''} src={value.torgid_user.profile_picture} style={{width: '25px', height: 'auto'}}/> : ''
        },
      },
      {
        Header: t('name'),
        accessor: (values) => {
          let value = values.children[0];
          return value.torgid_user ? value.torgid_user.name : ''
        }
      },
      {
        Header: t('email'),
        accessor: (values) => {
          return values.children[0].torgid_user ? values.children[0].torgid_user.email : ''
        }
      },
      {
        Header: t('phone'),
        accessor: (values) => {
          return values.children[0].torgid_user ? values.children[0].torgid_user.phone : ''
        }
      },
      {
        Header: t('position'),
        accessor: (values) => {
          return values.children[0].torgid_user ? values.children[0].torgid_user.position : null
        }
      },
      {
        Header: t('roles'),
        accessor: (values) => {
          let roles = '';
          roles = values.children[0].torgid_user?.roles.map((rank, i, row) => {
            return rank.name+' '
          });
          return roles
        }
      },
      {
        Header: t('company'),
        accessor: (values) => {
          let company = '';
          if(values.children.length === 1) {
            company = values.children[0].torgid_company?.name
          } else {
            company = values.children.filter(item => item.torgid_company_id !== 1305).map((rank, i, row) => {
              if (i + 1 === row.length) {
                return rank.torgid_company?.name
              } else {
                return rank.torgid_company?.name+', '
              }
            });
          }
          return company;
        }
      },

      {
        Header: t('actions'),
        disableFilters: true,
        Cell: ({ cell }) => {
          return (
            <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:4}}>
              {COMPANY_USER_UPDATE && props.updateButton(cell.row.original.children)}
              {COMPANY_USER_DELETE && props.deleteButton(cell.row.original.children[0])}
            </div>
          )
        },
      },
    ],
    []
  );


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 100 },
      autoResetExpanded: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <ArrowDropDown/> : <ArrowDropUp/>) : <Sort style={{fontSize: '14px'}} />;
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangePage = (page) => {
    gotoPage(page);
    cardRef.current.scrollIntoView();
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };


  // Render the UI for your table
  return (
    <React.Fragment>
      <div className={classes.tableResponsive}>
        <div ref={cardRef}/>
        <Table className={classes.table}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow className={classes.tableRow + " " + classes.tableRowHead} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell variant='head'
                    className={classes.tableHeadCell}
                   {...column.getHeaderProps({style: { minWidth: column.minWidth },})}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {!column.hideSorting && generateSortingIndicator(column)}
                    </div>
                    {column.canFilter && column.render("Filter")}
                    {/*<Filter column={column} />*/}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow key={key} hover={true} className={classes.tableBodyRow}>
                    {row.cells.map(cell =>
                      <TableCell className={classes.tableCell} key={key} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    )}
                  </TableRow>
               </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </div>

      <GridContainer className={classes.pagination_row} justifyContent="space-between" container>
        <GridItem xs={3}>
          <Button color="success" onClick={() => onChangePage(0)} disabled={!canPreviousPage}>{"<<"}</Button> {' '}
          <Button color="success" onClick={() => onChangePage(pageIndex-1)} disabled={!canPreviousPage}>{"<"}</Button>
        </GridItem>
        <GridItem md={2} style={{ marginTop: 15 }}>
          Page{" "} <strong> {pageIndex + 1} of {pageOptions.length}</strong>
        </GridItem>
        <GridItem md={1}>
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            formStyle={{marginTop: '0px'}}
            inputStyle={{marginTop: '0px'}}
            inputProps={{
              type: 'number',
              min: 1,
              max: pageOptions.length,
              defaultValue: pageIndex + 1,
              // value: filterValue || "",
              onChange: onChangeInInput,
              placeholder: `Page`
            }}
          />
        </GridItem>
        <GridItem md={2} style={{ marginTop: 15 }}>
          <select value={pageSize} onChange={onChangeInSelect} className="select_filter">
            {[25, 50, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> Show {pageSize} </option>))}
          </select>
        </GridItem>
        <GridItem md={3}>
          <Button color="success" onClick={() => onChangePage(pageIndex + 1)} disabled={!canNextPage}>{">"}</Button> {' '}
          <Button color="success" onClick={() => onChangePage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
        </GridItem>
      </GridContainer>

    </React.Fragment>
  )

};

export default CompanyUsersTable;