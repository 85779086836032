import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const changeNotificationStatus  = () => async dispatch => {
  dispatch({type: actionTypes.NOTIFICATION_STATUS_FALSE});
};

export const getUsers = (page, loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_MORE_USERS_START : actionTypes.GET_USERS_START});
  try {
    const res = await axios.get('/api/user?page='+page);
    dispatch({type: loadMore ? actionTypes.GET_MORE_USERS_SUCCESS : actionTypes.GET_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: loadMore ? actionTypes.GET_MORE_USERS_FAIL : actionTypes.GET_USERS_FAIL});
  }
};

export const searchUser = (search) => async dispatch => {
  try {
    const res = await axios.get('/api/user/search/'+search);
    dispatch({type: actionTypes.GET_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USERS_FAIL});
  }
};

export const getUserProfile = ({...user}) => async dispatch => {
  dispatch({type: actionTypes.GET_USER_PROFILE_START});
  try {
    const res = await axios.get('/api/user/report/' + user.id);
    dispatch({type: actionTypes.GET_USER_PROFILE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USER_PROFILE_FAIL});
  }
};

export const createUpdateUser = ({update, ...user}, history) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_USERS_START : actionTypes.CREATE_USERS_START});
  try {
    const res = await axios({
      url: `${update ? '/api/user'+`/${user.about.id}` : '/api/user'}`,
      method: update ? 'PUT' : 'POST',
      data: {...user.about}
    });

    // dispatch(createUpdateUserNotification({update:true, ...res.data.results}, {...user.notification}));
    dispatch({type: update ? actionTypes.UPDATE_USERS_SUCCESS : actionTypes.CREATE_USERS_SUCCESS, data: res.data});

    history(-1);
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_USERS_FAIL : actionTypes.CREATE_USERS_FAIL, data: errorMessage});
  }
};

export const createUpdateUserNotification = ({update, ...user}, notification) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_USERS_NOTIFICATION_START : actionTypes.CREATE_USERS_NOTIFICATION_START});

  try {
    const res = await axios({
      url: `${update ? '/api/user_notification'+`/${user.id}` : ''}`,
      method: update ? 'PUT' : 'POST',
      data: {...notification}
    });

    dispatch({type: update ? actionTypes.UPDATE_USERS_NOTIFICATION_SUCCESS : actionTypes.CREATE_USERS_NOTIFICATION_SUCCESS, data: res.data});

  } catch (error) {
    dispatch({type: update ? actionTypes.UPDATE_USERS_NOTIFICATION_FAIL : actionTypes.CREATE_USERS_NOTIFICATION_FAIL});
  }
};

export const deleteUser = ({...user}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_USERS_START});
  try {
    const res = await axios.delete('/api/user/' + user.id);
    dispatch({type: actionTypes.DELETE_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_USERS_FAIL});
  }
};

export const getUserByEmail = (email) => async dispatch => {
  dispatch({type: actionTypes.GET_USER_BY_EMAIL_START});
  try {
    const res = await axios.get('/api/user/by_email/'+email);
    dispatch({type: actionTypes.GET_USER_BY_EMAIL_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USER_BY_EMAIL_FAIL});
  }
};

