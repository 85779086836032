import React from 'react';
import { Link } from 'react-router-dom';
import {createUseStyles} from "react-jss";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import styles from "../assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
const useStyles = createUseStyles(styles);

export default function NotFound() {
	const classes = useStyles();
	let path = window.location.pathname;
	return (
		<div className={classes.contentCenter}>
			<GridContainer>
				<GridItem md={12}>
					<h1 className={classes.title}>404</h1>
					{path === '/managers' || path === '/attached-group' ?
						<h2>Þessar stillingar eru læstar fyrir Lite hópum. Hafðu samband við spara@spara.is fyrir frekari upplýsingar</h2>
					:
						<React.Fragment>
							<h2 className={classes.subTitle}>Page not found :(</h2>
							<h4 className={classes.description}>Ooooups! Looks like you got lost.</h4>
						</React.Fragment>
					}
					<Link to="/" className="link">Back to home</Link>
				</GridItem>
			</GridContainer>
		</div>
	);
}