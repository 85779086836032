import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';

// @material-ui
import {TextareaAutosize, FormControl, InputLabel, Select, MenuItem} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FileUpload from "components/CustomUpload/FileUpload.js";

class addSpecialUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users:  "",
      usersState: "",
      company_id: props.companies && props.companies.length === 1 ? [props.companies[0].id] : [],
      companiesState: props.companies && props.companies.length === 1 ? "success" : "",
      msg: ""
    };
  }

  isValidated() {
    if (
      this.state.msg === 'Success' &&
      this.state.companiesState === "success"
    ) {
      return true;
    } else {
      if (this.state.msg !== 'Success') {
        this.setState({ msg: "Please fill input" });
      }
      if (this.state.companiesState !== "success") {
        this.setState({ companiesState: "error" });
      }
    }
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state);
      this.props.onCancel();
    }
  };

  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };
  verifyPhone = (value) => {
    var phoneRex =  /^\d{7}$/;
    return phoneRex.test(value);
  };
  verifyKennitala = (value) => {
    var kennitalaRex =  /^\d{10}$/;
    return kennitalaRex.test(value);
  };

  change(data) {
    this.setState({ users: data });
    let result = data.replace(/^\s+|\s+$/g, '').split(/\s+/);
    let temp = [];
    for(var i = 0; i < result.length; i++) {
      if(this.verifyEmail(result[i]) || this.verifyPhone(result[i]) || this.verifyKennitala(result[i])) {
        temp.push('ok');
      }
      else {
        temp.push('error');
      }
    }
    if(temp.includes('error')) {
      this.setState({ msg: "Validation Error in "+result[temp.indexOf('error')] });
    } else {
      this.setState({ msg: "Success" });
    }

  }

  handleChange = (company_id) => {
    this.setState({ company_id: company_id });
    this.setState({ companiesState: "success" });
  };

  render() {
    const {classes, companies, t} = this.props;
    return (
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={ t('add_new_special_user') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer justifyContent="space-between" container>
          <GridItem xs={12} sm={12} className={this.state.msg === 'Success' ? classes.txtSuccess : classes.error}>
            {this.state.msg}
          </GridItem>
          <GridItem xs={12} sm={12}>
            <TextareaAutosize
              className={classes.txtArea}
              minRows={8}
              maxRows={8}
              id="description"
              aria-label="description"
              placeholder={t('add_employee_example')}
              value={this.state.users}
              onChange={event => this.change(event.target.value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12}>
            <FileUpload onUpload={(data) => this.change(data)} />
          </GridItem>

          <React.Fragment>
            <GridItem xs={12} sm={12} className={this.state.companiesState === "error" ? classes.error : classes.txtSuccess}>
              { this.state.companiesState === "error" && 'Error: Please choose company' }
            </GridItem>
            <GridItem xs={12} sm={12} className={classes.companies}>
              {companies && companies.length > 1 &&
              <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('companies')} </InputLabel>
                <Select
                  MenuProps={{className: classes.selectMenu, style: {zIndex: 35001}}}
                  classes={{select: classes.select}}
                  value={this.state.company_id}
                  onChange={e => this.handleChange(e.target.value)}
                  inputProps={{name: "categories", id: "categories-select"}}
                >
                  {companies && companies.map((value, index) => {
                    return <MenuItem
                      classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                      value={value.id}
                      key={value.id}
                    >
                      {value.name}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
              }
            </GridItem>
          </React.Fragment>

        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addSpecialUser);