import React, {useState} from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { SketchPicker } from 'react-color';

import {createUseStyles} from "react-jss";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const useStyles = createUseStyles(stylesAlert);

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.defaultColor)
  const classes = useStyles();

  return <SweetAlert
    showCancel
    style={{ display: "block", marginTop: "-100px", width: '260px' }}
    onCancel={() => props.onCancel()}
    onConfirm={e => {props.confirm(color)}}
    confirmBtnCssClass={classes.button + " " + classes.success}
    cancelBtnCssClass={classes.button + " " + classes.danger}
    closeOnClickOutside={false}
  >
    <SketchPicker color={color} onChange={(e) => setColor(e.hex) } />
  </SweetAlert>
};

export default ColorPicker;