import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';
import i18n from '../../../locales/i18n';

// material-ui
import {createUseStyles} from "react-jss";
import {Web, Email, Phone, Language} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import offerPreviewStyle from "assets/jss/material-dashboard-pro-react/offerPreviewStyle";

import {getSpecialOffer} from "../../../store/actions/offer";
import {LoaderSpinner} from "../../../components/Loader";

const style = {
  ...offerPreviewStyle,
};

const useStyles = createUseStyles(style);

const Index = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch ();
  const companyUser = JSON.parse(localStorage.getItem('user')).user.torgid_user;
  const {specialOffersByCompany, loading} = useSelector( state => state.offer );
  const tagType = { "kynning": '#0eae00', "2 fyrir 1": '#0089cf', "discount": '#faa214', "sérkjör": '#cf0000'};
  const lang = i18n.language.toLowerCase();
  const currentLang = lang.includes("is") ? 'is' :  'en';

  const [active_days] = React.useState([
    { "active_day": 0, "day": "M", "start_time": "", "end_time": "", active: false },
    { "active_day": 1, "day": "Þ", "start_time": "", "end_time": "", active: false },
    { "active_day": 2, "day": "M", "start_time": "", "end_time": "", active: false },
    { "active_day": 3, "day": "F", "start_time": "", "end_time": "", active: false },
    { "active_day": 4, "day": "F", "start_time": "", "end_time": "", active: false },
    { "active_day": 5, "day": "L", "start_time": "", "end_time": "", active: false },
    { "active_day": 6, "day": "S", "start_time": "", "end_time": "", active: false },
  ]);

  useEffect(() => {
      dispatch(getSpecialOffer(companyUser.id, 3));
    }, [dispatch]);

  const calculateOfferStatus = (activeTimes) => {
    var now = new Date().getDay()-1;
    var active_day = activeTimes.find(obj => { return obj.active_day === now });
    var d = new Date();
    var nowHours = d.getHours();
    var nowMinutes = d.getMinutes();

    if(active_day) {
      let startTime = active_day.start_time.split(":");
      let endTime = active_day.end_time.split(":");
      if (startTime[0] === "00" && endTime[0] === "23") {
        return <div> <span className="status active" /> <span style={{color: 'green'}}>●</span> {t('offer_is_available_all_day')} </div>
      }
      if (endTime[0] <= nowHours && endTime[1] <= nowMinutes) {
        return <div> <span className="status active" /> <span style={{color: 'red'}}>●</span> {`${t('offer_was_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`} </div>
      }
      if (startTime[0] >= nowHours) {
        return <div> <span className="status active" /> <span style={{color: 'red'}}>●</span> {`${t('offer_will_be_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`} </div>
      }
      return <div> <span className="status active" /> <span style={{color: 'green'}}>●</span> {`${t('offer_is_available')} ${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]} ${t('offer_today')}`}</div>
    }
    return <div> <span className="status inactive" /> <span style={{color: 'red'}}>●</span> {t('offer_not_available_today')} </div>
  };


  if (loading) return (<LoaderSpinner/>);

    return <GridContainer container direction="row" alignItems="flex-start">
      {
        specialOffersByCompany && specialOffersByCompany.map((value, index) => {
          let phone = '';
          let email = '';
          let website = '';
          value.torgid_company && value.torgid_company.torgid_company_contacts && value.torgid_company.torgid_company_contacts.map(val => {
              if(val.type === 'website') website = val.value;
              if(val.type === 'phone') phone = val.value;
              if(val.type === 'email') email = val.value;
            });
          return <GridItem sm={6} md={4} lg={4} xl={3} className={classes.boxMain2} key={index+'boxMain'}>
            <div className={classes.box} key={index+'box'}>

              <div style={{backgroundImage: `url(${value.image})`}} className={classes.boxImg2}> </div>

              <div className={classes.discount2} style={{backgroundColor: tagType[value.discount] ? tagType[value.discount] : tagType['discount']}}>
                <span className={classes.discountLeft} /> <span className={classes.discountTxt}> {value.discount}</span> <span className={classes.discountRight} />
              </div>

              <div className={classes.box_body2}>
                <div className={classes.box_title}> {currentLang === 'en' && value.title_en ? value.title_en : value.title} </div>
                <div className={classes.box_desc2}> {currentLang === 'en' && value.description_en ? value.description_en : value.description} </div>

                {
                  value.torgid_offer_active_times && <div>
                    <div className={classes.mStatus2}> {calculateOfferStatus(value.torgid_offer_active_times )} </div>
                    <GridContainer justifyContent="space-between" className={classes.activeDays}>
                      {active_days.map((activeValue, activeIndex) => {
                        var result = value.torgid_offer_active_times.find(obj => { return obj.active_day === activeValue.active_day });
                        return result ? <Button key={activeIndex+'active'} color="success" size="sm" style={{padding: '12px 12px', fontSize: '16px'}}> {activeValue.day}  </Button>
                          : <Button key={activeIndex+'noactive'} color="default" size="sm" style={{padding: '12px 12px', fontSize: '16px'}}> {activeValue.day}  </Button>
                      })}
                    </GridContainer>
                  </div>
                }

                <div className={classes.box_company_name2}> {value.torgid_company.name} </div>
                <div className={classes.box_desc_short}> {currentLang === 'en' && value.torgid_company.description_en ? value.torgid_company.description_en : value.torgid_company.description} </div>


                <GridContainer justifyContent="space-between" className={classes.contacts}>
                  {website && <div className="site"> <a href={website} target="_blank" title={website}> <Web className={classes.icon2}/>  </a> </div>}
                  {phone && <div className="phone"> <a href={"tel:" +phone} target="_blank" title={phone}> <Phone className={classes.icon2}/> </a> </div>}
                  {email && <div className="email"> <a href={"mailto:" + email} target="_blank" title={email}> <Email className={classes.icon2}/> </a> </div>}
                </GridContainer>

              </div>

            </div>
              </GridItem>
          })
      }

      </GridContainer>
  };

export default withTranslation('',{ withRef: true })(Index);