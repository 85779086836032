import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const changeNotificationStatus  = () => async dispatch => {
  dispatch({type: actionTypes.NOTIFICATION_STATUS_FALSE});
};

export const getOffers = (loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_MORE_OFFER_START : actionTypes.GET_OFFER_START});
  try {
    const res = await axios.get('/api/offer');
    dispatch({type: loadMore ? actionTypes.GET_MORE_OFFER_SUCCESS : actionTypes.GET_OFFER_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: loadMore ? actionTypes.GET_MORE_OFFER_FAIL : actionTypes.GET_OFFER_FAIL});
  }
};

export const getOfferById = (offerId) => async dispatch => {
  dispatch({type: actionTypes.GET_OFFER_BY_ID_START});
  try {
    const res = await axios.get(`/api/offer/${offerId}`);
    dispatch({type: actionTypes.GET_OFFER_BY_ID_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_OFFER_BY_ID_FAIL});
  }
};

export const searchOffers = (search) => async dispatch => {
  dispatch({type: actionTypes.GET_OFFER_START});
  try {
    const res = await axios.get('/api/offer/search/'+search);
    dispatch({type: actionTypes.GET_OFFER_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_OFFER_FAIL});
  }
};

export const getOfferByCompanyID = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_START});
  try {
    const res = await axios.get(`/api/offer/offers_by_company_user/${companyId}`);
    dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_FAIL});
  }
};

export const getOfferByCompaniesAndGroups = (companyIds, groupIds) => async dispatch => {
  dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_START});
  try {
    let res;
    if(!companyIds) {
      res = await axios.get(`/api/offer/offers_by_cu_and_group?group_ids=${groupIds}`);
    } else {
      res = await axios.get(`/api/offer/offers_by_cu_and_group?company_ids=${companyIds}&group_ids=${groupIds}`);
    }
    dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_OFFER_BY_COMPANY_FAIL});
  }
};

export const getSpecialOffer = (userId, specialCompanyId) => async dispatch => {
  dispatch({type: actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_START});
  try {
    const res = await axios.get(`/api/offer/by_special_company/${specialCompanyId}`);
    dispatch({type: actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_FAIL});
  }
};

export const getOfferReport = (offerId) => async dispatch => {
  dispatch({type: actionTypes.GET_OFFER_REPORT_START});
  try {
    const res = await axios.get(`/api/offer/report/${offerId}`);
    dispatch({type: actionTypes.GET_OFFER_REPORT_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_OFFER_REPORT_FAIL});
  }
};

export const getTopOffers = () => async dispatch => {
  dispatch({type: actionTypes.GET_TOP_OFFER_START});
  try {
    const res = await axios.get('/api/top_offers?filter=admin');
    dispatch({type: actionTypes.GET_TOP_OFFER_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_TOP_OFFER_FAIL});
  }
};

export const deleteOffer = ({...offer}, deleteFrom ='offers') => async dispatch => {
  dispatch({type: actionTypes.DELETE_OFFER_START});
  try {
    const res = await axios.delete('/api/offer/' + offer.id);
    dispatch({type: actionTypes.DELETE_OFFER_SUCCESS, data: res.data, deleteFrom: deleteFrom});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_OFFER_FAIL});
  }
};

export const createUpdateOffer = ({update, ...offer}, url) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_OFFER_START : actionTypes.CREATE_OFFER_START});
  try {
    const res = await axios({
      url: `${update ? '/api/offer'+`/${offer.about.id}` : '/api/offer'}`,
      method: update ? 'PUT' : 'POST',
      data: {
        "torgid_company_id": offer.about.companyId,
        "torgid_offer_categories": offer.category.torgid_category_id,
        ...offer.about
      }
    });
    dispatch({type: update ? actionTypes.UPDATE_OFFER_SUCCESS : actionTypes.CREATE_OFFER_SUCCESS, data: res.data});

    window.location.href = url;
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_OFFER_FAIL : actionTypes.CREATE_OFFER_FAIL, data: errorMessage});
  }
};


export const changeOfferStatus = (offer, type) => async dispatch => {
  dispatch({type: type === "cu" ? actionTypes.CHANGE_OFFER_STATUS_CU_START : actionTypes.CHANGE_OFFER_STATUS_START});
  try {
    const res = await axios.get(`/api/offer/activate/${offer.id}/${(!offer.is_active)}`);
    dispatch({type: type === "cu" ? actionTypes.CHANGE_OFFER_STATUS_CU_SUCCESS : actionTypes.CHANGE_OFFER_STATUS_SUCCESS, data: res.data});
  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: type === "cu" ? actionTypes.CHANGE_OFFER_STATUS_CU_FAIL : actionTypes.CHANGE_OFFER_STATUS_FAIL, data: errorMessage});
  }
};

export const changeOfferStatusArchive = (offer) => async dispatch => {
  dispatch({type: actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_START});
  try {
    const res = await axios.get(`/api/offer/archive/${offer.id}`);
    dispatch({type: actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_SUCCESS, data: res.data});
  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_FAIL, data: errorMessage});
  }
};
