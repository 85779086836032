import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';

// @material-ui
import {TextareaAutosize} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

class preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log_name: props.data.log_name || "",
      description: props.data.description || "",
      subject_type: props.data.subject_type || "",
      subject_id: props.data.subject_id || "",
      properties: JSON.stringify(props.data.properties) || "",
      old_value: props.data.properties && props.data.properties.old_value ? JSON.stringify(props.data.properties.old_value) : "",
      new_value: props.data.properties && props.data.properties.new_value ? JSON.stringify(props.data.properties.new_value) : "",
    };
  }

  render() {
    const {classes, t} = this.props;

    return (
      <SweetAlert
        showCancel
        showConfirm={false}
        style={{ display: "block", marginTop: "-100px" }}
        title='Log Details'
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        closeOnClickOutside={false}
      >
        <GridContainer  justifyContent="space-between" container>
          <GridItem xs={12} sm={12}>
            <div className={classes.txt}> <b> Log name:</b> {this.state.log_name} </div>
            <div className={classes.txt}> <b> Description:</b> {this.state.description} </div>
            <div className={classes.txt}> <b> Subject type:</b> {this.state.subject_type} </div>
            <div className={classes.txt}> <b> Subject Id:</b> {this.state.subject_id} </div>
            <br/>
            <TextareaAutosize
              className={classes.txtArea}
              minRows={8}
              maxRows={8}
              id="old_value"
              aria-label="old_value"
              placeholder='Old value'
              value={this.state.old_value ? this.state.old_value.split(',').join('\n'): this.state.properties}
            />
            {this.state.new_value &&
              <TextareaAutosize
                className={classes.txtArea}
                minRows={8}
                maxRows={8}
                id="new_value"
                aria-label="new_value"
                placeholder='New value'
                value={this.state.new_value.split(',').join('\n')}
              />
            }
          </GridItem>

        </GridContainer>
      </SweetAlert>
    );
  }
}

preview.propTypes = {
  classes: PropTypes.object
};
export default withTranslation('',{ withRef: true })(preview);