import React, {useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import { Permissions } from '../../../config'

// material-ui
import {Assignment, Edit, AddAlert, Visibility, VisibilityOff} from "@mui/icons-material";
import { createUseStyles } from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getSpecialCompanyByUserId, createUpdateSpecialCompany} from "../../../store/actions/specialCompany";
import {LoaderSpinner} from "../../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert,
  groupType: {
    textTransform: "capitalize"
  },
};

const useStyles = createUseStyles(style);

const Group = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {specialCompanies, loading, specialCompaniesById } = useSelector( state => state.specialCompanies );
  const {status, message, type} = useSelector( state => state.user.notification );
  const [alert, setAlert] = React.useState(null);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const canCreate = userPermission.includes(Permissions.GROUP_CREATE);
  const canUpdate = userPermission.includes(Permissions.GROUP_UPDATE);
  const canDelete = userPermission.includes(Permissions.GROUP_DELETE);
  // const isUpdating = !!history.location.state?.special_company_data;
  // const dataByState = history.location.state?.special_company_data;
  let store = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    dispatch(getSpecialCompanyByUserId(store.user.torgid_user_id));
  }, [dispatch]);

  // const onSubmit = (data) => {
  //   dispatch(createUpdateSpecialCompany(!!data.about.id, data, history));
  // };

  const updateButton = (special_company_data) => {
    return (
      <Button
        color="success"
        className={classes.actionButton}
        title={t('edit')}
        key={special_company_data.id + 'update'}
        onClick={()=> navigate('/edit-group', { state: {"special_company_data": special_company_data} })}
      >
        <Edit className={classes.icon} />
      </Button>
    );
  };

  if (loading) return ( <LoaderSpinner/>);

  const tableData = specialCompanies.results ?
    specialCompanies.results.map((value, index) => {
      return ([
        index+1,
        <img key={index} title={value.logo} src={value.logo} style={{width: '25px', height: 'auto'}}/>,
        value.name,
        <span className={classes.groupType} style={value.type === 'lite' ? {color:'#F2CB04'} : value.type === 'golden' ? {color: "#16B4F3"} : {color: "#000"}}>{value.type}</span>,
        <Button color={value.hidden ? "warning" : "success"} size="sm" > {value.hidden ? <VisibilityOff className={classes.icon}/> : <Visibility className={classes.icon} />}  </Button>,
        [
          updateButton(value),
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <GridContainer justifyContent="space-between" container>
              <GridItem md={3}>
                <CardHeader>
                  <CardIcon color="rose"> <Assignment/> </CardIcon>
                  <span className={classes.tableTitle}>{t('menu.list_groups')} </span>
                </CardHeader>
              </GridItem>
              <GridItem>
                {canCreate && <Link to="/add-group">
                  <Button color="rose" className={classes.addButton}>
                    {t('group.add_new')}
                  </Button>
                </Link>}
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              striped
              tableHead={["#", t('logo'), t('name'), t('type'), t('status'),  t('actions')]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[classes.center, classes.right, classes.right]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Group);