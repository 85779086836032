import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getReport = (date) => async dispatch => {
    dispatch({type: actionTypes.GET_REPORT_SUCCESS});
    try {
      const res = await axios({
        url: '/api/report/project_statistic',
        method: 'POST',
        data: { date: date },
        responseType: 'blob'
      });
      
      if(res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${date}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    dispatch({type: actionTypes.GET_REPORT_START, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_REPORT_FAIL});
  }
};

