import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getActivityLogs = () => async dispatch => {
  dispatch({type: actionTypes.GET_LOG_START});
  try {
    const res = await axios.get('/api/activity_log');
    dispatch({type: actionTypes.GET_LOG_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_LOG_FAIL});
  }
};

export const getActivityLogins = (page, loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_MORE_ACTIVITY_LOGIN_START : actionTypes.GET_ACTIVITY_LOGIN_START});
  try {
    const res = await axios.get('/api/login_activity?page='+page);
    dispatch({type: loadMore ? actionTypes.GET_MORE_ACTIVITY_LOGIN_SUCCESS : actionTypes.GET_ACTIVITY_LOGIN_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: loadMore ? actionTypes.GET_MORE_ACTIVITY_LOGIN_FAIL : actionTypes.GET_ACTIVITY_LOGIN_FAIL});
  }
};
