import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createUseStyles} from "react-jss";
import {withTranslation} from 'react-i18next';
import {Permissions} from '../../config'

// material-ui icons
import {Assignment, Edit, AddAlert} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getRoles, createUpdateUserRole, deleteUserRole, getUserRoles} from "../../store/actions/acl";
import {getCompanyUsers} from "../../store/actions/companyUser";
import AddUserRole from "./addUserRole";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};
const useStyles = createUseStyles(style);

const Roles = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {user_roles, loading} = useSelector( state => state.acl);
  const {roles} = useSelector( state => state.acl);
  const {companyUsers} = useSelector( state => state.companyUser);
  const {status, message, type} = useSelector( state => state.acl.notification );
  const [alert, setAlert] = React.useState(null);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const ACL_UPDATE = userPermission.includes(Permissions.ACL_UPDATE);
  const ACL_DELETE = userPermission.includes(Permissions.ACL_DELETE);

  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getCompanyUsers());
    dispatch(getRoles());
  }, [dispatch]);

  const onSubmit = (data, update) => {
    dispatch(createUpdateUserRole({update:update, ...data}));
  };

  const hideAlert = () => { setAlert(null) };

  const updateButton = (data) => {
    return (
        <Button color="success" key={data.id + 'update'} className={classes.actionButton} onClick={() => htmlAlert(data)} title={t('edit')}>
          <Edit className={classes.icon} />
        </Button>
    );
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddUserRole classes={classes} data={data} roles={roles.results} users={companyUsers} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = user_roles ?
    user_roles.map((value, index) => {
      return ([
        index+1,
        value.id,
        <img key={index} src={value.profile_picture} style={{width: '25px', height: '25px', padding: '4px'}}/>,
        value.name,
        value.email,
        value.roles.map((per, perIndex) => {
          return <Button color={per.name === 'super_admin' ? "danger" : per.name === 'manager' ? "warning" : "success"}  key={perIndex} size="sm"> {per.name} </Button>
        }),
        [
          ACL_UPDATE && updateButton(value),
          ACL_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteUserRole({...value})} setLimit={()=>{}} />
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer  justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
              </CardHeader>
            </GridItem>
            <GridItem>
              { userPermission.includes(Permissions.ACL_CREATE) && <Button color="rose" className={classes.addButton} onClick={htmlAlert} > {t('acl.add_user_role')} </Button> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "#", "ID", t('image'), t('name'), t('email'), t('roles'), t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[ classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Roles);