import {
  cardTitle,
  whiteColor,
  grayColor
} from "../../material-dashboard-pro-react.js";

const loginPageStyle = () => ({
  auth_card: {
    minHeight: '200px',
    maxWidth: '350px',
    
  },
  auth_logo:{
    width: 'auto',
    height: '50px',
    
  },
  auth_tab: {
    marginTop: '0px',
    color: "#5c5b5b !important;"
  },

  sms_msg1: {
    textAlign: 'center',
    color: '#158acb',
    fontSize: '14px',
    padding: 0,
    margin: '20px 0 0 0'
  },
  sms_msg2: {
    textAlign: 'center',
    fontSize: '10px',
    color: '#158acb'
  },
  auth_btn: {
    "&:focus,&:hover": {
      backgroundColor: '#E5576C'
    },
    backgroundColor: '#DE2C48 !important',
    fontSize: '16px !important',
    color: '#fff !important'
  },
  auth_tab_color: {
    color: "#eee"
  },
  auth_foot: {
    textAlign: 'center',
    fontWeight: 'normal'
  },
  container: {
    zIndex: "4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "8vw 20px 0 20px",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  reset_pass: {
    "&:hover": {
      color: '#158acb'
    },
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#000'
  }
});

export default loginPageStyle;
