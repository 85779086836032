import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  permissions: [],
  roles: [],
  user_roles: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_PERMISSION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_PERMISSION_SUCCESS:
      let data = action.data.results.sort((a, b) => {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        permissions: data,
        loading: false
      };
    case actionTypes.GET_PERMISSION_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.DELETE_PERMISSION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_PERMISSION_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.CREATE_PERMISSION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_PERMISSION_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_PERMISSION_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_PERMISSION_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_PERMISSION_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_PERMISSION_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };



    case actionTypes.GET_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        roles:  action.data,
        loading: false
      };
    case actionTypes.GET_ROLE_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.DELETE_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_ROLE_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.CREATE_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_ROLE_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_ROLE_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_ROLE_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_ROLE_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };





    case actionTypes.GET_USER_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_USER_ROLE_SUCCESS:
      let userRoles = action.data.results.sort((a, b) => {
        if ( a.roles[0].name < b.roles[0].name ){
          return -1;
        }
        if ( a.roles[0].name > b.roles[0].name ){
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        user_roles: userRoles,
        loading: false
      };
    case actionTypes.GET_USER_ROLE_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.DELETE_USER_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_USER_ROLE_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.CREATE_USER_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_USER_ROLE_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_USER_ROLE_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_USER_ROLE_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_USER_ROLE_SUCCESS:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_USER_ROLE_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    default:
      return state;
  }
}
