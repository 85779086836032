import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCategories = () => async dispatch => {
  dispatch({type: actionTypes.GET_CATEGORY_START});
  try {
    const res = await axios.get('/api/category');
    dispatch({type: actionTypes.GET_CATEGORY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_CATEGORY_FAIL});
  }
};

export const searchCategories = (search) => async dispatch => {
  dispatch({type: actionTypes.GET_CATEGORY_START});
  try {
    const res = await axios.get('/api/category/search/'+search);
    dispatch({type: actionTypes.GET_CATEGORY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_CATEGORY_FAIL});
  }
};

export const deleteCategory = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_CATEGORY_START});
  try {
    const res = await axios.delete('/api/category/' + data.id);
    // dispatch(getCategories());
    dispatch({type: actionTypes.DELETE_CATEGORY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_CATEGORY_FAIL});
  }
};

export const createUpdateCategory = ({update, ...data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_CATEGORY_START : actionTypes.CREATE_CATEGORY_START});
  try {
    const res = await axios({
      url: `${update ? '/api/category'+`/${data.id}` : '/api/category'}`,
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch({type: update ? actionTypes.UPDATE_CATEGORY_SUCCESS : actionTypes.CREATE_CATEGORY_SUCCESS, data: res.data});
    !update && dispatch(getCategories());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_CATEGORY_FAIL : actionTypes.CREATE_CATEGORY_FAIL, data: errorMessage});
  }
};








