import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getRegions = () => async dispatch => {
  dispatch({type: actionTypes.GET_REGION_NAME_START});
  try {
    const res = await axios.get('/api/region');
    dispatch({type: actionTypes.GET_REGION_NAME_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_REGION_NAME_FAIL});
  }
};
