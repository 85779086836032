import React from "react";
import Datetime from "react-datetime";
import { withTranslation } from 'react-i18next';

// @material-ui
import {Face, Email, Lock, FiberManualRecord, PersonPinCircle} from "@mui/icons-material";
import {
  InputAdornment,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  Switch,
  Select,
  MenuItem,
  TextField
} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Autocomplete from "@mui/material/Autocomplete";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.user.id || "",
      login: props.user.email || props.user.phone || "",
      loginState: props.user.email || props.user.phone ? "success" : "",
      password: "",
      passwordState: props.user.id ? "success" : "",
      name: props.user.name || "",
      date_of_birth: props.user.date_of_birth || null,
      gender: props.user.gender || "male",
      address: props.user.address || "",
      position: props.user.position || "",
      torgid_region_id: props.user.torgid_region_id || "",
      postalcode_number: props.user.torgid_region_id && props.data.results ? this.findPostalCode(props.data.results, props.user.torgid_region_id,'postal_code') : "",
      postalcode_city: props.user.torgid_region_id && props.data.results ? this.findPostalCode(props.data.results, props.user.torgid_region_id,'city') : "",

      subscribed: props.user.id ? props.user.subscribed : true,
      language: props.user.language || "IS",
      profile_picture: props.user.profile_picture || "",
      type: props.user.type || "App_User"
    };
  }

  sendState() {
    return this.state;
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  }

  verifyLength(value, length) {
    return value.length >= length;
  }

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  onUpload(data) {
    this.setState({ profile_picture: data });
  }

  findPostalCode = (data, id, type) => {
    let temp = data.find(x => x.id === id);
    if(temp) return temp[type];
    return ""
  };

  handleChangeRegion = (data) => {
    if(!data) return;
    this.setState({ "torgid_region_id": data.id });
    this.setState({ "postalcode_number": data.postal_code });
    this.setState({ "postalcode_city": data.city });
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "login":
        if (this.verifyEmail(event.target.value) || (/^\d{7}$/.test(event.target.value))) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (/^\d{7}$/.test(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (/^\d+$/.test(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    if (
      this.state.loginState === "success" &&
      this.state.passwordState === "success"
    ) {
      return true;
    } else {
      if (this.state.loginState !== "success") {
        this.setState({ loginState: "error" });
      }
      if (this.state.passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
    }
    return false;
  };

  render() {
    const {user, t, data, classes} = this.props;
    const isUpdating = !!user.id;
    const optionsRegion = data.results &&  data.results.map((option) => {
      const firstLetter = option.torgid_region_name.name.toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={4}>
          <PictureUpload imgHolder={this.state.profile_picture} msg={t('choose_picture')} onUpload={(data) => this.onUpload(data)} />
        </GridItem>
        <GridItem xs={12} sm={8}>
          <CustomInput
            success={this.state.loginState === "success"}
            error={this.state.loginState === "error"}
            labelText={ <span> {t('email_phone')} <small>{t('required')}</small> </span> }
            id="login"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.login,
              onChange: event => this.change(event, "login", "login"),
              endAdornment: (
                <InputAdornment  position="end"  className={classes.inputAdornment} >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={!isUpdating && this.state.passwordState === "success"}
            error={this.state.passwordState === "error"}
            labelText={ <span> {t('password')} <small>{t('required')}</small> </span> }
            id="password"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: isUpdating && true,
              onChange: event => this.change(event, "password", "length", 6),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Lock className={isUpdating ? classes.inputAdornmentIcon : classes.disabledInputIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
        <CustomInput
          labelText={ <span> {t('full_name')} </span> }
          id="name"
          formControlProps={{fullWidth: true }}
          inputProps={{
            value: this.state.name,
            onChange: event => this.change(event, "name"),
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            )
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          labelText={ <span> {t('address')} </span> }
          id="address"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            value: this.state.address,
            onChange: event => this.change(event, "address"),
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <PersonPinCircle className={classes.inputAdornmentIcon} />
              </InputAdornment>
            )
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          labelText={ <span> {t('position')} </span> }
          id="position"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            value: this.state.position,
            onChange: event => this.change(event, "position"),
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <PersonPinCircle className={classes.inputAdornmentIcon} />
              </InputAdornment>
            )
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4} className={classes.marginTop}>
        <FormControl fullWidth>
          <Datetime
            value={this.state.date_of_birth}
            onChange={e => this.handleChange(e, 'date_of_birth')}
            timeFormat={false}
            className={classes.datePicker}
            inputProps={{ placeholder: t('date_of_birth') }}
            dateFormat="yyyy-MM-D"
          />
        </FormControl>
      </GridItem>
        <GridItem xs={12} sm={4} className={classes.marginTop}>
          <FormControl fullWidth className={classes.selectFormControl} variant="standard">
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('choose_language')} </InputLabel>
            <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={this.state.language}
              onChange={e => this.handleChange(e.target.value, 'language')}
              inputProps={{ name: "language", id: "user-lang"}}
            >
              <MenuItem
                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                value="IS"
              >
                {t('icelandic')}
              </MenuItem>
              <MenuItem
                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                value="EN"
              >
                {t('english')}
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Autocomplete
            id={"region"}
            onChange={(event, newValue) => {
              this.handleChangeRegion(newValue)
            }}
            value={this.state.torgid_region_id && this.state.postalcode_number ? {id: this.state.torgid_region_id, postal_code: this.state.postalcode_number, city: this.state.postalcode_city} : null}
            options={optionsRegion ? optionsRegion.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.postal_code+' '+option.city}
            getOptionSelected={(option, value) => value && option.id === value.id}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} label={t('select_region')} variant="standard" />}
          />
        </GridItem>
      <GridItem xs={12} sm={5} className={classes.gender}>
        <FormControlLabel
          control={
            <Radio
              checked={this.state.gender === "male"}
              onChange={e => this.handleChange(e.target.value, 'gender')}
              value="male"
              name="gender"
              aria-label="Male"
              icon={ <FiberManualRecord className={classes.radioUnchecked} /> }
              checkedIcon={ <FiberManualRecord className={classes.radioChecked} /> }
              classes={{ checked: classes.radio,  root: classes.radioRoot }}
            />
          }
          classes={{ label: classes.label,  root: classes.labelRoot }}
          label={t('male')}
        />
        <FormControlLabel
          control={
            <Radio
              checked={this.state.gender === "female"}
              onChange={e => this.handleChange(e.target.value, 'gender')}
              value="female"
              name="gender"
              aria-label="Female"
              icon={ <FiberManualRecord className={classes.radioUnchecked} /> }
              checkedIcon={ <FiberManualRecord className={classes.radioChecked} /> }
              classes={{ checked: classes.radio,  root: classes.radioRoot }}
            />
          }
          classes={{ label: classes.label,  root: classes.labelRoot }}
          label={t('female')}
        />
      </GridItem>
      <GridItem xs={12} sm={3} className={classes.subscribed}>
      <div className={classes.block}>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.subscribed}
              onChange={e => this.handleChange(e.target.checked, 'subscribed')}
              value="false"
              classes={{switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar}}
            />
          }
          classes={{label: classes.label}}
          label={t('subscribe')}
        />
      </div>
      </GridItem>

      </GridContainer>
    );
  }
}

export default withTranslation('',{ withRef: true })(Step1);