import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  ccard: {
    padding: '10px 0'
  },
  cardIconTitle: {
    ...cardTitle,
    position: "relative",
    float: "left",
    width: "100%",
    height: "auto",
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory2: {
    margin: "0",
    color: "#3C4858",
    fontWeight: 500,
    marginLeft: "40px",
    paddingTop: "0",
    fontSize: "14px"
  },
  cardCategory: {
    position: "relative",
    float: "left",
    width: "100%",
    height: "auto",
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center"
  },
  description: {
    color: grayColor[0]
  },
  updateProfileButton: {
    float: "right"
  },

  cardIcon: {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translateY(-50%)",
    width: "15px",
    height: "15px",
    fontSize: "15px",
    color: '#1588cb'
  }
};
export default userProfileStyles;
