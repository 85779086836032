import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { withTranslation } from 'react-i18next';

import Wizard from "../../components/Wizard/Wizard.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import SnackbarCustom from "../../components/Snackbar/Snackbar.js";
import {AddAlert} from "@mui/icons-material";

import {useDispatch, useSelector} from "react-redux";
import {createUpdateSpecialCompany, getSpecialCompany, getSpecialCompanyById} from "../../store/actions/specialCompany";
import Step1 from "./AddSteps/Step1.js";
import {LoaderSpinner} from "../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import {createUseStyles} from "react-jss";

const style = {
  inputAdornment: {
    position: "relative"
  },
  colorPickerIcon: {
    position: "relative",
    cursor: 'pointer',
    color: '#de2c49',
    '& svg': {
      color: '#de2c49'
    }
  },
  attach_group_ids: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '20px'
  },
  tagItem: {
    backgroundColor: '#de2c49',
    // display: 'inline-block',
    fontSize: '14px',
    borderRadius: '30px',
    height: '30px',
    padding: '0 4px 0 1rem',
    display: 'inline-flex',
    alignItems: 'center',
    margin: '0 0.3rem 0.3rem 0',
    color: '#fff',
    fontWeight: '500'
  },
  button:{
    backgroundColor: 'white',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    marginLeft: '10px',
    fontWeight: '500',
    padding: '0',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ...wizardStyle
};
const useStyles = createUseStyles(style);

const AddGroup = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const {state} = useLocation();
  const classes = useStyles();
  const {status, message, type} = useSelector( state => state.offer.notification );
  const {specialCompaniesById, specialCompanies, loading:loadingAllSpeacilCompanies, loading } = useSelector( state => state.specialCompanies );
  const isUpdating = !!(state && state.special_company_data);
  const dataByState = (state && state.special_company_data);
  let store = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if(dataByState && dataByState.id) {
      dispatch(getSpecialCompanyById(dataByState.id));
    }
    else if(store && store.roles[0] === 'group_admin' && store.user.torgid_special_company_id) {
      dispatch(getSpecialCompanyById(store.user.torgid_special_company_id));
    }
    dispatch(getSpecialCompany());
  }, []);

  const onSubmit = (data) => {
    data.about.attached_group_ids = data.about.attached_group_ids.length>0 ? data.about.attached_group_ids.map(item => item.id).join(',') : null;

    dispatch(createUpdateSpecialCompany(!!data.about.id, data, history));
  };

  if (loading || loadingAllSpeacilCompanies) return (<LoaderSpinner/>);

  return (
    <GridContainer justifyContent="center">
      <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status} />
      <GridItem xs={12} sm={10}>
        <Wizard
          classes={classes}
          validate
          history={history}
          data={dataByState ? dataByState : store.roles[0] === 'group_admin' ? specialCompaniesById : {}}
          data2={specialCompanies || {}}
          steps={[
            { stepName: t('menu.groups'), stepComponent: Step1, stepId: "about" },
          ]}
          title={isUpdating ? t('group.update') : t('group.add_new')}
          subtitle=""
          finishButtonClick={onSubmit}
          nextButtonText={t('next')}
          cancelButtonText={t('cancel')}
          previousButtonText={t('previous')}
          finishButtonText={t('finish')}
        />
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation('',{ withRef: true })(AddGroup);