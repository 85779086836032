import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createUseStyles} from "react-jss";
import {withTranslation} from 'react-i18next';

// material-ui icons
import {Assignment, Edit, AddAlert} from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {getRoles, createUpdateRole, deleteRole, getPermissions, deleteUserRole} from "../../store/actions/acl";
import AddRole from "./addRole";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert,
  ...formStyles
};
const useStyles = createUseStyles(style);

const Roles = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {roles, loading} = useSelector( state => state.acl);
  const {permissions, loadingPer} = useSelector( state => state.acl);
  const {status, message, type} = useSelector( state => state.acl.notification );
  const [alert, setAlert] = React.useState(null);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const ROLE_UPDATE = userPermission.includes('role-update');
  const ROLE_DELETE = userPermission.includes('role-delete');

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getPermissions());
  }, [dispatch]);

  if (loading || loadingPer) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateRole({update:update, ...data}));
  };

  const hideAlert = () => { setAlert(null) };

  const updateButton = (data) => {
    return (
        <Button color="success" key={data.id + 'update'} className={classes.actionButton} onClick={() => htmlAlert(permissions, data)} title={t('edit')}>
          <Edit className={classes.icon} />
        </Button>
    );
  };

  const htmlAlert = (permissions, data) => {
    setAlert(
      <AddRole classes={classes} data={data} permissionsList={permissions} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const tableData = roles.results ?
    roles.results.map((value, index) => {
      return ([
        value.id,
        value.name,
        value.permissions.map((per, perIndex) => { return <Button color="success" key={perIndex} size="sm"> {per.name} </Button>}),
        [
          ROLE_UPDATE && updateButton(value),
          ROLE_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteRole({...value})} setLimit={()=>{}} />
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom
      place="br"
      icon={AddAlert}
      color={type === 'success' ? 'success' : 'warning'}
      message = { message }
      open = { status }
    />
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer  justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
              </CardHeader>
            </GridItem>
            <GridItem>
              { userPermission.includes('role-create') && <Button color="rose" className={classes.addButton} onClick={() => htmlAlert(permissions)} > {t('acl.add_role')} </Button> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "ID", t('name'), t('permissions'), t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[ classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Roles);