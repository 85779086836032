import React from "react";
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from 'react-i18next';
import {loginUser, loginByPhone, loginSms, resetPassword} from "../../store/actions/auth";

// @material-ui
import {Tabs, Tab, Typography, Box} from '@mui/material';
import {Error, Phone, Email} from '@mui/icons-material';
import {createUseStyles} from 'react-jss'
// core components
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import SnackbarCustom from "../../components/Snackbar/Snackbar.js";

import {LoaderSpinner} from "../../components/Loader";

const useStyles = createUseStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LoginPage({ t }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [login, setLogin] = React.useState("");
  const [loginState, setLoginState] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordState, setPasswordState] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneState, setPhoneState] = React.useState("");
  const [sms, setSms] = React.useState("");
  const [smsState, setSmsState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailState, setEmailState] = React.useState("");

  const {status, message, type} = useSelector( state => state.auth.notification )
  const {msg, msgReset, smsPhone, loading, loadingPhone, msgFail} = useSelector( state => state.auth );

  const [value, setValue] = React.useState(0);
  const [reset, setReset] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    document.title = "Login | Spara";
  });

  React.useEffect(() => {
   if(msgReset === 'Link to reset your password has been sent to that email') {
     setReset(0);
   }
  },[msgReset]);

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  const change = (event, stateName, type) => {
    switch (type) {
      case "login":
        if (verifyEmail(event.target.value)) {
          setLoginState( "success" );
        } else {
          setLoginState( "error" );
        }
        break;
      case "phone":
        if (/^\d{7}$/.test(event.target.value)) {
          setPhoneState( "success" );
        } else {
          setPhoneState( "error" );
        }
        break;
      case "sms":
        if (event.target.value.length >= 4) {
          setSmsState("success");
        } else {
          setSmsState("error");
        }
        break;  case "pass":
        if (event.target.value.length >= 6) {
          setPasswordState("success");
        } else {
          setPasswordState("error");
        }
        break;
      case "email":
        if (verifyEmail(event.target.value)) {
          setEmailState( "success" );
        } else {
          setEmailState( "error" );
        }
        break;
      default:
        break;
    }
    if(stateName === "login") {
      setLogin(event.target.value);
    }
    if(stateName === "phone") {
      setPhone(event.target.value);
    }
    if(stateName === "sms") {
      setSms(event.target.value);
    }
    if(stateName === "password") {
      setPassword(event.target.value)
    }
    if(stateName === "email") {
      setEmail(event.target.value)
    }
  };

  const handleSubmit = () => {
    if (loginState !== "success") { setLoginState("error"); return }
    if (passwordState !== "success") { setPasswordState("error"); return }
    dispatch(loginUser({login, password}));
  };

  const handlePhoneSubmit = () => {
    if (phoneState !== "success") { setPhoneState("error"); return }
    dispatch(loginByPhone(phone));
  };

  const handleResetPassword = () => {
    if (emailState !== "success") { setEmailState("error"); return }
    dispatch(resetPassword(email));
  };

  const handleVerify = () => {
    if (smsState !== "success") { setSmsState("error"); return }
    dispatch(loginSms(smsPhone, sms));
  };

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <SnackbarCustom place="br" icon={Error} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
            <h1 style={{fontSize:"3.4rem", fontStyle:"italic", color:"#DE2C48", fontWeight:600, margin:0, padding:0}}>Spara</h1>
            <h4 style={{fontSize:"1.2rem", fontStyle:"italic", color:"#DE2C48", fontWeight:500, margin:0, padding:0}}>fyrir stjórnendur</h4>
            <Card className={classes.auth_card}>
              
                <Tabs
                  value={value}
                  onChange={handleChange}
                  className={classes.auth_tab}
                  centered
                  variant="fullWidth"
                  aria-label="icon label tabs example"
                  textColor={classes.auth_tab_color}
                  TabIndicatorProps={{style: {background:'#DE2C48', color: '#DE2C48'}}}
                >
                  
                  <Tab icon={<Phone />} label="Rafræn skilríki" />
                  <Tab icon={<Email />} label="Netfang" />
                </Tabs>

                <TabPanel value={value} index={0}>
                  {msg === '' && <React.Fragment>
                    {msgFail.length > 0 && <p className={classes.sms_msg1}>{msgFail}</p>}
                    <CustomInput
                      success={phoneState === "success"}
                      error={phoneState === "error"}
                      labelText="Símanúmer"
                      formStyle={{margin: '20px 0'}}
                      labelStyle={{fontSize: '18px'}}
                      inputStyle={{marginTop: '10px'}}
                      id="phone"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onKeyPress: e => (e.key === 'Enter' && phone.length === 7 && handlePhoneSubmit()),
                        onChange: event => change(event, "phone", "phone"),
                      }}
                    />
                    <Button block onClick={() => handlePhoneSubmit()} className={classes.auth_btn}>
                      Áfram &nbsp; {loadingPhone && <LoaderSpinner color="white"/>}
                    </Button>
                  </React.Fragment>
                  }
                  {msg === 'SMS sent' && <React.Fragment>
                    <p className={classes.sms_msg1}>Staðfestu símanúmerið þitt <b>{smsPhone}</b></p>
                    <p className={classes.sms_msg2}>Við sendum þér SMS með kóða sem þú setur inn hér að neðan.</p>
                    <CustomInput
                      success={smsState === "success"}
                      error={smsState === "error"}
                      labelText="SMS kóða"
                      labelStyle={{fontSize: '18px'}}
                      inputStyle={{marginTop: '10px'}}
                      id="code"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => change(event, "sms", "sms"),
                      }}
                    />
                    <Button block onClick={() => handleVerify()} className={classes.auth_btn}>Staðfesta kóða</Button>
                  </React.Fragment>
                  }

                </TabPanel>

                <TabPanel value={value} index={1}>
                  {value === 1 && reset === 0 &&
                    <React.Fragment>
                      <CustomInput
                        success={loginState === "success"}
                        error={loginState === "error"}
                        labelText="Netfang"
                        formStyle={{margin: '20px 0'}}
                        labelStyle={{fontSize: '18px'}}
                        inputStyle={{marginTop: '10px'}}
                        id="login"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          onChange: event => change(event, "login", "login"),
                        }}
                      />
                      <CustomInput
                        success={passwordState === "success"}
                        error={passwordState === "error"}
                        labelText="Lykilorð"
                        labelStyle={{fontSize: '18px'}}
                        inputStyle={{margin: '10px 0 20px 0'}}
                        id="password"
                        formControlProps={{fullWidth: true, marginTop: '10px'}}
                        inputProps={{
                          onKeyPress: event => handleKeypress(event),
                          onChange: event => change(event, "password", "pass"),
                          type: "password",
                          autoComplete: "off",
                        }}
                      />
                      <Button block onClick={() => handleSubmit()} className={classes.auth_btn}>Innskrá &nbsp; {loading && <LoaderSpinner color="white"/>}</Button>
                      <div className={classes.reset_pass} style={{marginTop: '10px'}} onClick={() => setReset(2)}> Vantar og/eða gleymt lykilorð? </div>
                    </React.Fragment>
                  }
                  {reset === 2 && <React.Fragment>
                    <CustomInput
                      success={emailState === "success"}
                      error={emailState === "error"}
                      labelText="Netfang"
                      formStyle={{marginTop: '20px'}}
                      labelStyle={{fontSize: '18px'}}
                      inputStyle={{marginTop: '10px'}}
                      id="email"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onKeyPress: e => (e.key === 'Enter' && phone.length === 7 && handleResetPassword()),
                        onChange: event => change(event, "email", "email"),
                      }}
                    />
                    <Button block onClick={() => handleResetPassword()} className={classes.auth_btn}>
                      Endurstilla lykilorð
                    </Button>
                    <div className={classes.reset_pass} onClick={() => setReset(0)}> Til baka </div>
                  </React.Fragment>
                  }
                </TabPanel>

              
            </Card>
            <GridItem>
              <div className={classes.auth_foot}>
                <p>© {new Date().getFullYear()} Spara</p>
              </div>
            </GridItem>
      </div>
    </React.Fragment>
  );
}

export default  withTranslation('',{ withRef: true })(LoginPage);