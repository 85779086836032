import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const changeNotificationStatus  = () => async dispatch => {
  dispatch({type: actionTypes.NOTIFICATION_STATUS_FALSE});
};

export const getDashboardReport = () => async dispatch => {
  dispatch({type: actionTypes.GET_DASHBOARD_REPORT_START});
  try {
    const res = await axios.get('/api/dashboard/total_count');
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_FAIL});
  }
};

export const getDashboardForCompanyUser = (company_id) => async dispatch => {
  dispatch({type: actionTypes.GET_DASHBOARD_REPORT_START});
  try {
    const res = await axios.get('/api/dashboard/total_count_cu/'+company_id);
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_FAIL});
  }
};

export const getDashboardForGroupUser = (groupId) => async dispatch => {
  dispatch({type: actionTypes.GET_DASHBOARD_REPORT_START});
  try {
    const res = await axios.get('/api/dashboard/group/'+groupId);
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_FAIL});
  }
};
