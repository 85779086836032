import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  loadingSave: false,
  companies: [],
  company: [],
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.data,
        loading: false
      };
    case actionTypes.GET_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_MORE_COMPANY_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          results: [...state.companies.results, ...action.data.results]
        },
        loadingMore: false
      };
    case actionTypes.GET_MORE_COMPANY_FAIL:
      return  {
        ...state,
        loadingMore: false
      };

    case actionTypes.GET_COMPANY_BY_ID_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        company: action.data.results,
        loading: false,
        notification: { status: true, type: 'success', message: 'Success'}
      };
    case actionTypes.GET_COMPANY_BY_ID_FAIL:
      return  {
        ...state,
        loading: false,
        error: true,
        notification: { status: true, type: 'error', message: 'Not found' }
      };


    case actionTypes.CREATE_COMPANY_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_COMPANY_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_COMPANY_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_COMPANY_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.DELETE_COMPANY_START:
      return  {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_COMPANY_SUCCESS:
      const id = action.data.results.id;
      const data = state.companies.results.filter((item) => item.id !== id);
      state.companies.results = data;
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };


    default:
      return state;
  }
}
