export default {

	DASHBOARD_VIEW: 'dashboard-view',

	APP_USER_VIEW: 'app_user-view',
	APP_USER_CREATE: 'app_user-create',
	APP_USER_UPDATE: 'app_user-update',
	APP_USER_DELETE: 'app_user-delete',
	APP_USER_DETAILS: 'app_user-details',

	COMPANY_USER_VIEW: 'company_user-view',
	COMPANY_USER_CREATE: 'company_user-create',
	COMPANY_USER_UPDATE: 'company_user-update',
	COMPANY_USER_DELETE: 'company_user-delete',
	COMPANY_USER_DETAILS: 'company_user-details',

	SPECIAL_USER_VIEW: 'special_user-view',
	SPECIAL_USER_CREATE: 'special_user-create',
	SPECIAL_USER_UPDATE: 'special_user-update',
	SPECIAL_USER_DELETE: 'special_user-delete',

	GROUP_VIEW: 'group-view',
	GROUP_CREATE: 'group-create',
	GROUP_UPDATE: 'group-update',
	GROUP_DELETE: 'group-delete',

	OFFER_VIEW: 'offer-view',
	OFFER_CREATE: 'offer-create',
	OFFER_UPDATE: 'offer-update',
	OFFER_DELETE: 'offer-delete',
	OFFER_DETAILS: 'offer-details',

	CATEGORY_VIEW: 'category-view',
	CATEGORY_CREATE: 'category-create',
	CATEGORY_UPDATE: 'category-update',
	CATEGORY_DELETE: 'category-delete',

	COMPANY_VIEW: 'company-view',
	COMPANY_CREATE: 'company-create',
	COMPANY_UPDATE: 'company-update',
	COMPANY_DELETE: 'company-delete',

	REGION_CREATE: 'region-create',
	REGION_UPDATE: 'region-update',
	REGION_DELETE: 'region-delete',

	REGION_NAME_CREATE: 'region_name-create',
	REGION_NAME_UPDATE: 'region_name-update',
	REGION_NAME_DELETE: 'region_name-delete',

	SPECIAL_COMPANY_CREATE: 'special_company-create',
	SPECIAL_COMPANY_UPDATE: 'special_company-update',
	SPECIAL_COMPANY_DELETE: 'special_company-delete',

	NOTIFICATION_VIEW: 'notification-view', //  -
	REPORT_VIEW: 'report-view',
	LOG_VIEW: 'log-view',
	ACTIVITY_LOGIN_VIEW: 'activity_login-view',

	DEVELOPER_NOTE_CREATE: 'developer_note-create',
	DEVELOPER_NOTE_UPDATE: 'developer_note-update',
	DEVELOPER_NOTE_DELETE: 'developer_note-delete',

	ACL_VIEW: 'acl-view',
	ACL_CREATE: 'acl-create',
	ACL_UPDATE: 'acl-update',
	ACL_DELETE: 'acl-delete',

	PERMISSION_VIEW: 'permission-view',
	PERMISSION_CREATE: 'permission-create',
	PERMISSION_UPDATE: 'permission-update',
	PERMISSION_DELETE: 'permission-delete',

	ROLE_VIEW: 'role-view',
	ROLE_CREATE: 'role-create',
	ROLE_UPDATE: 'role-update',
	ROLE_DELETE: 'role-delete',


	CU_DASHBOARD_VIEW: 'cu_dashboard-view',
	CU_PROFILE_UPDATE: 'cu_profile-update',

	CU_PROFILE_VIEW: 'cu_profile-view',

	CU_COMPANY_VIEW: 'cu_company-view',
	CU_COMPANY_UPDATE: 'cu_company-update',

	CU_COMPANY_USER_VIEW: 'cu_company_user-view',
	CU_COMPANY_USER_CREATE: 'cu_company_user-create',
	CU_COMPANY_USER_UPDATE: 'cu_company_user-update',
	CU_COMPANY_USER_DELETE: 'cu_company_user-delete',
	CU_COMPANY_USER_DETAILS: 'cu_company_user-details',

	CU_OFFER_VIEW: 'cu_offer-view',
	CU_OFFER_CREATE: 'cu_offer-create',
	CU_OFFER_UPDATE: 'cu_offer-update',
	CU_OFFER_DELETE: 'cu_offer-delete',
	CU_EMPLOYEE_VIEW: 'cu_employee-view',
	CU_CUSTOM_EMPLOYEE_VIEW: 'cu_custom-employee-view',

	CU_MANAGER_VIEW: 'cu_manager-view',
	CU_MANAGER_DETAILS: 'cu_manager-detail',
	CU_B2B_VIEW: 'cu_b2b-view',

	CU_GROUP_UPDATE: 'cu_group-update',
};
