import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  loadingReport: false,
  loadingSave: false,
  loadingOfferById: false,
  offerById: {},
  offers: [],
  offersByCompany: [],
  specialOffersByCompany: [],
  topOffers: [],
  report: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.NOTIFICATION_STATUS_FALSE:
      return {
        ...state,
        notification: { status: false, type: '', message: '' }
      };

    case actionTypes.GET_OFFER_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_OFFER_SUCCESS:
      return {
        ...state,
        offers: action.data.results,
        offersByCompany: action.data.results,
        loading: false
      };
    case actionTypes.GET_OFFER_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_OFFER_BY_ID_START:
      return  {
        ...state,
        loadingOfferById: true
      };
    case actionTypes.GET_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        offerById: action.data.results,
        loadingOfferById: false
      };
    case actionTypes.GET_OFFER_BY_ID_FAIL:
      return  {
        ...state,
        loadingOfferById: false
      };

    case actionTypes.GET_MORE_OFFER_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_OFFER_SUCCESS:
      return {
        ...state,
        offers: {
          ...state.offers,
          results: [...state.offers.results, ...action.data.results]
        },
        loadingMore: false
      };
    case actionTypes.GET_MORE_OFFER_FAIL:
      return  {
        ...state,
        loadingMore: false
      };

    case actionTypes.GET_TOP_OFFER_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_TOP_OFFER_SUCCESS:
      return {
        ...state,
        topOffers: action.data,
        loading: false
      };
    case actionTypes.GET_TOP_OFFER_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_OFFER_BY_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_OFFER_BY_COMPANY_SUCCESS:
      return {
        ...state,
        offersByCompany: action.data.results,
        loading: false
      };
    case actionTypes.GET_OFFER_BY_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_SUCCESS:
      return {
        ...state,
        specialOffersByCompany: action.data.results,
        loading: false
      };
    case actionTypes.GET_SPECAIL_OFFER_BY_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_OFFER_REPORT_START:
      return  {
        ...state,
        loadingReport: true
      };
    case actionTypes.GET_OFFER_REPORT_SUCCESS:
      return {
        ...state,
        report: action.data,
        loadingReport: false
      };
    case actionTypes.GET_OFFER_REPORT_FAIL:
      return  {
        ...state,
        loadingReport: false
      };


    case actionTypes.DELETE_OFFER_START:
      return  {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_OFFER_SUCCESS:
      if(action.deleteFrom === 'offersByCompany') {
        let id = action.data.results.id;
        state.offersByCompany = state.offersByCompany.filter((item) => item.id !== id);
      } else {
        let id = action.data.results.id;
        state.offers = state.offers.filter((item) => item.id !== id);
      }

      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_OFFER_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.CREATE_OFFER_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.CREATE_OFFER_SUCCESS:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_OFFER_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_OFFER_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.UPDATE_OFFER_SUCCESS:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_OFFER_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.CHANGE_OFFER_STATUS_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.CHANGE_OFFER_STATUS_SUCCESS:
      let newStateD = Object.assign([], state.offers);
      const indexD = newStateD.findIndex(item => item.id === action.data.results.id);
      newStateD[indexD].is_active = action.data.results.is_active === "true";
      return  {
        ...state,
        offers: newStateD,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CHANGE_OFFER_STATUS_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.CHANGE_OFFER_STATUS_CU_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.CHANGE_OFFER_STATUS_CU_SUCCESS:
      let newStateCU = Object.assign([], state.offersByCompany);
      let indexCU = newStateCU.findIndex(item => item.id === action.data.results.id);
      newStateCU[indexCU].is_active = action.data.results.is_active === "true";
      return  {
        ...state,
        offersByCompany: newStateCU,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CHANGE_OFFER_STATUS_CU_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_START:
      return  {
        ...state,
        loadingSave: true
      };
    case actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_SUCCESS:
      let copyState = Object.assign([], state.offersByCompany);
      copyState = copyState.filter((item) => item.id !== action.data.results.id);
      return  {
        ...state,
        offersByCompany: copyState,
        loadingSave: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CHANGE_OFFER_STATUS_ARCHIVE_FAIL:
      return  {
        ...state,
        loadingSave: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    default:
      return state;
  }
}
