import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getPermissions = () => async dispatch => {
  dispatch({type: actionTypes.GET_PERMISSION_START});
  try {
    const res = await axios.get('/api/permission');
    dispatch({type: actionTypes.GET_PERMISSION_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_PERMISSION_FAIL});
  }
};

export const createUpdatePermission = ({update, ...data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_PERMISSION_START : actionTypes.CREATE_PERMISSION_START});
  try {
    const res = await axios({
      url: `${update ? '/api/permission'+`/${data.id}` : '/api/permission'}`,
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch(getPermissions());
    dispatch({type: update ? actionTypes.UPDATE_PERMISSION_SUCCESS : actionTypes.CREATE_PERMISSION_SUCCESS, data: res.data});
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_PERMISSION_FAIL : actionTypes.CREATE_PERMISSION_FAIL, data: errorMessage});
  }
};

export const deletePermission = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_PERMISSION_START});
  try {
    const res = await axios.delete('/api/permission/' + data.id);
    dispatch(getPermissions());
    dispatch({type: actionTypes.DELETE_PERMISSION_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_PERMISSION_FAIL});
  }
};


export const getRoles = () => async dispatch => {
  dispatch({type: actionTypes.GET_ROLE_START});
  try {
    const res = await axios.get('/api/role');
    dispatch({type: actionTypes.GET_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ROLE_FAIL});
  }
};

export const createUpdateRole = ({update, ...data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_ROLE_START : actionTypes.CREATE_ROLE_START});
  try {
    const res = await axios({
      url: `${update ? '/api/role'+`/${data.id}` : '/api/role'}`,
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch(getRoles());
    dispatch({type: update ? actionTypes.UPDATE_ROLE_SUCCESS : actionTypes.CREATE_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_ROLE_FAIL : actionTypes.CREATE_ROLE_FAIL, data: errorMessage});
  }
};

export const deleteRole = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_ROLE_START});
  try {
    const res = await axios.delete('/api/role/' + data.id);
    dispatch(getRoles());
    dispatch({type: actionTypes.DELETE_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_ROLE_FAIL});
  }
};



export const getUserRoles = () => async dispatch => {
  dispatch({type: actionTypes.GET_USER_ROLE_START});
  try {
    const res = await axios.get('/api/user_roles');
    dispatch({type: actionTypes.GET_USER_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USER_ROLE_FAIL});
  }
};

export const createUpdateUserRole = ({update, ...data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_USER_ROLE_START : actionTypes.CREATE_USER_ROLE_START});
  try {
    const res = await axios({
      url: `${update ? '/api/user_roles'+`/${data.id}` : '/api/user_roles'}`,
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch(getUserRoles());
    dispatch({type: update ? actionTypes.UPDATE_USER_ROLE_SUCCESS : actionTypes.CREATE_USER_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_USER_ROLE_FAIL : actionTypes.CREATE_USER_ROLE_FAIL, data: errorMessage});
  }
};

export const deleteUserRole = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_USER_ROLE_START});
  try {
    const res = await axios.delete('/api/user_roles/' + data.id);
    dispatch(getUserRoles());
    dispatch({type: actionTypes.DELETE_USER_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_USER_ROLE_FAIL});
  }
};
