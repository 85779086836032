import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "../../material-dashboard-pro-react.js";

const appStyle = theme => ({
  // html: {
  //   margin: 0,
  //   padding:0,
  //   height: '100%'
  // },
  // body: {
  //   margin: 0,
  //   padding:0,
  //   height: '100%'
  // },
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    // overflowX: 'hidden',
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    '@media (min-width: 992px)': {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "0px 15px 30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    '@media (min-width: 992px)': {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  }
});

export default appStyle;
